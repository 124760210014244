import React, { useRef, useEffect } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper/modules";
import { useSelector, useDispatch } from "react-redux";
import { decryptUserData } from "../../utils/encryptedData";
import { ModalAction } from "../../Store/Modal-Slice";
import { ShortAddress } from "../../utils/Address";
import LightBoxList from "../LightBox/LightBoxList";
import { formatDate } from "../../utils/Formatteddate"
import { LightBoxAction } from "../../Store/LightboxSlice";
import useCloseModalHandler from "../../Hooks/useCloseModal";
import "swiper/css";
import "swiper/css/navigation";

const LightBoxModal = () => {
  let LightBoxArr = useSelector((state) => state.LightBox.LightBoxState);

  let LightBoxActiveImgID = useSelector(
    (state) => state.LightBox.LightBoxActiveImgId
  );
  let LoadMorePhotos = useSelector((state) => state.LightBox.LoadMorePhotos);

  let dispatch = useDispatch();
  // <---------------- decrypted data ------------->
  const encryptedUserData = localStorage.getItem("Data");
  const decryptedUserData = decryptUserData(encryptedUserData);
  let usertoken = decryptedUserData.token;
  let LoginUserid = decryptedUserData.userid;
  let display_name = decryptedUserData.useDisplay_name;
  let displaySocailUserPhoto = decryptedUserData.Display_UserSocailPhoto;
  let location = decryptedUserData?.state
    ? ShortAddress(decryptedUserData.state, 16)
    : "";
  const formattedDate = formatDate(decryptedUserData.CreatedAt);

  // const formattedDate = formatDate(props.postedDate);

  const swiperRef = useRef(null);
  useEffect(() => {
    // Check if swiperRef is available and LightBoxArr has data
    if (swiperRef.current && LightBoxArr.PhotoArr) {
      // Find the index of the slide with ID 3 in LightBoxArr
      let index;
      if (LightBoxArr.PhotoArr[0]?.photo_id) {
        index = LightBoxArr.PhotoArr.findIndex(
          (item) => item.photo_id === LightBoxActiveImgID
        );
      } else {
        index = LightBoxArr.PhotoArr.findIndex(
          (item) => item.id === LightBoxActiveImgID
        );
      }

      // Check if the index is found
      if (index !== -1) {
        // Activate the slide with the found index
        swiperRef.current.swiper.slideTo(index);
      }
    }
  }, []);

  // LightBoxActiveImgID, LightBoxArr.PhotoArr
  const closeModal = () => {
    dispatch(ModalAction.setisLightboxModalOpen(false))
  };
  const modalRef = useCloseModalHandler(closeModal);
  return (
    <>
      <div className="backdrop" />

      <div
        className="modal fade show bph-modal galleryExpanded"
        id="galleryExpanded"
        tabIndex={-1}
        aria-labelledby="galleryExpanded"
        aria-hidden="true"
        style={{ display: "block" }}
      >
        <div className="modal-dialog modal-dialog-centered modalAnimation">
          <div className="modal-content" ref={modalRef}>
            <div className="gallery_expand_content">
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={closeModal}
              />
              <div className="gallery_popup_content">
                <div
                  className="gallery_popup_carousel swiper swiper-container"
                  id="galleryPopupCarousel"
                >
                  <Swiper
                    ref={swiperRef}
                    modules={[Navigation]}
                    spaceBetween={50}
                    slidesPerView={1}
                    navigation={{
                      nextEl: ".galleryNextBtn",
                      prevEl: ".galleryPrevBtn",
                    }}
                    onSlideChange={(swiper) => {
                      const totalSlides = swiper.slides.length;
                      const currentIndex = swiper.activeIndex;

                      if (totalSlides - currentIndex <= 4) {
                        // Run your function when there are only 3 slides left

                        dispatch(LightBoxAction.setLoadMorePhotos({ fetchMorePhotos: true, fetchAction: !LoadMorePhotos.fetchAction }))
                        // yourFunction(); // Replace with your actual function 
                      }
                    }}
                  >
                    {LightBoxArr.PhotoArr &&
                      LightBoxArr.PhotoArr.map((data, index) => (
                        <>
                          <SwiperSlide key={data.id}>
                            <LightBoxList
                              Name={data.name}
                              photo_url={data.photo_url}
                              UserPhoto={data.user_photo_url}
                              Userlocation={data.state}
                              is_vote={data.is_vote}
                              photoId={data.id}
                              postedDate={data.photo_posted_date}
                              usertoken={usertoken}
                              is_follow={data.is_follow}
                              Userid={data.user_id}
                              LoginUserid={LoginUserid}
                              LoginUserPhotos={LightBoxArr.LoginUserPhotos}
                              gallery={LightBoxArr.gallery}
                              TotalVotes={data.total_votes}
                            />
                          </SwiperSlide>
                        </>
                      ))}
                  </Swiper>
                  {LightBoxArr.LoginUserPhotos && (
                    <div className="gallery_content_meta">
                      <div className="feed_user">
                        <a
                          className="user_img"
                          style={{
                            backgroundImage: `url(${displaySocailUserPhoto || ""
                              })`,
                            backgroundPosition: "center",
                            backgroundSize: "cover",
                            backgroundRepeat: "no-repeat",
                            borderRadius: "50%",
                            cursor: "pointer",
                            height: "40px",
                            width: "40px",
                          }}
                        >
                          {!displaySocailUserPhoto && (
                            <img
                              src={`https://ui-avatars.com/api/?name=${display_name}&size=40&rounded=true&background=01655a&color=fff`}
                            />
                          )}
                        </a>
                        <div className="user_text">
                          <a className="user_name">{display_name}</a>
                          {location && (
                            <p className="user_meta">
                              <span className="location">{location}</span>
                              <span className="date">{formattedDate}</span>
                            </p>
                          )}
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
              <div className="photo_nav">
                <button className="galleryPrevBtn swiper-button-prev">
                  <img src="./assets/img/icons/arrow-left.svg" alt="Previous" />
                </button>
                <button className="galleryNextBtn swiper-button-next ">
                  <img src="./assets/img/icons/arrow-right.svg" alt="Next" />
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default LightBoxModal;
