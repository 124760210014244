import { configureStore } from "@reduxjs/toolkit";
import SocialMediaSlice from "./SocialMedia-Slice";
import ModalSlice from "./Modal-Slice";
import LoginSlice from "./Login-Slice";
import FollowSlice from "./FollowSlice";
import SocketSlice from "./SocketSlice";
import LightBoxSlice from "./LightboxSlice";
import GenericSlice from "./GenericSlice";
import GallerySlice from "./Gallery-Slice";
import PayWallSlice from "./PayWallSlice";

let store = configureStore({
    reducer: {
        SocialMedia: SocialMediaSlice.reducer,
        Modal: ModalSlice.reducer,
        Login: LoginSlice.reducer,
        Follow: FollowSlice.reducer,
        Socket: SocketSlice.reducer,
        LightBox: LightBoxSlice.reducer,
        Generic: GenericSlice.reducer,
        Gallery: GallerySlice.reducer,
        PayWall: PayWallSlice.reducer,

    }
})

export default store;