import React, { useState } from 'react'
import { FacebookShareButton, TwitterShareButton, LinkedinShareButton, EmailShareButton, WhatsappShareButton } from 'react-share'
import { useSelector } from "react-redux";
import CryptoJS from 'crypto-js';
import { useDispatch } from "react-redux";
import { ModalAction } from "../../Store/Modal-Slice";
import amplitude from "amplitude-js"

const SharePhotoModal = () => {
    const [CopyPath, setCopyPath] = useState(false);


    let dispatch = useDispatch()

    let sharePhotoInfo = useSelector(state => state.Modal.ShareModalPhotoInfo)



    let secretKey = process.env.REACT_APP_SECRET_KEY
    const encryptedID = CryptoJS.AES.encrypt(sharePhotoInfo.id, secretKey).toString();



    let shareURL0ther = `${process.env.REACT_APP_SHARE_URL}/Photo?id=${encryptedID}&Source=other`
    let shareURLwhatsapp = `${process.env.REACT_APP_SHARE_URL}/Photo?id=${encryptedID}&Source=whatsapp`
    let shareURLfacebook = `${process.env.REACT_APP_SHARE_URL}/Photo?id=${encryptedID}&Source=facebook`
    let shareURLtwitter = `${process.env.REACT_APP_SHARE_URL}/Photo?id=${encryptedID}&Source=twitter`
    let shareURLlinkedin = `${process.env.REACT_APP_SHARE_URL}/Photo?id=${encryptedID}&Source=linkedin`
    let shareURLemail = `${process.env.REACT_APP_SHARE_URL}/Photo?id=${encryptedID}&Source=email`


    // Email share content
    const emailSubject = sharePhotoInfo.shareType ? "Check out this photo I hunted!" : "I'm being Big Photo Hunted!";
    const emailBody = sharePhotoInfo.shareType
        ? "Hi!\n\nI found this photo in the running to be a Big Photo Hunt top ten. I like it and thought you might too!"
        : "Hi!\n\nMy photo is in the running to be a Big Photo Hunt top ten. It will help me get noticed, get more feedback, and get inspired. Please help me get there by giving me your Upvote!\n\n";


    // Twitter share content
    const twitterTitle = "My photo is being hunted on the Big Photo Hunt! Please help me get into the top ten by giving me your Upvote at the link below!";

    // Facebook share content
    const facebookQuote = "Hi Everyone! My photo is being hunted on the Big Photo Hunt. Please help me get into the top ten by giving me your Upvote at the link below. Thanks!";


    const CopyHandler = async (text) => {
        try {
            amplitudeHandler("Photo Share: Link")
            if (navigator.clipboard && navigator.clipboard.writeText) {
                await navigator.clipboard.writeText(shareURL0ther);
            } else {
                const fallbackInput = document.createElement("textarea");
                fallbackInput.value = text;
                document.body.appendChild(fallbackInput);
                fallbackInput.select();
                document.execCommand("copy");
                document.body.removeChild(fallbackInput);
            }
            setCopyPath(true);
            setTimeout(() => {
                setCopyPath(false);
            }, 2000);

        } catch (error) {
            console.log("Failed to copy link .");
        }
    }

    let amplitudeHandler = (val) => {
        let ShareType = !sharePhotoInfo.shareType ? "Share User Photo" : "Share Other User Photo"
        amplitude.getInstance().logEvent(ShareType);
        amplitude.getInstance().logEvent(val);
    }

    return (
        <>
            <div className="backdrop" />

            {/* Share Photo */}
            <div className="modal fade bph-modal show" id="sharePhotoModal" tabIndex={-1} aria-labelledby="sharePhotoModal" aria-hidden="true" style={{ display: "block" }}>
                <div className="modal-dialog modal-dialog-centered modalAnimation">
                    <div className="modal-content">
                        <div className="modal-body">
                            <div className="onboarding_modal sharePhoto_modal">
                                <div className="modal_inner">
                                    <button
                                        type="button"
                                        className="btn-close"
                                        data-bs-dismiss="modal"
                                        aria-label="Close"
                                        onClick={() => { dispatch(ModalAction.setSharePhotoModalState(false)) }}
                                    />
                                    {/* logo */}
                                    <div className="modal_logo d-none d-md-inline-flex">
                                        <img src="./assets/img/logo/modal-logo.png" />
                                    </div>
                                    {/* content */}
                                    <div className="modal_content">
                                        <div className="modal_title">
                                            <h2 className="title">{!sharePhotoInfo.shareType ? "Get more upvotes!" : "Share this photo!"}</h2>
                                            <p className="subtitle">{!sharePhotoInfo.shareType ? "Share your photo with friends. We’ll show them a special page to give you upvotes for your photo."
                                                :
                                                "Share this fellow Big Photo Hunter's photo for inspiration or just to show friends what you’re seeing around here."}</p>
                                        </div>
                                        {/* image */}
                                        <div className="modal_image">
                                            <img src={sharePhotoInfo.path} />
                                        </div>
                                        {/* share content */}
                                        <div className="share_content">
                                            <span className="label">Pick how you want to share this photo</span>

                                            <ul className="social_share">
                                                <li>
                                                    <WhatsappShareButton
                                                        onClick={() => amplitudeHandler("Photo Share: WhatsApp")}
                                                        url={shareURLwhatsapp}
                                                        windowWidth={700}
                                                        windowHeight={700}
                                                    >

                                                        <a ><img src="./assets/img/icons/icons8-whatsapp.svg" />
                                                            <span className="name">Whatsapp</span>
                                                        </a>
                                                    </WhatsappShareButton>
                                                </li>
                                                <li>
                                                    <TwitterShareButton
                                                        onClick={() => amplitudeHandler("Photo Share: X")}
                                                        url={shareURLtwitter}
                                                        title={twitterTitle}
                                                    >
                                                        <a ><img src="./assets/img/icons/twitter.svg" />
                                                            <span className="name">Twitter</span>
                                                        </a>
                                                    </TwitterShareButton>

                                                </li>
                                                <li>
                                                    <FacebookShareButton
                                                        onClick={() => amplitudeHandler("Photo Share: Facebook")}
                                                        url={shareURLfacebook}
                                                        quote={facebookQuote}
                                                        appId={process.env.REACT_APP_FACEBOOK_KEY}
                                                    >
                                                        <a ><img src="./assets/img/icons/facebook.svg" />
                                                            <span className="name">Facebook</span>
                                                        </a>
                                                    </FacebookShareButton>
                                                </li>
                                                <li>
                                                    <LinkedinShareButton
                                                        onClick={() => amplitudeHandler("Photo Share: LinkedIn")}
                                                        url={shareURLlinkedin}
                                                        summary='sssss'
                                                    >
                                                        <a ><img src="./assets/img/icons/linkedin.svg" />
                                                            <span className="name">LinkedIn</span>
                                                        </a>
                                                    </LinkedinShareButton>
                                                </li>
                                                <li onClick={() => amplitudeHandler("Photo Share: Email")}>
                                                    <EmailShareButton

                                                        url={shareURLemail}
                                                        subject={emailSubject}
                                                        body={emailBody}
                                                    >
                                                        <a ><img src="./assets/img/icons/email.svg" />
                                                            <span className="name">Email</span>
                                                        </a>
                                                    </EmailShareButton>

                                                </li>
                                            </ul>
                                            <div className="copy_link">

                                                <a onClick={CopyHandler}><img src="./assets/img/icons/link.svg" />
                                                    <span className="name">
                                                        {!CopyPath ? "Or copy link" : "Copied"}
                                                    </span>
                                                </a>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* ! Share Photo */}


        </>
    )
}

export default SharePhotoModal