import axios from 'axios';

export const getUserPaymentStatus = async (userToken) => {
    try {
        const headers = {
            'Authorization': `Bearer ${userToken}`,
            'Content-Type': 'application/json'
        };

        const response = await axios.post(
            `${process.env.REACT_APP_BASE_URL}/user/getPaymentStatus`,
            {},
            { headers }
        );

        return response; // Return only the relevant data
    } catch (error) {

        throw new Error('Failed to fetch payment status.');
    }
};



export const WhyYouSubscribedApi = async (userToken, val) => {
    try {
        const payload = {
            "subscription_reason": val.trim()
        };
        const headers = {
            'Authorization': `Bearer ${userToken}`,
            'Content-Type': 'application/json'
        };

        const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/user/updateSubscriptionReason`, payload, { headers });

        return response; // Return only the relevant data
    } catch (error) {

        throw new Error('Failed to fetch.');
    }
};