import React from 'react'
import MembersList from './MembersList'
import { useEffect, useState, useRef } from 'react'
import { useSelector } from 'react-redux';
import { decryptUserData } from '../../utils/encryptedData'
import { fetchNewMembers } from '../../utils/API/NewMemberApi';

let increment = 0;
const Members = () => {

  let [firstloadMembers, setfirstloadMembers] = useState([])
  let [loadMembers, setloadMembers] = useState([])
  let [CheckEmptyArry, setCheckEmptyArry] = useState(true)
  let [spinner, setspinner] = useState(false)

  // <---------------- decrypted data ------------->
  const encryptedUserData = localStorage.getItem('Data');
  const decryptedUserData = decryptUserData(encryptedUserData);
  let usertoken = decryptedUserData.token
  let isloggedin = decryptedUserData.isLoggedIn
  let LoginUserid = decryptedUserData.userid
  // <-------------------------------------------->


  let footer = useRef();
  let isLogginState = useSelector(state => state.Login.isLoggedIn)
  // ..............................

  let apiCall = (e) => {
    if (e[0].isIntersecting) {
      increment = increment + 10;

      let LoadMembers = async function () {

        setspinner(true)
       
        try {
         
          let response = await fetchNewMembers(increment, isloggedin, usertoken);

          const newMembers = response.data.data;
          setloadMembers(prevMembers => [...prevMembers, ...newMembers])

          if (newMembers.length == 0 || newMembers.length < 10) {
            setCheckEmptyArry(false)
          }
          setspinner(false)

        } catch (error) {

          setspinner(false)
        }
      }
      LoadMembers();
    }


  }




  ///////////

  useEffect(() => {
    const Observer = new IntersectionObserver(apiCall, {
      root: null,
      threshold: 0

    })

    if (footer.current) Observer.observe(footer.current)

  }, [firstloadMembers])
  ////////////
  useEffect(() => {

    let LoadMembers = async function () {
      increment = 0
      setspinner(true)
      try {
      let response = await fetchNewMembers(0, isloggedin, usertoken);

      setfirstloadMembers(response.data.data);
      if (response.data.data.length == 0 || response.data.data.length < 10) {
        setCheckEmptyArry(false)
      }
      setspinner(false)

    } catch (error) {

      setspinner(false)
    }
    // .............
  }
    LoadMembers();
}, [isLogginState])

  let AllLoadMembers;
if (increment === 0) {
  AllLoadMembers = [...firstloadMembers]
} else {

  AllLoadMembers = [...firstloadMembers, ...loadMembers]

}

const currentUrl = window.location.pathname;


return (
  <>

    {currentUrl !== "/new-members" ? <div className="new_member_sidebar">
      <div className="member_sidebar_content">
        <div className="sidebar_heading">
          <h3 className="title">New Members</h3>
        </div>

        <div className="member_items">


          {AllLoadMembers.map((data) => (<MembersList


            userPhoto={data.profile_pic}
            userId={data.id}
            userLocation={data.state}
            Name={data.name}
            key={data.id}
            isfollow={data.is_follow}
            iswelcome={data.is_welcome}
            genreslist={data.genres_list}
            camerabrand={data.camera_brand}
            loginUserid={LoginUserid}
          />))
          }
          {CheckEmptyArry && spinner && <div style={{ display: "flex", justifyContent: "center" }}>
            <div className="spinner-border text-secondary" role="status" >
              <span className="sr-only"></span>
            </div>
          </div>}






        </div>
      </div>
    </div>


      :

      <main className="site-content" id="main">
        {/* start: Photo Feed */}
        <section className="photo-feed-section new-members">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-md-6 col-lg-5">
                <div className="new_member_sidebar">
                  <div className="member_sidebar_content">
                    <div className="sidebar_heading">
                      <h3 className="title" style={{ fontSize: '18px' }}>New Photo Hunters</h3>
                      <span>Who's this?</span>
                    </div>
                    <div className="member_items">
                      {AllLoadMembers.map((data) => (<MembersList


                        userPhoto={data.profile_pic}
                        userId={data.id}
                        userLocation={data.state}
                        Name={data.name}
                        key={data.id}
                        isfollow={data.is_follow}
                        iswelcome={data.is_welcome}
                        genreslist={data.genres_list}
                        camerabrand={data.camera_brand}
                        loginUserid={LoginUserid}



                      />))
                      }

                      {CheckEmptyArry && spinner && <div style={{ display: "flex", justifyContent: "center" }}>
                        <div className="spinner-border text-secondary" role="status" >
                          <span className="sr-only"></span>
                        </div>
                      </div>}

                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* end: Photo Feed */}
      </main>
    }

    {CheckEmptyArry && firstloadMembers.length > 0 && <div ref={footer}></div>}
  </>
)
}

export default Members