import { createSlice } from "@reduxjs/toolkit";

let initialPayWallState = { PayWallModalTitleObj: {}, UserPaymentStatus: false, isSamCardSideBarOpen: false, SamCardProductName :"" };


let PayWallSlice = createSlice({
    name: 'PayWall',
    initialState: initialPayWallState,
    reducers: {
        setPayWallModalTitleObj(state, action) {
            state.PayWallModalTitleObj = action.payload
        },
        setUserPaymentStatus(state, action) {
            state.UserPaymentStatus = action.payload
        },
        setisSamCardSideBarOpen(state, action) {
            state.isSamCardSideBarOpen = action.payload
        },
        setSamCardProductName(state, action) {
            state.SamCardProductName = action.payload
        },


    }
})

export let PayWallAction = PayWallSlice.actions;
export default PayWallSlice