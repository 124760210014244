import React from 'react'
import { Link } from 'react-router-dom'
import { useRef, useState } from 'react'
import Swal from 'sweetalert2'
import LightBoxModal from './UI/LightBoxModal'
import { addNewsletterRecipient } from '../utils/API/SubscribeApi'


const Footer = () => {
  let [spinner, setspinner] = useState(false)

  let SubscribeRef = useRef();

  let submithandler = async function (event) {
    event.preventDefault();
    let SubscribeRefValue = SubscribeRef.current.value;
    setspinner(true)
    try {
      const response = await addNewsletterRecipient(SubscribeRefValue);
      Swal.fire('', response.data.data.response, 'success');
      SubscribeRef.current.value = '';
    } catch (error) {
      console.error('Error:', error);
      Swal.fire('', error.response.data.message, 'error');
    } finally {
      setspinner(false);
    }
  }

  return (
    <>
      <footer className="site-footer" id="footer">
        <div className="container">
          <div className="row">
            <div className="col-md-6 col-lg-3 order-4 order-md-1">
              <div className="footer_widget">
                <div className="site_info">
                  <ul className="socials d-md-none">
                    <li>
                      <Link to="#" target="_blank">
                        <img src="./assets/img/icons/twitter.svg" alt='img'/>
                      </Link>
                    </li>
                    <li>
                      <Link to="#" target="_blank">
                        <img src="./assets/img/icons/instagram.svg" alt='img'/>
                      </Link>
                    </li>
                    <li>
                      <Link to="#" target="_blank">
                        <img src="./assets/img/icons/facebook.svg" alt='img'/>
                      </Link>
                    </li>
                  </ul>
                  <div className="footer_logo">
                    <Link to="/">
                      <img
                        src="./assets/img/logo/footer-logo.png"
                        alt="Footer Logo"
                      />
                    </Link>
                  </div>
                  <div className="hash_tag">
                    <span>#pointshootupvote</span>
                  </div>
                  <div className="info_text">
                    <p>
                      The Big Photo Hunt grows your photography in a friendly community of aspiring and amateur photographers.
                    </p>
                  </div>
                  <ul className="socials d-none d-md-block">
                    <li>
                      <Link to={"https://twitter.com/bigphotohunt"} target="_blank">
                        <img src="./assets/img/icons/twitter.svg" alt='img'/>
                      </Link>
                    </li>
                    <li>
                      <Link to={"https://www.instagram.com/bigphotohunt/"} target="_blank">
                        <img src="./assets/img/icons/instagram.svg" alt='img'/>
                      </Link>
                    </li>
                    <li>
                      <Link to={"https://www.facebook.com/bigphotohunt"} target="_blank">
                        <img src="./assets/img/icons/facebook.svg" alt='img'/>
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-lg-2 order-1 offset-lg-1 order-md-2">
              <div className="footer_widget">
                <div className="footer_title">Info</div>
                <div className="footer_menu">
                  <ul onClick={() => { window.scrollTo(0, 0); }}>
                    <li>
                      <Link to={'/about'} >About Us</Link>
                    </li>
                    <li>
                      <Link to={'/how-works'}  >How It Works</Link>
                    </li>
                    {/* <li>
                      <Link to="#">FAQ</Link>
                    </li> */}
                    <li>
                      <Link to={'/meet-terry'} >A Mouse?</Link>
                    </li>
                    <li>
                      <Link to={'/contact'} >Contact</Link>
                    </li>
                    <li>
                      <Link to="https://bigphotohunt.buzzsprout.com" target='_blank'>Podcast</Link>
                    </li>
                    <li>
                      <Link to={'/terms'} >Terms</Link>
                    </li>
                    <li>
                      <Link to={'/policy'} >Privacy Policy</Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-lg-2 order-2 order-md-3">
              <div className="footer_widget">
                <div className="footer_title">Be Recognized</div>
                <div className="footer_menu">
                  <ul onClick={() => { window.scrollTo(0, 0); }}>
                    {/* <li>
                      <Link to="#">Winners Page</Link>
                    </li> */}
                    <li>
                      <Link to={"/topten"}>Weekly Top 10</Link>
                    </li>

                    {/* <li>
                      <Link to={'/giveaway'}>This Month’s Prize</Link>
                    </li> */}
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-lg-4 order-3 order-md-4">
              <div className="footer_widget">
                <div className="footer_subscription">
                  <div className="title">Receive Our 1x Week Newsletter</div>
                  <form className="form" onSubmit={submithandler}>
                    <input
                      type="email"
                      name="email"
                      id="email"
                      placeholder="Email"
                      ref={SubscribeRef}
                      required
                    />
                    {!spinner ?
                      <button className="btn" type="submit" style={{ width: "100%" }}> Subscribe </button>
                      :
                      <button className="btn" style={{ width: "100%" }} >
                        <span className="loader"></span>
                      </button>}
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>

      {/* <LightBoxModal /> */}



    </>
  )
}

export default Footer