import axios from 'axios';

export const FollowerImagesApi = async (user_id, usertoken) => {
    try {
        const payload = {
            "user_id": user_id,
        };
        const headers = {
            'Authorization': `Bearer ${usertoken}`,
            'Content-Type': 'application/json'
        };
        const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/follow/upvoted_Photos`, payload, { headers });

       

        return response;
    } catch (error) {

        throw error; // rethrow the error if you want to handle it in the component
    }
};