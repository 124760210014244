import React, { useState } from 'react'
import { decryptUserData } from '../../utils/encryptedData'
import axios from 'axios'
import { useSelector } from 'react-redux'
import { SocketAction } from '../../Store/SocketSlice'
import { useDispatch } from 'react-redux'
import amplitude from "amplitude-js"


const ReplyInput = (props) => {
    let [commentValue, setcommentValue] = useState("")
    let [Loader, setLoader] = useState(false)



    // <---------------- decrypted data ------------->
    const encryptedUserData = localStorage.getItem('Data');
    const decryptedUserData = decryptUserData(encryptedUserData);
    let display_name = decryptedUserData.useDisplay_name
    let displaySocailUserPhoto = decryptedUserData.Display_UserSocailPhoto
    let usertoken = decryptedUserData.token
    
    // <-------------------------------------------->

    // < --------Socket ------->
    let dispatch = useDispatch()
    let ReloadNotificationFn = useSelector(state => state.Socket.ReloadNotificationSenderFn)

    let SendNotificationFn = () => {
        dispatch(SocketAction.setSocketReceiverUserID(props.userid))
        dispatch(SocketAction.setReloadNotificationSenderFn(!ReloadNotificationFn))
    }
    // <-------------------------------------------->


    let commentInputHandler = (e) => {
        setcommentValue(e.target.value)
    }

    let SubmitHandler = async (e) => {
        e.preventDefault()

        setLoader(true)
        try {
            let payload;
            if (props.userCommentinfo.replyCommentID) {
                payload = {
                    "photoID": props.userCommentinfo.photoID,
                    "commentText": commentValue,
                    "topcommentID": props.userCommentinfo.parentCommentID,
                    "subcommentID": props.userCommentinfo.replyCommentID,
                };
            } else {
                payload = {
                    "photoID": props.userCommentinfo.photoID,
                    "commentText": commentValue,
                    "topcommentID": props.userCommentinfo.parentCommentID,
                };
            }



            let headers = {
                'Authorization': `Bearer ${usertoken}`,
                'Content-Type': 'application/json'
            }
            const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/comment/add`, payload, { headers });

            SendNotificationFn()
            amplitude.getInstance().logEvent('Comment Reply');


            props.setvalueFn({
                id: response.data.data.id,
                comment_text: response.data.data.comment_text,
                username: display_name,
                user_profile_pic: displaySocailUserPhoto,
                createdAt: response.data.data.createdAt,
                parentCommentID: props.userCommentinfo.parentCommentID,
                replyToName: response.data.data.replyToName,
                totalVote: response.data.data.total_likes,
                isVote: response.data.data.is_vote,
                user_id: response.data.data.user_id,
                photoID: props.userCommentinfo.photoID
            })


            setcommentValue('')
            setLoader(false)

        } catch (error) {

            setLoader(false)
        }





    }


    return (
        <>
            <form onSubmit={SubmitHandler} style={{ width: '100%', marginLeft: '50px' }}>
                <div className="feed_comment">
                    <div className="comment_user"
                        style={{
                            backgroundImage: `url(${displaySocailUserPhoto || ""})`,
                            backgroundPosition: "center",
                            backgroundSize: "cover",
                            backgroundRepeat: "no-repeat",
                            borderRadius: "50%",
                            cursor: "pointer",
                        }}
                    >
                        {!displaySocailUserPhoto && <img src={`https://ui-avatars.com/api/?name=${display_name}&size=40&rounded=true&background=01655a&color=fff`}  alt='img'/>}
                    </div>
                    <div className="comment_field">
                        <input type="text" className={`${commentValue ? "typed" : ''}`} id="comment" placeholder={`@${props.userCommentinfo.username}`} value={commentValue} onChange={commentInputHandler} />
                        {!Loader ? <button type="submit" className="comment-btn">
                            <img src="./assets/img/icons/subtract.svg" alt='img' />
                        </button> :
                            <span className={`${commentValue ? "Commentloader" : ''}`}></span>}
                    </div>
                </div>
            </form>
        </>
    )
}

export default ReplyInput