import React from 'react'
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'



const MainImageSkeleton = () => {
    return (
       
            <Skeleton width="100%" height="100%" style={{width:"100%",aspectRatio:" 1.5/1",borderRadius:'20px'}} />
        

    )
}

export default MainImageSkeleton