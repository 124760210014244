import { useEffect, useRef } from 'react';

const useCloseModalHandler = (onClose) => {
    const modalRef = useRef(null);

    useEffect(() => {


        const handleOutsideClick = (event) => {

            if (modalRef.current && !modalRef.current.contains(event.target)) {
                onClose();
            }
        };

        document.addEventListener('mousedown', handleOutsideClick);

        return () => {
            document.removeEventListener('mousedown', handleOutsideClick);
        };
    }, [onClose]);

    return modalRef;
};

export default useCloseModalHandler;

// import { useEffect, useRef } from 'react'; // Importing hooks

// const useCloseModalHandler = (onClose, modalId) => {
//     const modalRef = useRef(null);

//     useEffect(() => {
//         const handleOutsideClick = (event) => {
//             // Ensure that the click was outside the current modal
//             if (modalRef.current && !modalRef.current.contains(event.target) && event.target.id === modalId) {
//                 onClose();
//             }
//         };

//         document.addEventListener('mousedown', handleOutsideClick);

//         return () => {
//             document.removeEventListener('mousedown', handleOutsideClick);
//         };
//     }, [onClose, modalId]);

//     return modalRef;
// };

// export default useCloseModalHandler;

