import React from 'react'
import UserData from './UserData'
import FollowAndThanksButton from './FollowAndThanksButton'


const WelcomeNotification = ({ NotificationData, usertoken, loginUserid, is_seen }) => {
    return (
        <>

            <UserData NotificationData={NotificationData} message={"just welcomed you!"} is_seen={is_seen}>
                <FollowAndThanksButton
                    thanksBtnHidden={true}
                    usertoken={usertoken}
                    NotificationData={NotificationData}
                    loginUserid={loginUserid}
                />
            </UserData>


        </>
    )
}

export default WelcomeNotification