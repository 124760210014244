import React from 'react'
import { calculateTimeDifference } from '../../utils/CalculateCommentTime';
const CreatedAtDateFormat = ({date}) => {

    const NotificationCreatedAt = calculateTimeDifference(date);

    return (
        <span className="value time-indicator d-none d-md-inline">{NotificationCreatedAt}</span>
    )
}

export default CreatedAtDateFormat
