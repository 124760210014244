import React from 'react'
import { Link } from 'react-router-dom'
import { useDispatch } from "react-redux";
import { ModalAction } from "../../Store/Modal-Slice";



const NoPhotoSection = (props) => {


  let dispatch = useDispatch()

  let PhotoUploadModalHandler = () => {
    dispatch(ModalAction.setPhotoUploadModalState(true))
    dispatch(ModalAction.setPhotoUploadType("hunted_photo"))
    dispatch(ModalAction.setisQueued({ queue: false }))
  }
  return (
    <>
      <main className="site-content" id="main">
        {/* <!-- start: Photo Feed --> */}
        <section className="photo-feed-section profile-no-photo">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <div className="feed_content">
                  {props.showMenu && <ul className="heading_tabs" role="tablist">
                    <li
                      className="active"
                      role="presentation"
                      data-bs-toggle="tab"
                      data-bs-target="#profile"
                    >
                      My Hunted Photos
                    </li>
                    <li
                      role="presentation"
                      data-bs-toggle="tab"
                      data-bs-target="#upvotes"
                    >
                      My Upvotes
                    </li>

                    <li
                      role="presentation"
                      data-bs-toggle="tab"
                      data-bs-target="#gallery"
                    >
                      My Personal Gallery
                    </li>
                  </ul>}

                  <div className="tab-content" id="profileTabContent">
                    <div
                      className={`tab-pane fade show ${props.myphoto ? "active" : ''} `}
                      id="profile"
                      role="tabpanel"
                    >
                      <div className="no_photo_content">
                        <img src="./assets/img/icons/no-photo.svg" alt="img" />
                        <h4 className="title">
                          Looks like you don’t have any photos hunted yet
                        </h4>
                        <p>Post your first photo to start being a part of the community.</p>

                        <div style={{ marginTop: "20px" }}>
                          <button className="btn" onClick={PhotoUploadModalHandler}>
                            Hunt your first photo‌
                          </button>
                        </div>
                      </div>
                    </div>

                    <div className={`tab-pane fade show ${props.Upvotephoto ? "active" : ''} `} id="upvotes" role="tabpanel">
                      <div className="no_photo_content">
                        <img src="./assets/img/icons/no-photo.svg" alt="img" />
                        <h4 className="title">
                          Looks like you haven’t given anyone an upvote yet.
                        </h4>
                        <p>Give an upvote to start celebrating your favorite photographers.</p>

                        <div>
                          <Link to="/" className="btn" >
                            Give an upvote
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* <!-- end: Photo Feed --> */}
      </main>
    </>
  )
}

export default NoPhotoSection