
import axios from 'axios';

export const fetchNewMembers = async (increment, isloggedin, usertoken) => {
    try {
        const payload = {
            userType: isloggedin ? "by_user" : "all",
            offSet: increment
        };
        const headers = {
            'Content-Type': 'application/json'
        };
        if (usertoken) {
            headers['Authorization'] = `Bearer ${usertoken}`;
        }

        const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/photo/list_photo_for_new_members`, payload, { headers });

        return response
    } catch (error) {
        throw error;
    }
};
