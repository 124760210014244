import React from 'react'
import { ShortAddress } from '../../utils/Address';
import { AES } from 'crypto-js'
import { useNavigate } from 'react-router-dom';
import CreatedAtDateFormat from './CreatedAtDateFormat';

const UserData = ({ children, NotificationData, message, ChildAfterUl, is_seen }) => {



    let navigate = useNavigate()

    let NavigatorHandler = () => {
        let userData = {
            PhotoID: "",
            UserID: NotificationData.sender_id,
        }
        const secretKey = process.env.REACT_APP_SECRET_KEY;
        const encryptedUserData = AES.encrypt(JSON.stringify(userData), secretKey).toString();
        navigate(`/otherusers-profile?userdata=${encodeURIComponent(encryptedUserData)}`);
        window.scrollTo(0, 0)
    }


    ///address
    let address = ShortAddress(NotificationData.state, 16);

    const genresString = NotificationData.genres_list ? Object.values(NotificationData.genres_list).slice(0, 3).join(", ") : "";
    return (
        <>
            <div className={`profile-card ${!is_seen ? "new-card" : ''} style--two`}>
                <div className="profile-card-inner">
                    <div className="profile-card-top-content mb-0">
                        <div className="profile-card-thumb">
                            <span className='PointerClass' onClick={NavigatorHandler} >
                                <img src={NotificationData.profile_pic ? NotificationData.profile_pic : `https://ui-avatars.com/api/?name=${NotificationData.name}&size=40&rounded=true&background=01655a&color=fff`} alt="profile" />
                            </span>
                        </div>
                        <div className="profile-card-content">
                            <ul className="profile-info-list">
                                <li className="name_info"><span className="title">
                                    <a onClick={NavigatorHandler} className='PointerClass text-title'>{NotificationData.name}</a>
                                </span> <span className="value font--sm text-title">{message}</span> <CreatedAtDateFormat date={NotificationData.notification_createdAt} /></li>
                                <li className="mb-1 location_info"><span className="value font--sm ">{address}</span></li>
                                {NotificationData.camera_brand && NotificationData.camera_brand !== "null" && <li className="d-none d-sm-block camera_info"><span className="title ">Camera: </span> <span className="value">{NotificationData.camera_brand}</span></li>}
                                {genresString && <li className="d-none d-sm-block genres_info"><span className="title ">Genres: </span> <span className="value">{genresString}</span></li>}
                            </ul>

                            <div className='desFollow-thank-btn'>
                                {!ChildAfterUl && children}
                            </div>


                        </div>
                    </div>
                </div>
                <ul className="text-start profile-info-list d-sm-none">
                    {NotificationData.camera_brand && NotificationData.camera_brand !== "null" && <li className="camera_info"><span className="title text-title">Camera: </span> <span className="value">{NotificationData.camera_brand}</span></li>}
                    {genresString && <li className="genres_info"><span className="title text-title">Genres: </span> <span className="value">{genresString}</span></li>}
                </ul>
                <div className="d-sm-none mobileFollow-thank-btn">
                    {!ChildAfterUl && children}
                </div>
                {ChildAfterUl && children}
            </div>


        </>
    )
}

export default UserData