
import React from 'react'
import { useState } from 'react';
import { useEffect } from "react";
import axios from "axios";
import Commentslist from './Commentslist';
import { decryptUserData } from '../../utils/encryptedData'



let increment = 0;
const CommentsListContainer = (props) => {
    let [loadcomments, setloadcomments] = useState([])
    let [ShowMorebtn, setShowMorebtn] = useState([])
    let [checkEmptyArr, setcheckEmptyArr] = useState(true)
    let [spinner, setspinner] = useState(false)



    // <---------------- decrypted data ------------->
    const encryptedUserData = localStorage.getItem('Data');
    const decryptedUserData = decryptUserData(encryptedUserData);
    let usertoken = decryptedUserData.token
    // <-------------------------------------------->

    let ViewMoreCommentsHandler = async (event) => {
        event.preventDefault();
        increment = increment + 4;


      
        setspinner(true)
        try {
            const payload = {
                "photoID": props.postid,
                "offSet": increment
            };
            let headers = {
                'Authorization': `Bearer ${usertoken}`,
                'Content-Type': 'application/json'
            }
            const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/comment/list`, payload, { headers });
    
            let newComments = response.data.data.comments
            setloadcomments(prevComments => [...prevComments, ...newComments])
            setspinner(false)

        } catch (error) {
         

        }
    }

    useEffect(() => {
        increment = 0;
    }, [props.postid])

    ////////////////////////////////////////
    useEffect(() => {

        let LoadcommentsList = async function () {

            setspinner(true)

            try {
                const payload = {
                    "photoID": props.postid,
                    "offSet": 0
                };
                let headers = {
                    'Authorization': `Bearer ${usertoken}`,
                    'Content-Type': 'application/json'
                }
                const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/comment/list`, payload, { headers });
                
                setloadcomments(response.data.data.comments)
                setShowMorebtn(response.data.data.total_comments)
                setspinner(false)

                if (response.data.data.total_comments < 4) {
                    setcheckEmptyArr(false)
                }

            } catch (error) {
            
            }
        }
        LoadcommentsList();
    }, [])

    useEffect(() => {

        if (loadcomments.length === ShowMorebtn) {
            setcheckEmptyArr(false)
        }
    }, [loadcomments])



    
    useEffect(() => {
        if (props.inputValue) {
            let newARR = [...loadcomments, props.inputValue];
            setloadcomments(newARR);
        }
    }, [props.inputValue])
    
    return (
        <>

            <div className="comment_lists">
                <ul>



                    {loadcomments?.map((data) => (
                        <Commentslist
                            commentText={data.comment_text}
                            username={data.username}
                            userid={data.user_id}
                            key={data.id}
                            id={data.id}
                            userPhoto={data.user_profile_pic}
                            CeatedAt={data.createdAt}
                            isVote={data.is_vote}
                            is_follow={data.is_follow}
                            totalVote={data.total_votes}
                            totalReplies={data.total_replies}
                            photoID={props.postid}
                            usertoken={usertoken}
                            loginUserid={props.loginUserid}

                        />))
                    }
                    {spinner && <div style={{ display: "flex", width: "33px", height: "30px" }}>
                        <div className="spinner-border text-warning" role="status">
                            <span className="visually-hidden" />
                        </div>

                    </div>}

                    
                </ul>
                {checkEmptyArr && !spinner && <p className="more_comment">
                    <span className='PointerClass' onClick={ViewMoreCommentsHandler}>
                        <img src="./assets/img/icons/comment.svg" alt='img'/>
                        View More
                    </span>
                </p>}
            </div>


        </>
    )
}

export default CommentsListContainer