import React from 'react'
import FollowerAndFollowingContainer from '../Components/FollowersAndFollowing/FollowerAndFollowingContainer'

const UserFollowersAndFollowingPage = ({ MarginTopNone }) => {
    return (
        <>
          <FollowerAndFollowingContainer MarginTopNone={true}/>

        </>
    )
}

export default UserFollowersAndFollowingPage