import axios from 'axios';
import axiosInstance from '../Services/AxiosInstance';



export const fetchNotifications = async (increment, usertoken) => {
  try {
    const payload = {
      offset: increment
    };
    const headers = {
      Authorization: `Bearer ${usertoken}`,
      'Content-Type': 'application/json'
    };
    const response = await axiosInstance.post(`${process.env.REACT_APP_BASE_URL}/notify/user`, payload, { headers });

    return response;
  } catch (error) {
    throw error;
  }
};



export const CheckNewNotification = async (usertoken) => {
  try {
    const headers = {
      'Authorization': `Bearer ${usertoken}`,
      'Content-Type': 'application/json'
    };

    const response = await axiosInstance.post(`${process.env.REACT_APP_BASE_URL}/notify/getNotification`, {}, { headers });
    return response
    
  } catch (error) {
    
    throw error;
  }
};