import React from 'react'
import axios from 'axios'
import { useState } from 'react'
import { useNavigate } from "react-router-dom"
import { useDispatch } from "react-redux";
import { ModalAction } from '../../Store/Modal-Slice'
import { PayWallAction } from '../../Store/PayWallSlice';
import Commentslist from '../Comments/Commentslist'
import CommentsListContainer from '../Comments/CommentsListContainer'
import { decimalToFraction } from '../../utils/shutterSpeed'
import { formatDate } from '../../utils/Formatteddate'
import { AES } from 'crypto-js'
import { useSelector } from 'react-redux'
import amplitude from 'amplitude-js';
import { SocketAction } from '../../Store/SocketSlice'
import Swal from 'sweetalert2';



const ImageDescription = (props) => {
    let [showCommentlist, setShowCommentlist] = useState(false)
    let [MetaDataState, setMetaDataState] = useState(false)
    let [commentValue, setcommentValue] = useState("")
    let [PlaceholderValue, setPlaceholderValue] = useState(false)
    let [CommentValuePushInToCommentList, setCommentValuePushInToCommentList] = useState("")
    let [FirstCommentArr, setFirstCommentArr] = useState(props.First1Comments)
    let [Loader, setLoader] = useState(false)
    let [TotalComments, setTotalComments] = useState(Number(props.TotalComments))




    // < --------Socket ------->
    let dispatch = useDispatch()
    let ReloadNotificationFn = useSelector(state => state.Socket.ReloadNotificationSenderFn)

    let SendNotificationFn = () => {
        dispatch(SocketAction.setSocketReceiverUserID(props.Userid))
        dispatch(SocketAction.setReloadNotificationSenderFn(!ReloadNotificationFn))
    }
    // <-------------------------------------------->


    let navigate = useNavigate()



    let metadataObject = props.metadata ? JSON.parse(props.metadata) : {};
    let shutterSpeed;
    let takkenDate;
    if (MetaDataState) {


        shutterSpeed = decimalToFraction(metadataObject?.shutterSpeed)
        takkenDate = formatDate(metadataObject?.dateTaken)
    }


    let commentInputHandler = (e) => {
        setcommentValue(e.target.value)
    }

    let SharePhotobtnHandler = () => {
        dispatch(ModalAction.setSharePhotoModalState(true))

        dispatch(ModalAction.setShareModalPhotoInfo(
            {
                "path": props.img,
                "id": props.postId,

            }
        ))


    }



    let CameraSettingHandler = () => {
        // if(!props.UserPaymentStatus){
        //     dispatch(ModalAction.setisPayWallOpen(true))
        //     dispatch(PayWallAction.setPayWallModalTitleObj({ Title: "See how photos were made with access to Members’ camera settings" }))
        //     return
        // }
        setMetaDataState(!MetaDataState)
        if (!MetaDataState) {
            amplitude.getInstance().logEvent('Camera Settings', { UserID: props.loginUserid });
        }

    }

    ////
    let EditPageHandler = () => {
        const secretKey = process.env.REACT_APP_SECRET_KEY;
        const encryptedUserData = AES.encrypt(JSON.stringify(props.postId), secretKey).toString();
        navigate(`/edit-photo?photoid=${encodeURIComponent(encryptedUserData)}`)
    }
    ////

    let commentlistloadHandler = () => {
        setShowCommentlist(!showCommentlist)
        // setShowCommentlist2(!showCommentlist2)
    }

    let commentlisthideHandler = () => {
        setShowCommentlist(!showCommentlist)
        // setShowCommentlist2(!showCommentlist2)
    }

    let SubmitHandler = async (e) => {
        e.preventDefault()
        setLoader(true)
        SendNotificationFn()
        try {
            const payload = {
                "photoID": props.postId,
                "commentText": commentValue
            };
            let headers = {
                'Authorization': `Bearer ${props.usertoken}`,
                'Content-Type': 'application/json'
            }
            const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/comment/add`, payload, { headers });

            amplitude.getInstance().setUserId(props.loginUserid);
            amplitude.getInstance().logEvent('Comment', { UserID: props.loginUserid });

            if (showCommentlist) {
                setTotalComments(TotalComments + 1)
                setCommentValuePushInToCommentList({
                    id: response.data.data.id,
                    comment_text: response.data.data.comment_text,
                    username: props.Name,
                    user_profile_pic: props.displayUserSocailPhoto,
                    createdAt: response.data.data.createdAt,
                    is_vote: response.data.data.is_vote,
                    total_likesdata: response.data.data.itotal_likesdata,
                    photoID: props.postId
                })
            } else {
                setTotalComments(TotalComments + 1)

                let newFirstComment = {
                    id: response.data.data.id,
                    comment_text: response.data.data.comment_text,
                    username: props.Name,
                    user_profile_pic: props.displayUserSocailPhoto,
                    createdAt: response.data.data.createdAt,
                    is_vote: response.data.data.is_vote,
                    total_likesdata: response.data.data.itotal_likesdata,
                    photoID: props.postId,
                    user_id: response.data.data.user_id
                }

                setFirstCommentArr([newFirstComment]);


            }
            setcommentValue('')
            setLoader(false)
        } catch (error) {

        }

        setcommentValue('')
        setPlaceholderValue(true)
        setLoader(false)


    }




    return (
        <>

            {props.description ? <div className="feed_description">
                <div className="text"><span style={{ fontWeight: '500', color: 'black' }}>About My Photo:</span> {props.description}</div>
                {props.show && <a onClick={EditPageHandler} className="btn feed-edit d-md-none"><img src="./assets/img/icons/pencil.svg" alt="img" /></a>}
                {props.show && <a onClick={EditPageHandler} className="btn feed-edit d-none d-md-inline-flex"><img src="./assets/img/icons/pencil.svg" alt="img" />Edit</a>}
            </div> :
                (props.show && <div className="feed_description">
                    <div className="add_desc_btn">
                        <a onClick={EditPageHandler} className="btn btn-feedback">Add Description</a>
                    </div>
                </div>)
            }


            <div className={`${!props.show && props.feedback && props.feedback["I am not looking for feedback"] ? "dis" : ""} ${!props.show ? (props.feedback && props.feedback["I am not looking for feedback"] !== "I am not looking for feedback" || props.metadata && Object.keys(metadataObject).length > 0 ? "feed_feedback" : " ") : "feed_feedback "}`}>
                {props.feedback && props.feedback["I am not looking for feedback"] !== "I am not looking for feedback" ? <div className="feedback_cat">

                    <ul style={{ flexWrap: 'wrap' }}>
                        <h5 className="label">Helpful Feedback:</h5>
                        {Object.values(props.feedback).map((item, index, array) => (

                            <li key={index}>
                                <a>{item}</a>
                            </li>

                        ))}
                    </ul>
                    {props.show && <a onClick={EditPageHandler} className="btn feed-edit d-md-none"><img src="./assets/img/icons/pencil.svg" alt="img" /></a>}
                    {props.show && <a onClick={EditPageHandler} className="btn feed-edit d-none d-md-inline-flex"><img src="./assets/img/icons/pencil.svg" alt="img" />Edit</a>}
                </div> :
                    (props.show && <div className="choose_feedback">
                        <a onClick={EditPageHandler} className="btn btn-feedback">Choose Specific Feedback</a>
                    </div>)}
                {props.metadata && Object.keys(metadataObject).length > 0 && <div className="metadata d-none d-md-inline-flex">
                    <img className="exif" src="./assets/img/icons/exif.svg" alt="img" />
                    <button className={`${MetaDataState ? 'active' : ''}`} type="button" onClick={props.isLoggedIn ? CameraSettingHandler : () => { dispatch(ModalAction.setJoinNowModalState(true)) }}>
                        Camera Settings
                        <img className="arrow" src="./assets/img/icons/arrow-up.svg" alt="img" />
                    </button>
                </div>}
            </div>


            {props.metadata && Object.keys(metadataObject).length > 0 && <div className="metadata d-md-none">
                <img className="exif" src="./assets/img/icons/exif.svg" alt="img" />
                <button className={`${MetaDataState ? 'active' : ''}`} type="button" onClick={props.isLoggedIn ? CameraSettingHandler : () => { dispatch(ModalAction.setJoinNowModalState(true)) }}>
                    Camera Settings
                    <img className="arrow" src="./assets/img/icons/arrow-up.svg" alt="img" />
                </button>
            </div>}


            <div className={`feed_device_info  ${MetaDataState ? 'expand' : ''}`}>
                <div>
                    <div className='feed_device_info-inter-Div'>
                        {metadataObject.cameraName && <h5 className="device_name">{metadataObject.cameraName}</h5>}
                        <div className="device_info">
                            <div className="info_box">
                                {metadataObject.focalLength && <span><strong>Focal Length:</strong> {metadataObject.focalLength}</span>}
                                {metadataObject.lens && <span><strong>Lens FE:</strong> {metadataObject.lens}</span>}
                            </div>
                            <div className="info_box">
                                {metadataObject.aperture && <span><strong>Aperture:</strong> {metadataObject.aperture}</span>}
                                {metadataObject.iso && <span><strong>ISO:</strong> {metadataObject.iso}</span>}
                            </div>
                            <div className="info_box">
                                {metadataObject.shutterSpeed && <span><strong>Shutter Speed:</strong> {shutterSpeed} s</span>}
                                {metadataObject.dateTaken && <span><strong>Date Taken:</strong> {takkenDate}</span>}
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {props.isLoggedIn && <div className="feed_comment ">
                <div className="following_users">
                    {!showCommentlist && (TotalComments == 1) && <span

                        className="count"
                        id="viewComment"

                    >
                        Add your comment to this photo.
                    </span>}
                    {!showCommentlist && (TotalComments > 1) && <a

                        className="count"
                        id="viewComment"
                        onClick={commentlistloadHandler}
                    >
                        View all {TotalComments} comments
                    </a>}
                    {showCommentlist && <a
                        id="hideComment"
                        onClick={commentlisthideHandler}
                        className='hide_comment'
                    >
                        Hide Comments
                    </a>}
                    {(!FirstCommentArr && TotalComments == 0) && <span className="count">Be first to leave a comment!</span>}
                </div>
                {props.sharebtn && <div className="share_btn d-none d-md-block">
                    <a className="btn" onClick={SharePhotobtnHandler}>
                        Share for more votes!</a>
                </div>}
            </div>}
            {props.isLoggedIn && !showCommentlist && <div className="comment_lists viewList">
                <ul>
                    {FirstCommentArr?.map((data) => (
                        (<Commentslist
                            commentText={data.comment_text}
                            username={data.username}
                            userid={data.user_id}
                            key={data.id}
                            id={data.id}
                            userPhoto={data.user_profile_pic}
                            CeatedAt={data.createdAt}
                            isVote={data.is_vote}
                            totalVote={data.total_votes}
                            totalReplies={data.total_replies}
                            photoID={props.postId}
                            usertoken={props.usertoken}
                            loginUserid={props.loginUserid || data.user_id}
                            is_vote={props.is_vote}
                            is_follow={data.is_follow}

                        />))
                    )}

                </ul>
            </div>}




            {/* comment list */}
            {props.isLoggedIn && showCommentlist &&
                <CommentsListContainer
                    postid={props.postId}
                    inputValue={CommentValuePushInToCommentList}
                    loginUserid={props.loginUserid}
                />}
            {props.isLoggedIn && <form onSubmit={SubmitHandler} >
                <div className="feed_comment">
                    <div className="comment_user"
                        style={{
                            backgroundImage: `url(${props.displayUserSocailPhoto || ""})`,
                            backgroundPosition: "center",
                            backgroundSize: "cover",
                            backgroundRepeat: "no-repeat",
                            borderRadius: "50%",
                            cursor: "pointer",
                        }}
                    >
                        {!props.displayUserSocailPhoto && <img src={`https://ui-avatars.com/api/?name=${props.Name}&size=40&rounded=true&background=01655a&color=fff`} alt="img" />}
                    </div>
                    <div className="comment_field">
                        <input type="text" name="comment" placeholder={!PlaceholderValue ? "Add feedback and make a photographer smile." : "Thanks! Feedback makes the (photography) world go ‘round!"} className={`${commentValue ? "typed" : ''}`} value={commentValue} onChange={commentInputHandler} />
                        {!Loader ? <button type="submit" className="comment-btn">
                            <img src="./assets/img/icons/subtract.svg" alt="img" />
                        </button> :
                            <span className={`${commentValue ? "Commentloader" : ''}`}></span>}
                    </div>
                </div>

            </form>}






            {/* <!-- mobile share button --> */}
            {/* {props.isLoggedIn && props.sharebtn && <div className="share_btn d-md-none">
                <a className="btn" onClick={SharePhotobtnHandler}>
                    Share for more votes!
                </a>
            </div>} */}


        </>
    )
}

export default ImageDescription