import axios from 'axios';

export const RemainderApi = async (usertoken, PaidUser) => {
    let payload = {}
    if (PaidUser) {
        payload = {
            "queuedPhotos": 4,
            "isPaid": true
        }
    }

    try {
        let headers = {
            'Authorization': `Bearer ${usertoken}`
        };
        const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/user/sendPhotoReminder`, payload, { headers });
        return response;
    } catch (error) {

        throw error;
    }
};

