export function calculateTimeDifference(postedTimestamp) {
    const now = new Date(); // Current date and time
    const postedTime = new Date(postedTimestamp); // Convert posted timestamp to a Date object

    const timeDiff = now - postedTime; // Time difference in milliseconds
    const seconds = Math.floor(timeDiff / 1000); // Convert milliseconds to seconds

    if (seconds < 60) {
      return `${seconds}s ago`;
    } else if (seconds < 3600) {
      const minutes = Math.floor(seconds / 60);
      return `${minutes}m ago`;
    } else if (seconds < 86400) {
      const hours = Math.floor(seconds / 3600);
      return `${hours}h ago`;
    } else if (seconds < 2592000) {
      const days = Math.floor(seconds / 86400);
      return `${days}d ago`;
    } else if (seconds < 31536000) {
      const months = Math.floor(seconds / 2592000);
      return `${months}mo ago`;
    } else {
      const years = Math.floor(seconds / 31536000);
      return `${years}y ago`;
    }
  }