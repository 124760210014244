import React from 'react'
import UserInfo from './UserInfo'
import { useEffect, useState, useRef } from 'react'
import axios from 'axios'
import { decryptUserData } from '../../utils/encryptedData'
import { useLocation } from "react-router-dom";
import FollowersAndFollowingCard from './FollowersAndFollowingCard'
import { useSelector } from 'react-redux'
import FollowerImgModal from '../UI/FollowerImgModal'
import { Swiper, SwiperSlide } from 'swiper/react';


// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';

// import required modules
import { Pagination } from 'swiper/modules';


let FollowersORFollowingListIncrement = 0;
const FollowerAndFollowingContainer = ({ MarginTopNone }) => {



    let [FirstList, setFirstList] = useState([])
    let [Spinner, setSpinner] = useState(false)
    let [Totaluser, setTotaluser] = useState(null)
    let [TotalFollowers, setTotalFollowers] = useState('')
    let [TotalFollowing, setTotalFollowing] = useState('')
    let [UserValue, setUserValue] = useState([])
    let [LoadMoreFollowersORFollowingList, setLoadMoreFollowersORFollowingList] = useState([])
    let [CheckEmptyArray, setCheckEmptyArray] = useState(false)
    let [TotalRecommented, setTotalRecommented] = useState(null)
    let followState = useSelector(state => state.Follow.FollowState)
    let FollowerimgModalState = useSelector(state => state.Follow.FollowerimgModalOpen)
    let [FollowState, setFollowState] = useState(followState ? followState : "follower")



    let followpagefooter = useRef();




    // <-------------------->
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    // Get individual query parameters
    // let PhotoId = searchParams.get('photoid');
    let Userdata = searchParams.get('userdata');

    let userInfo;
    if (Userdata) {
        userInfo = decryptUserData(decodeURIComponent(Userdata))
    }





    // <---------------- decrypted data ------------->
    const encryptedUserData = localStorage.getItem('Data');
    const decryptedUserData = decryptUserData(encryptedUserData);
    let usertoken = decryptedUserData.token
    let Userid = decryptedUserData.userid
    let loginUserPhoto = decryptedUserData.Display_UserSocailPhoto
    let loginUserName = decryptedUserData.useDisplay_name


    // <----------------------------->



    // <---------------------------->


    let TabHandler = (val) => {
        setFollowState(val)

    }



    let FollowersListORFollowingList = async (e) => {
        if (e[0].isIntersecting) {
            FollowersORFollowingListIncrement = FollowersORFollowingListIncrement + 12


            try {
                let payload
                if (Userdata) {
                    payload = {
                        "key": FollowState,
                        "user_id": userInfo.UserID,
                        "offset": FollowersORFollowingListIncrement
                    }
                } else {
                    payload = {
                        "key": FollowState,
                        "offset": FollowersORFollowingListIncrement
                    }
                }
                let headers = {
                    'Authorization': `Bearer ${usertoken}`,
                    'Content-Type': 'application/json'
                }

                const url = `${process.env.REACT_APP_BASE_URL}/follow/list`

                const response = await axios.post(url, payload, { headers })


                const newList = response.data.data[0].data;
                setLoadMoreFollowersORFollowingList(PrevList => [...PrevList, ...newList])


            } catch (error) {


            }
            // .............
        }
    }

    useEffect(() => {

        const Observer = new IntersectionObserver(FollowersListORFollowingList, {
            root: null,
            threshold: 0
        });

        if (followpagefooter.current) {

            Observer.observe(followpagefooter.current)
        }
        return () => {
            if (followpagefooter.current) Observer.unobserve(followpagefooter.current);
        };
    }, [FirstList])



    useEffect(() => {

        let FollowersListORFollowingList = async function () {


            setFirstList([])
            setLoadMoreFollowersORFollowingList([])
            FollowersORFollowingListIncrement = 0;
            setCheckEmptyArray(false)
            setSpinner(true)
            try {
                let payload
                if (Userdata) {
                    payload = {
                        "key": FollowState,
                        "user_id": userInfo.UserID,
                        "offset": 0
                    }
                } else {
                    payload = {
                        "key": FollowState,
                        "offset": 0
                    }
                }

                let headers = {
                    'Authorization': `Bearer ${usertoken}`,
                    'Content-Type': 'application/json'
                }

                const url = `${process.env.REACT_APP_BASE_URL}/follow/list`

                const response = await axios.post(url, payload, { headers })



                setSpinner(false)

                setFirstList(response.data.data[0].data)
                setTotalFollowers(response.data.data[0].follower_count)
                setTotalFollowing(response.data.data[0].following_count)
                setTotalRecommented(response.data.data[0].recommended_count)
                setTotaluser(response.data.data[0][`${FollowState}_count`] * 1)
                if (response.data.data[0][`${FollowState}_count`] * 1 < 12) {

                    setCheckEmptyArray(true)
                }
                if (response.data.data[0].user) {
                    setUserValue(response.data.data[0].user[0])
                }

            } catch (error) {

            }
            // .............

        }
        FollowersListORFollowingList();
    }, [FollowState])







    let AllCards;
    if (FollowersORFollowingListIncrement === 0) {
        AllCards = [...FirstList]
    } else {
        AllCards = [...FirstList, ...LoadMoreFollowersORFollowingList]
    }





    useEffect(() => {


        if (Totaluser === AllCards.length) {

            setCheckEmptyArray(true)
        }

    }, [AllCards.length])

    let GetFollowCardInfoHandler = (val, Action, is_FollowingCard) => {
        if (!userInfo && Action == "add") {
            if (FollowState == "following" && !userInfo && Action == "add" && !is_FollowingCard) {
                let CheckUserAllreadyExist = [...AllCards].filter(item => item.id === val.id);
                let NewUser = [val]
                const updatedList = [...NewUser, ...AllCards];
                if (CheckUserAllreadyExist.length !== 1) {
                    setFirstList(updatedList);
                }

            }

            setTotalFollowing(TotalFollowing + 1)

        } else if (!userInfo) {
            // if (!is_FollowingCard) {
            //     const filteredList = FirstList.filter(item => item.id !== val.id);
            //     const updatedList = [...filteredList];
            //     setFirstList(updatedList);
            // }
            setTotalFollowing(TotalFollowing - 1)
        }


    }



    return (
        <>
            <main main className="site-content" id="main"  >
                <div className="tb-wrapper" >

                    {<UserInfo
                        myProfile={Userdata ? false : true}
                        UserName={UserValue.name}
                        UserPhoto={UserValue.photo_url}
                        createdAt={UserValue.createdAt}
                        genresList={UserValue.genres_list}
                        cameraBrand={UserValue.camera_brand}
                        UserLocation={UserValue.state}
                        UserId={UserValue.id}
                        isfollow={UserValue.is_follow}
                        usertoken={usertoken}
                        MarginTopNone={MarginTopNone}
                        LoginUserId={Userid}
                        loginUserPhoto={loginUserPhoto}
                        loginUserName={loginUserName}
                    />}
                    {/* profile end */}
                    {/* <div className="section-divider" /> */}
                    {/* big photo hunters start */}

                    {/* big photo hunters end */}
                    {/* <div className="section-divider" /> */}
                    {/* followers & followings start */}
                    <section className="pt-40 pb-120" style={{ paddingTop: '40px', paddingBottom: "120px" }}>
                        <div className="container">
                            <ul className="nav-tabs nav tab-style-one">
                                <li>
                                    <a onClick={() => { TabHandler("follower") }} className={`${FollowState == "follower" ? "active" : ''}`}>Followers {TotalFollowers > 0 && <span>({TotalFollowers})</span>}
                                    </a>
                                </li>

                                <li>
                                    <a onClick={() => { TabHandler("following") }} className={`${FollowState == "following" ? "active" : ''}`}>Following {TotalFollowing > 0 && <span>({TotalFollowing})</span>}
                                    </a>
                                </li>

                                {!Userdata && <li>
                                    <a onClick={() => {
                                        TabHandler("recommended");
                                    }} className={`${FollowState == "recommended" ? "active" : ''}`}>Recommended
                                        {/* {TotalRecommented > 0 &&  */}
                                        {/* // <span>({TotalRecommented})
                                    // </span>} */}
                                    </a>
                                </li>}

                            </ul>
                            <div className="followers_tab_content tab-content" id="followersTabContent">

                                {FollowState == "follower" && <div id="followers" role="tabpanel">
                                    <h4 className="tab_content-title">{`${userInfo ? UserValue.name + " Followers" : "My Followers"}`} {TotalFollowers > 0 && <span>({TotalFollowers})</span>}</h4>
                                    <div className="followers_users">
                                        {AllCards?.map((data) => (<FollowersAndFollowingCard
                                            GetFollowCardInfoFn={GetFollowCardInfoHandler}
                                            key={data.id}
                                            cameraBrand={data.camera_brand}
                                            createdAt={data.createdAt}
                                            FollowerId={data.id}
                                            genresList={data.genres_list}
                                            FollowerName={data.name}
                                            FollowerPhoto={data.photo_url}
                                            FollowerLocation={data.state}
                                            usertoken={usertoken}
                                            isfollow={data.is_follow}
                                            LoginUserId={Userid}
                                            photosArr={data.photos}
                                            isFollowBack={data.is_follow_back}
                                        />))}



                                    </div>
                                    {Spinner && <div style={{ display: "flex", justifyContent: "center" }}>
                                        <div className="spinner-border text-secondary" role="status" >
                                            <span className="sr-only"></span>
                                        </div>
                                    </div>}

                                </div>}


                                {FollowState == "following" &&

                                    <div id="following" role="tabpanel">
                                        <h4 className="tab_content-title">{`${userInfo ? UserValue.name + " Is Following" : "I'm Following"}`} {TotalFollowing > 0 && <span>({TotalFollowing})</span>}</h4>

                                        <div className="followers_users">
                                            {AllCards?.map((data) => (<FollowersAndFollowingCard
                                                GetFollowCardInfoFn={GetFollowCardInfoHandler}
                                                FollowingCard={true}
                                                key={data.id}
                                                cameraBrand={data.camera_brand}
                                                createdAt={data.createdAt}
                                                FollowerId={data.id}
                                                genresList={data.genres_list}
                                                FollowerName={data.name}
                                                FollowerPhoto={data.photo_url}
                                                FollowerLocation={data.state}
                                                usertoken={usertoken}
                                                isfollow={data.is_follow}
                                                LoginUserId={Userid}
                                                photosArr={data.photos}
                                                isFollowBack={data.is_follow_back}

                                            />))}


                                        </div>
                                        {Spinner && <div style={{ display: "flex", justifyContent: "center" }}>
                                            <div className="spinner-border text-secondary" role="status" >
                                                <span className="sr-only"></span>
                                            </div>
                                        </div>}
                                    </div>

                                }


                                {FollowState == "recommended" &&


                                    <div id="recommended" role="tabpanel">
                                        <h4 className="tab_content-title">People We Recommend</h4>

                                        <div className="followers_users">
                                            {AllCards?.map((data) => (<FollowersAndFollowingCard
                                                GetFollowCardInfoFn={GetFollowCardInfoHandler}
                                                key={data.id}
                                                cameraBrand={data.camera_brand}
                                                createdAt={data.createdAt}
                                                FollowerId={data.id}
                                                genresList={data.genres_list}
                                                FollowerName={data.name}
                                                FollowerPhoto={data.photo_url}
                                                FollowerLocation={data.state}
                                                usertoken={usertoken}
                                                isfollow={data.is_follow}
                                                LoginUserId={Userid}
                                                photosArr={data.photos}
                                                isFollowBack={data.is_follow_back}
                                            />))}



                                        </div>

                                        {Spinner && <div style={{ display: "flex", justifyContent: "center" }}>
                                            <div className="spinner-border text-secondary" role="status" >
                                                <span className="sr-only"></span>
                                            </div>
                                        </div>}
                                    </div>

                                }








                                {!CheckEmptyArray && !Spinner && <div className="text-center pt-40 ">

                                    <div style={{ display: "flex", justifyContent: "center", marginTop: '30px' }}>
                                        <div className="spinner-border text-secondary" role="status" >
                                            <span className="sr-only"></span>
                                        </div>
                                    </div>






                                </div>}
                                {!CheckEmptyArray && FirstList.length > 0 && <div ref={followpagefooter}></div>}

                            </div>
                        </div>
                    </section>

                    {/* followers & followings end */}




                    {FollowerimgModalState && <FollowerImgModal />}

                </div >
            </main >




        </>
    )
}

export default FollowerAndFollowingContainer