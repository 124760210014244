import React, { useState, useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux'
import { ModalAction } from '../../Store/Modal-Slice'
import { loadGalleryPhotos } from '../../utils/API/GalleryApi';
import { Link } from 'react-router-dom';
import useCloseModalHandler from '../../Hooks/useCloseModal';

let increment = 0;
const UploadPhotoFromGallery = ({ usertoken }) => {

    const [ImagesLoader, setImagesLoader] = useState(false)
    const [GalleryfirstPhoto, setGalleryfirstPhoto] = useState([])
    const [MoreGalleryPhoto, setMoreGalleryPhoto] = useState([])
    const [IsFooter, setIsFooter] = useState(true)
    const [selectedPhotoId, setSelectedPhotoId] = useState(null);
    const [EmptyGallery, setEmptyGallery] = useState(false)
    let dispatch = useDispatch()

    let GalleryModalBackBtnHandler = () => {
        dispatch(ModalAction.setUploadPhotoFromGalleryModalState(false))
        dispatch(ModalAction.setPhotoUploadModalState(true))
    }

    let imgSelectHandler = (id, compressedImageId) => {

        dispatch(ModalAction.setGalleryModalPhotoObject({ photoID: id, compressedImageId }))
        setSelectedPhotoId(id)
        GalleryModalBackBtnHandler()
    }

    let Galleryfooterr = useRef();



    let apiCall = (e) => {
        if (e[0].isIntersecting) {
            increment = increment + 10;

            let LoadPhotos = async function () {

                setImagesLoader(true)
                let OtherUserID = false
                try {

                    const response = await loadGalleryPhotos(usertoken, OtherUserID, increment, { sortingType: 'gallery_photo', value: "new" });

                    const newPhotos = response.data.data;

                    if (response.data.data.length < 10) {
                        setIsFooter(false)
                    }
                    setMoreGalleryPhoto(prevPhotos => [...prevPhotos, ...newPhotos])


                    setImagesLoader(false)


                } catch (error) {
                    console.log(error)
                }
            }
            LoadPhotos();
        }
    }

    useEffect(() => {

        const Observer = new IntersectionObserver(apiCall, {
            root: null,
            threshold: 0

        })

        if (Galleryfooterr.current) Observer.observe(Galleryfooterr.current)
        return () => {
            if (Galleryfooterr.current) Observer.unobserve(Galleryfooterr.current);
        };
    }, [GalleryfirstPhoto])

    useEffect(() => {

        setImagesLoader(true)


        increment = 0
        let OtherUserID = false
        setEmptyGallery(false)
        let LoadGalleryPhotos = async function () {
            try {

                const response = await loadGalleryPhotos(usertoken, OtherUserID, increment, { sortingType: 'gallery_photo', value: "new" });
                setGalleryfirstPhoto(response.data.data)
                setImagesLoader(false)
                if (response.data.data.length < 10) {
                    setIsFooter(false)
                }
                if (response.data.data.length === 0) {
                    setEmptyGallery(true)
                }

            } catch (error) {
                console.log(error)
            }

        }
        LoadGalleryPhotos();
    }, [])


    let AllPhotos;
    if (increment === 0) {
        AllPhotos = [...GalleryfirstPhoto]
    } else {
        AllPhotos = [...GalleryfirstPhoto, ...MoreGalleryPhoto]
    }


    const closeModal = () => {
        dispatch(ModalAction.setUploadPhotoFromGalleryModalState(false))
    };
    const modalRef = useCloseModalHandler(closeModal);

    return (
        <>
            <div className="backdrop" />

            {/* Upload From Gallery */}
            <div className="modal fade bph-modal show" id="QueuedPhotoModal" tabIndex={-1} aria-labelledby="sharePhotoModal" aria-hidden="true" style={{ display: "block" }}>
                <div className="modal-dialog modal-dialog-centered modalAnimation">
                    <div className="modal-content" ref={modalRef}>
                        <div className="modal-body">
                            <div className="form_gallery_modal">
                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={closeModal} />
                                <div className="header text-center">
                                    <h2 className="title">Select A Photo To Hunt</h2>
                                    <h5 className="subtitle">Pick from your personal gallery.</h5>
                                    <button className="return_btn" data-bs-toggle="modal" data-bs-target="#galleryAddImageModal" onClick={GalleryModalBackBtnHandler}>Back</button>
                                </div>
                                <div className="gallery_modal_images" >
                                    {AllPhotos?.map((data, index) => (
                                        <div className={`image_item ${selectedPhotoId === data.id ? 'selected' : ''}`} key={index} style={{ cursor: "pointer" }}
                                            onClick={() => { imgSelectHandler(data.id, data.compressedImageId) }}>
                                            <img src={`https://res.cloudinary.com/duk09hrua/image/upload/c_fit,w_300,f_auto,q_auto/Resized_Photos/${data.compressedImageId}`} alt="img" />
                                        </div>))}

                                    {EmptyGallery &&
                                        <div style={{ display: "flex", flexDirection: "column", alignItems: 'center' }}>
                                            <h4 style={{ textAlign: "center", marginBottom: "38px" }}>Oops. It looks like you don’t have any photos in your profile’s Personal Gallery.</h4>
                                            <Link onClick={() => { dispatch(ModalAction.setUploadPhotoFromGalleryModalState(false)) }} to={"/profile?tab=gallery"} style={{ color: 'blue', fontSize: "16px", fontWeight: "500" }}>Add photos here.</Link>
                                        </div>
                                    }
                                </div>



                                {ImagesLoader && <div style={{ display: "flex", justifyContent: "center", marginTop: "20px" }}>
                                    <div className="spinner-border text-secondary" role="status">
                                        <span className="sr-only"></span>
                                    </div>
                                </div>}

                                {IsFooter && AllPhotos.length > 0 && <div ref={Galleryfooterr}></div>}


                            </div>
                        </div>
                    </div>
                </div>
            </div>



            {/* !Upload From Gallery */}

        </>
    )
}

export default UploadPhotoFromGallery
