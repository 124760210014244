import { createSlice } from "@reduxjs/toolkit";

let initialModalState = {
    JoinModalBackbtn: false,
    StateAfterUploadPhoto: false,
    ShareModalPhotoInfo: "",
    PopupModalMessage: "",
    PopupModalState: false,
    PhotoUploadModalState: false,
    CoverPhotoUploadModalState: false,
    PhotoUploadType: "",
    ProfileUploadModalState: false,
    SharePhotoModalState: false,
    CropImg: "",
    CoverCropImg: "",
    MeApiState: false,
    JoinNowModalState: false,
    SignInModalState: false,
    FacebookEmailState: false,
    QueuedModalState: false,
    setisQueued: "",
    setQueuedTargetBtnAndValueObj: '',
    UploadPhotoFromGalleryModalState: false,
    GalleryModalPhotoObject: '',
    QueuePhotoObjectSelectedFromQueuePage: '',
    QueueEditValue: '',
    isLightboxModalOpen: false,
    isPayWallOpen: false,
};

let ModalSlice = createSlice({
    name: 'Modal',
    initialState: initialModalState,
    reducers: {
        setJoinModalBackbtn(state, action) {
            state.JoinModalBackbtn = action.payload
        },
        setStateAfterUploadPhoto(state, action) {
            state.StateAfterUploadPhoto = action.payload
        },
        setShareModalPhotoInfo(state, action) {
            state.ShareModalPhotoInfo = action.payload
        },
        setPopupModalState(state, action) {
            state.PopupModalState = action.payload
        },
        setPhotoUploadModalState(state, action) {
            state.PhotoUploadModalState = action.payload
        },
        setCoverPhotoUploadModalState(state, action) {
            state.CoverPhotoUploadModalState = action.payload
        },
        setPhotoUploadType(state, action) {
            state.PhotoUploadType = action.payload
        },
        setProfileUploadModalState(state, action) {
            state.ProfileUploadModalState = action.payload
        },
        setSharePhotoModalState(state, action) {
            state.SharePhotoModalState = action.payload
        },
        setPopupModalMessage(state, action) {
            state.PopupModalMessage = action.payload
        },
        setCropImg(state, action) {
            state.CropImg = action.payload
        },
        setCoverCropImg(state, action) {
            state.CoverCropImg = action.payload
        },
        setMeApiState(state, action) {
            state.MeApiState = action.payload
        },
        setJoinNowModalState(state, action) {
            state.JoinNowModalState = action.payload
        },
        setSignInModalState(state, action) {
            state.SignInModalState = action.payload
        },
        setFacebookEmailState(state, action) {
            state.FacebookEmailState = action.payload
        },
        setQueuedModalState(state, action) {
            state.QueuedModalState = action.payload
        },
        setisQueued(state, action) {
            state.isQueued = action.payload
        },
        setQueuedTargetBtnAndValueObj(state, action) {
            state.setQueuedTargetBtnAndValueObj = action.payload
        },
        setUploadPhotoFromGalleryModalState(state, action) {
            state.UploadPhotoFromGalleryModalState = action.payload
        },
        setGalleryModalPhotoObject(state, action) {
            state.GalleryModalPhotoObject = action.payload
        },
        setQueuePhotoObjectSelectedFromQueuePage(state, action) {
            state.QueuePhotoObjectSelectedFromQueuePage = action.payload
        },
        setQueueEditValue(state, action) {
            state.QueueEditValue = action.payload
        },
        setisLightboxModalOpen(state, action) {
            state.isLightboxModalOpen = action.payload
        },
        setisPayWallOpen(state, action) {
            state.isPayWallOpen = action.payload
        },
    }
})

export let ModalAction = ModalSlice.actions;

export default ModalSlice