import React from 'react'

const GalleryNOPhoto = ({handleUploadClick}) => {
    return (
        <>
            <div className="gallery_no_photo">
                <img src="./assets/img/gallery/no-photo-icon.png" alt />
                <h4 className="title">Use this space to share <br className="d-md-none" /> your work with club members.</h4>
                <div className="desc">
                    <p>Show people your style, your skills and your photography interests. Give more depth to yourself as photographer.</p>
                </div>
                <a  className="btn" 
                onClick={handleUploadClick}
                >Upload First Photo</a>
            </div>
        </>
    )
}

export default GalleryNOPhoto
