import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { editQueuedPhoto } from '../../utils/API/QueuedApi';
import { GenresList } from '../../utils/enums/listing';
import { ModalAction } from '../../Store/Modal-Slice';
import { photoUploadQ3List } from "../../utils/enums/listing";
import { DayList } from "../../utils/enums/listing";


const QueuedPhotoEditModal = ({ setEditModal }) => {
    const PhotoData = useSelector(state => state.Modal.setQueuedTargetBtnAndValueObj);

    const [textareaValue, setTextareaValue] = useState(PhotoData.photoObject?.description || '');
    const [spinner, setSpinner] = useState(false);
    const [EnteredQ2, setEnteredQ2] = useState(PhotoData.photoObject?.genres_list ? [PhotoData.photoObject?.genres_list] : []);
    const [EnteredQ3, setEnteredQ3] = useState(PhotoData.photoObject?.feedback_type ? PhotoData.photoObject?.feedback_type.split(',') : []);
    const [Q4Value, setQ4Value] = useState(PhotoData.photoObject?.day ? [PhotoData.photoObject?.day] : []);
    const [InitialFeedback, setInitialFeedback] = useState("");
    const [ActiveScreen, setActiveScreen] = useState("description");

    const dispatch = useDispatch();



    // Update state when PhotoData changes
    // useEffect(() => {
    //     setTextareaValue(PhotoData?.value || '');
    //     setEnteredQ2(PhotoData?.value || []);
    // }, [PhotoData]);


    useEffect(() => {

        // Split the string into an array of values
        let valuesArray = PhotoData.photoObject.feedback_type.split(',');


        // Initialize an empty object to store the result
        let resultObject = {};

        // Iterate over the array and construct the object
        valuesArray.forEach(value => {
            resultObject[value] = value;
        });

        setInitialFeedback(resultObject);


    }, [])


    const maxLength = 240;

    const textareaChangeHandler = (e) => {
        const value = e.target.value;
        setTextareaValue(value.slice(0, maxLength));
    };

    const remainingChars = maxLength - textareaValue.length;

    const Q2Changehandler = (e) => {
        const value = e.target.value.toString();

        const checked = e.target.checked;

        if (checked) {
            if (EnteredQ2.length < 1) {
                setEnteredQ2([...EnteredQ2, value]);
            } else {
                e.target.checked = false;
            }
        } else {
            setEnteredQ2(EnteredQ2.filter((item) => item !== value));
        }
    };

    // <-------------------------------------------->
    let Q3Changehandler = (e) => {
        let value = e.target.value.toString();
        let checked = e.target.checked;

        if (EnteredQ3[0] == 'I am not looking for feedback') {
            e.target.checked = false;
            if (e.target.value == 'I am not looking for feedback') {
                setEnteredQ3([])
            }
            return
        }
        if (checked) {
            // Check if the user can select more checkboxes
            if (value == 'I am not looking for feedback' && checked) {
                let checBOx = document.querySelectorAll(".checkkk")
                for (let i = 0; i < checBOx.length; i++) {
                    if (value == 'I am not looking for feedback') {
                        checBOx[i].checked = false
                        e.target.checked = true;
                        setEnteredQ3([value])
                    }
                }


            } else {
                if (EnteredQ3.length <= 2) {
                    setEnteredQ3([...EnteredQ3, value]);
                } else {
                    // Prevent further additions if the limit is reached
                    e.target.checked = false;
                }
            }

        } else {
            setEnteredQ3(EnteredQ3.filter((item) => item !== value));
        }
    };






    // <-------------------------->
    const [allowedDays, setAllowedDays] = useState([]);

    useEffect(() => {
        // Calculate allowed days based on daysLeft
        const calculateAllowedDays = () => {
            const allDays = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];
            const selectedIndex = allDays.indexOf(PhotoData.photoObject.day);
            const allowedDays = [];

            if (selectedIndex !== -1) {
                const startDayIndex = Math.max(0, selectedIndex - Number(PhotoData.daysLeft) + 1);
                for (let i = startDayIndex; i <= selectedIndex; i++) {
                    allowedDays.push(allDays[i]);
                }
                for (let i = selectedIndex; i < allDays.length; i++) {
                    allowedDays.push(allDays[i]);
                }
            }
            return allowedDays;
        };

        setAllowedDays(calculateAllowedDays());
    }, []);

    const Q4Changehandler = (e) => {
        let value = e.target.value.toString();
        let checked = e.target.checked;

        if (checked && allowedDays.includes(value)) {
            setQ4Value([value]); // Ensure only one value is selected
        }
    };


    // <-------------------------->

    const EditHandler = async () => {
        setSpinner(true);
        const trimTextValue = textareaValue.trim();

        try {
            let updatedValue = {
                photo_id: PhotoData.photoObject.id,
                description: trimTextValue,
                genres_list: EnteredQ2?.join(','),
                feedback_type: EnteredQ3?.join(','),
                day: Q4Value?.join(','),
            };
            // if (PhotoData.targetbtn === "description") {
            //     value = trimTextValue;
            // }
            // if (PhotoData.targetbtn === "genres_list") {
            //     value = EnteredQ2;
            // }
            // if (PhotoData.targetbtn === "feedback_type") {
            //     value = EnteredQ3;
            // }
            // if (PhotoData.targetbtn === "day") {
            //     value = Q4Value;
            // }
            // console.log(value)
            await editQueuedPhoto(PhotoData.usertoken, updatedValue);

            dispatch(ModalAction.setQueueEditValue(updatedValue));

            // dispatch(ModalAction.setQueueEditValue({ photoId: PhotoData.photoID, editType: PhotoData.targetbtn, editValue: typeof value === "string" ? value : value.join(",") }));
            // dispatch(ModalAction.setQueuedTargetBtnAndValueObj({ ...PhotoData, update: true }));
            setEditModal(false)
            setSpinner(false);
        } catch (error) {
            console.error(error);
            setSpinner(false);
        }
    };



    return (
        <>
            <div className="backdrop" />


            {/* Tell us about your photo */}
            <div className="modal fade bph-modal show" id="QueuedPhotoModal" tabIndex={-1} aria-labelledby="sharePhotoModal" aria-hidden="true" style={{ display: "block" }}>
                <div className="modal-dialog modal-dialog-centered modalAnimation">
                    <div className="modal-content">
                        <div className="modal-body">
                            <div className={`onboarding_modal photoUpload_modal ${ActiveScreen !== "description" ? 'feedback' : ''} edit_field`}>
                                <div className="modal_inner">
                                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={() => { setEditModal(false) }} />

                                    <div className="modal_logo d-none d-md-inline-flex">
                                        <img src="./assets/img/logo/modal-logo.png" alt="img" />
                                    </div>

                                    {ActiveScreen == "description" && <div className="modal_content">
                                        <div className="modal_title">
                                            <h2 className="title"><img src="./assets/img/icons/popup-pencil.svg" alt="img" />Edit</h2>
                                            <p className="subtitle">Edit your photo description below.</p>
                                        </div>
                                        <div className="edit_field_content">
                                            <img src={`https://res.cloudinary.com/duk09hrua/image/upload/c_fit,w_300,f_auto,q_auto/Resized_Photos/${PhotoData.photoObject.compressedImageId}`} alt="img" />
                                            <div className="desc">Edit 1/4</div>
                                            <div className="desc">Tell us about your photo.</div>
                                            <div className="form_group">
                                                <textarea name="description" id="description" placeholder="Tell club members what we are looking at here, or something else about this photo" value={textareaValue} onChange={textareaChangeHandler} />
                                                <span className="limit">({remainingChars} characters) </span>
                                            </div>

                                            <button className="btn" type="submit" onClick={() => { setActiveScreen("genres_list") }}>Next</button>

                                        </div>
                                    </div>}



                                    {ActiveScreen == "genres_list" && <div className="modal_content">
                                        <div className="modal_title">
                                            <h2 className="title"><img src="./assets/img/icons/popup-pencil.svg" alt="img" />Edit</h2>
                                            <p className="subtitle">Edit your photo's genre below.</p>
                                        </div>
                                        <div className="edit_field_content">
                                            <img src={`https://res.cloudinary.com/duk09hrua/image/upload/c_fit,w_300,f_auto,q_auto/Resized_Photos/${PhotoData.photoObject.compressedImageId}`} alt="img" />
                                            <div className="desc">Edit 2/4</div>
                                            <div className="desc">What genre is this photo?</div>
                                            <div className="row g-md-3">

                                                {GenresList.map((item, index) => (
                                                    <div className="col-xl-4 col-6" key={index}>

                                                        <div className="form_group checkbox">
                                                            <input type="checkbox" name={item.id} id={item.id}
                                                                defaultChecked={PhotoData.photoObject.genres_list == item.value}
                                                                value={item.value}
                                                                onChange={Q2Changehandler} />
                                                            <label htmlFor={item.id}>{item.value}</label>
                                                        </div>

                                                    </div>
                                                ))
                                                }
                                            </div>

                                            <button className="btn" type="submit" onClick={() => { setActiveScreen("feedback_type") }}>Next</button>

                                        </div>
                                    </div>}


                                    {ActiveScreen == "feedback_type" && <div className="modal_content">
                                        <div className="modal_title">
                                            <h2 className="title"><img src="./assets/img/icons/popup-pencil.svg" alt="img" />Edit</h2>
                                            <p className="subtitle">Edit your feedback request below.</p>
                                        </div>
                                        <div className="edit_field_content">
                                            <img src={`https://res.cloudinary.com/duk09hrua/image/upload/c_fit,w_300,f_auto,q_auto/Resized_Photos/${PhotoData.photoObject.compressedImageId}`} alt="img" />
                                            <div className="desc">Edit 3/4</div>
                                            <div className="desc">What type of feedback are you looking for (3 max.)?</div>
                                            <div className="row g-md-3">
                                                {InitialFeedback && photoUploadQ3List.map((item, index) => (
                                                    <div className="col-xl-4 col-6" key={index}>
                                                        <div className="form_group checkbox">
                                                            <input className='checkkk' type="checkbox" name={item.value} value={item.value} id={item.value}
                                                                defaultChecked={InitialFeedback[item.value]}
                                                                onChange={Q3Changehandler} />
                                                            <label htmlFor={item.value}>{item.value}</label>
                                                        </div>
                                                    </div>
                                                ))
                                                }

                                            </div>
                                            <button className="btn" type="submit" onClick={() => { setActiveScreen("day") }}>Next</button>
                                        </div>
                                    </div>}


                                    {ActiveScreen == "day" &&



                                        <div className="modal_content">
                                            <div className="modal_title">
                                                <h2 className="title"><img src="./assets/img/icons/popup-pencil.svg" alt />Edit</h2>
                                                <p className="subtitle">Edit the day of the week you wouldlike this photo to automatically post.</p>
                                            </div>
                                            <div className="edit_field_content">
                                                <img src={`https://res.cloudinary.com/duk09hrua/image/upload/c_fit,w_300,f_auto,q_auto/Resized_Photos/${PhotoData.photoObject.compressedImageId}`} alt="img" style={{ marginBottom: "20px" }} />

                                                <div className="desc">Edit 4/4</div>
                                                <div className="post_date">It will post at 12:00am EST on the day you pick.</div>
                                                <div className="row g-md-3">
                                                    {DayList.map((item, index) => (
                                                        <div className="col-6" key={index}>
                                                            <div className={`form_group checkbox ${!allowedDays.includes(item.value) ? "blurred" : ''}`}>
                                                                <input
                                                                    type="checkbox"
                                                                    name={item.value}
                                                                    id={item.id}
                                                                    value={item.value}
                                                                    checked={Q4Value.includes(item.value)} // Check if the value is in the state
                                                                    onChange={Q4Changehandler}
                                                                    disabled={!allowedDays.includes(item.value)} // Disable days that are not allowed
                                                                />
                                                                <label htmlFor={item.id}>{item.value}</label>
                                                            </div>
                                                        </div>
                                                    ))}
                                                </div>
                                                {!spinner ?
                                                    <button className="btn" type="submit" onClick={EditHandler}>Save</button>
                                                    :
                                                    <button className="btn"  >
                                                        <span className="loader"></span>
                                                    </button>}
                                            </div>
                                        </div>

                                    }




                                </div>
                            </div>





                        </div>
                    </div>
                </div>
            </div >
            {/* !Tell us about your photo */}


        </>
    )
}

export default QueuedPhotoEditModal
