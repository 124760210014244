import { createSlice } from "@reduxjs/toolkit";

let initialFollowState = { FollowState: "", FollowerImgmodalUserInfo: '', FollowerimgModalOpen: false, ClickedFollowCardInfo: '' };


let FollowSlice = createSlice({
    name: 'Follow',
    initialState: initialFollowState,
    reducers: {
        setFollowState(state, action) {
            state.FollowState = action.payload
        },
        setFollowerImgmodalUserInfo(state, action) {
            state.FollowerImgmodalUserInfo = action.payload
        },
        setFollowerimgModalOpen(state, action) {
            state.FollowerimgModalOpen = action.payload
        },

        setClickedFollowCardInfo(state, action) {
            state.ClickedFollowCardInfo = action.payload
        },

    }
})

export let FollowAction = FollowSlice.actions;

export default FollowSlice