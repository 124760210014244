import { io } from 'socket.io-client';



export const initializeSocket = () => {
    const socket = io(process.env.REACT_APP_SOCKET_URL);
    return socket;
};

export const emitNewUser = (socket, userId) => {
    socket?.emit("newUser", userId);
};


export const sendNotification = (socket, loginUserid, Userid) => {
    if (loginUserid !== Userid && socket) {
        socket.emit("sendNotification", {
            senderID: loginUserid,                
            receiverID: Userid,
        });
    }
};