import axios from 'axios';

export const testimonialsAPI = async () => {
    try {
      
        const headers = {
            
            'Content-Type': 'application/json'
        };
        const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/user/testimonials`, {}, { headers });

        return response;
    } catch (error) {

        throw error; // rethrow the error if you want to handle it in the component
    }
};