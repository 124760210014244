import { createSlice } from "@reduxjs/toolkit";

let initialGalleryState = { GalleryUploadState: false, GalleryUploadComponentState: false, SuccessfullyUploadedGalleryPhotos: [], SuccessfullyManageGalleryPhotos: [], ProfileInfoPlusBtnIsClick: false, ViewGallery: { mainGallery: true, manageGallery: false }, IsManageGallery: false };

let GallerySlice = createSlice({
    name: 'Gallery',
    initialState: initialGalleryState,
    reducers: {
        getGalleryUploadState(state, action) {
            state.GalleryUploadState = action.payload
        },
        setGalleryUploadComponentState(state, action) {
            state.GalleryUploadComponentState = action.payload
        },
        setSuccessfullyUploadedGalleryPhotos(state, action) {
            state.SuccessfullyUploadedGalleryPhotos = action.payload
        },
        setSuccessfullyManageGalleryPhotos(state, action) {
            state.SuccessfullyManageGalleryPhotos = action.payload
        },
        setProfileInfoPlusBtnIsClick(state, action) {
            state.ProfileInfoPlusBtnIsClick = action.payload
        },
        setViewGallery(state, action) {
            state.ViewGallery = action.payload
        },
        setIsManageGallery(state, action) {
            state.IsManageGallery = action.payload
        }
    }
})

export let GalleryAction = GallerySlice.actions;

export default GallerySlice