import { createSlice } from "@reduxjs/toolkit";

let initialLightBoxState = {
  LightBoxState: [],
  LightBoxActiveImgId: "",
  LoadMorePhotos: { fetchMorePhotos: false, fetchAction: false },
  UpdateTheMianFeedpage: ''
};

let LightBoxSlice = createSlice({
  name: "LightBox",
  initialState: initialLightBoxState,
  reducers: {
    setLightBoxState(state, action) {
      state.LightBoxState = action.payload;
    },
    setLightBoxActiveImgId(state, action) {
      state.LightBoxActiveImgId = action.payload;
    },
    setLoadMorePhotos(state, action) {
      state.LoadMorePhotos = action.payload;
    },
    setUpdateTheMianFeedpage(state, action) {
      state.UpdateTheMianFeedpage = action.payload;
    },
  },
});

export let LightBoxAction = LightBoxSlice.actions;

export default LightBoxSlice;
