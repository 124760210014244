import React from 'react'
import SubNavbar from '../Components/Layout/SubNavbar'



const ActivityPage = () => {

    return (
        <>
            <SubNavbar activityTabState={true} />

        </>
    )
}

export default ActivityPage