import React, { useState } from 'react'
import { calculateTimeDifference } from '../../utils/CalculateCommentTime';
import axios from 'axios';
import { formatLikes } from '../../utils/FomattedLike';
import { AES } from 'crypto-js';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { SocketAction } from '../../Store/SocketSlice';

const ReplyCommentList = (props) => {


    let [TotalVote, setTotalVote] = useState(props.totalVote)
    let [upDownvote, setupDownvote] = useState(props.isVote || false)
    let [votebtnactive, setvotebtnactive] = useState(props.isVote || false)


    /// Like 
    const formattedLikes = formatLikes(TotalVote ? TotalVote : 0);

    const timeDifference = calculateTimeDifference(props.CeatedAt);


    // <--------------------------->

    // < --------Socket ------->
    let dispatch = useDispatch()
    let ReloadNotificationFn = useSelector(state => state.Socket.ReloadNotificationSenderFn)

    let SendNotificationFn = () => {
        dispatch(SocketAction.setSocketReceiverUserID(props.userid))
        dispatch(SocketAction.setReloadNotificationSenderFn(!ReloadNotificationFn))
    }
    // <-------------------------------------------->


    let navigate = useNavigate();

    let OtheruserProfileNavigateHandler = (event) => {
        event.preventDefault();



        if (props.loginUserid === props.userid) {
            navigate("/profile");
        } else {
            let userData = {
                PhotoID: "",
                UserID: props.userid,
            }
            const secretKey = process.env.REACT_APP_SECRET_KEY;
            const encryptedUserData = AES.encrypt(JSON.stringify(userData), secretKey).toString();
            navigate(`/otherusers-profile?userdata=${encodeURIComponent(encryptedUserData)}`);

        }

        window.scrollTo(0, 0)
    }

    let replyProfileNavigateHandler = (event) => {
        event.preventDefault();
        if (props.loginUserid === props.replyToId) {
            navigate("/profile");
        } else {
            let userData = {
                PhotoID: "",
                UserID: props.replyToId,
            }
            const secretKey = process.env.REACT_APP_SECRET_KEY;
            const encryptedUserData = AES.encrypt(JSON.stringify(userData), secretKey).toString();
            navigate(`/otherusers-profile?userdata=${encodeURIComponent(encryptedUserData)}`);

        }

        window.scrollTo(0, 0)
    }



    let ReplyHandler = () => {


        props.ReplyCommentsInfoFun({
            username: props.username,
            photoID: props.photoID,
            parentCommentID: props.topcommentID,
            replyCommentID: props.id,
            userid: props.userid


        })
    }


    let voteHandler = async (event) => {

        event.preventDefault();
        setvotebtnactive(!votebtnactive)
        setupDownvote(!upDownvote)



        let up_or_down_vote;
        if (upDownvote) {
            up_or_down_vote = "remove"
        } else {
            up_or_down_vote = "add"
            SendNotificationFn()

        }
        // ......................
        try {
            const payload = {
                "commentID": props.id,
                "actionType": up_or_down_vote
            };
            let headers = {
                'Authorization': `Bearer ${props.usertoken}`,
                'Content-Type': 'application/json'
            }
            const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/vote/comment`, payload, { headers });

            setTotalVote(response.data.data.voteCount)





        } catch (error) {

        }

    }
    return (
        <>

            <li className="comment">
                <div className="comment_img"

                    onClick={OtheruserProfileNavigateHandler}

                    style={{
                        backgroundImage: `url(${props.userPhoto || ""})`,
                        backgroundPosition: "center",
                        backgroundSize: "cover",
                        backgroundRepeat: "no-repeat",
                        borderRadius: "50%",
                        cursor: "pointer",


                    }}
                >
                    {!props.userPhoto && <img src={`https://ui-avatars.com/api/?name=${props.username}&size=40&rounded=true&background=01655a&color=fff`} alt="img" />}
                </div>
                <div className="comment_text">
                    <h6 className="name" style={{ cursor: 'pointer' }} onClick={OtheruserProfileNavigateHandler}>{props.username}</h6>
                    <p className="text">
                        <span style={{ color: 'green', cursor: 'pointer' }} onClick={replyProfileNavigateHandler}>@{props.replyToName} </span>
                        {props.commentText}
                    </p>
                    <div className="comment_meta">
                        <span className={`likes ${votebtnactive ? "active" : ""}`} onClick={voteHandler}>{formattedLikes} Like
                            <img className="stock" src="./assets/img/icons/thumb.svg" alt="img" />
                            <img className="fill" src="./assets/img/icons/thumb-fill.svg" alt="img" />
                        </span>
                        <button onClick={ReplyHandler} className="reply" type="button">Reply <img src="./assets/img/icons/reply.svg" alt="img" /></button>
                        <span className="date">{timeDifference}</span>
                    </div>
                </div>
            </li>


        </>
    )
}

export default ReplyCommentList