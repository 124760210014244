import React from 'react'
import UserData from './UserData'
import { useState } from 'react'
import sendCongratulations from '../../utils/API/CongratApi'
import { SocketAction } from '../../Store/SocketSlice';
import { useSelector, useDispatch } from 'react-redux';

const WhoYouFollowInTopTenNotification = ({ NotificationData, usertoken, loginUserid, is_seen }) => {


    let [IsCongratulate, setIsCongratulate] = useState(NotificationData && NotificationData.isCongratulateTopTenUser)


    // < --------Socket ------->
    let dispatch = useDispatch()

    let ReloadNotificationFn = useSelector(state => state.Socket.ReloadNotificationSenderFn)

    let SendNotificationFn = () => {
        dispatch(SocketAction.setSocketReceiverUserID(NotificationData.sender_id))
        dispatch(SocketAction.setReloadNotificationSenderFn(!ReloadNotificationFn))
    }
    // <-------------------------------------------->


    let CongratulationssHandler = async (event) => {
        event.preventDefault();
        setIsCongratulate(!IsCongratulate);

        try {


            await sendCongratulations(usertoken, NotificationData.id, NotificationData.photo_id, IsCongratulate, loginUserid);
            SendNotificationFn()


        } catch (error) {

        }
    }
    return (
        <>
            <UserData NotificationData={NotificationData} message={"who you follow, is in this week's Top Ten"} is_seen={is_seen}>
                {!IsCongratulate ? <button onClick={CongratulationssHandler} className="btn btn--base btn--116 Congratulations-moblie-btn" data-normal="Congratulations!" data-active="Congratulated" >Congratulations!</button>
                    :
                    <button className="btn btn--base btn--116 congratulated-btn Congratulations-moblie-btn" >Congratulated</button>}

            </UserData>
        </>
    )
}

export default WhoYouFollowInTopTenNotification