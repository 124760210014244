import axios from 'axios';
import axiosInstance from '../Services/AxiosInstance';



export const mainPhotosFeedpagelist = async (userType, sortingType, offSet, usertoken, isloggedin) => {
  try {
    const payload = {
      "userType": userType,
      "sortingType": sortingType,
      "offSet": offSet
    };

    const headers = {
      'Authorization': `Bearer ${usertoken}`,
      'Content-Type': 'application/json'
    };

    const url = isloggedin
      ? `${process.env.REACT_APP_BASE_URL}/photo/list`
      : `${process.env.REACT_APP_BASE_URL}/photo/list_photo_for_landing`;

    const response = await axiosInstance.post(url, payload, { headers });

    return response;
  } catch (error) {

    throw error;
  }
};







export const fetchProfileAndUpvotePhotos = async (userType, FilterValue, offSet, usertoken, UpvotePhotoState) => {
  try {
    const payload = {
      userType: userType,
      sortingType: FilterValue || "by_recent_photos",
      offSet: offSet
    };
    const headers = {
      'Authorization': `Bearer ${usertoken}`,
      'Content-Type': 'application/json'
    };

    const url = UpvotePhotoState
      ? `${process.env.REACT_APP_BASE_URL}/photo/list_upvoted_photo`
      : `${process.env.REACT_APP_BASE_URL}/photo/list`;

    const response = await axiosInstance.post(url, payload, { headers });
    return response
  } catch (error) {
    throw error;
  }
};



export const ParticipateUsersPhotosList = async (offSet, usertoken,) => {
  try {
    const payload = {
      "offSet": offSet
    };

    const headers = {
      'Authorization': `Bearer ${usertoken}`,
      'Content-Type': 'application/json'
    };

    const url = `${process.env.REACT_APP_BASE_URL}/photo/list_participating_members`


    const response = await axiosInstance.post(url, payload, { headers });

    return response;
  } catch (error) {

    throw error;
  }
};