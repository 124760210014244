import React from 'react'
import { useDispatch } from "react-redux";
import { ModalAction } from "../../Store/Modal-Slice";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom"
import { AES } from 'crypto-js';


const PopupModal = () => {
    let dispatch = useDispatch()
    let navigate = useNavigate();

    let popupData = useSelector(state => state.Modal.PopupModalMessage)
    let popupHeading = popupData.popupHeading || "You’re Perfect for Us."
    let popupMessage = popupData.popupMessage || "You’re in! Don’t forget to check your email Tuesdays for each week's most upvoted photos."
    let popupMessage2 = popupData.popupMessage2 || ''


    let ContinueHandler = () => {
        dispatch(ModalAction.setPopupModalState(false))
        if (popupHeading == "Oops! Upgrade to upload.") {
            dispatch(ModalAction.setisPayWallOpen(true))
            return
        }

        if (popupData.photoID && !localStorage.getItem("source")) {
            let userData = {
                PhotoID: popupData.photoID,
                UserID: popupData.userID
            }
            const secretKey = process.env.REACT_APP_SECRET_KEY;
            const encryptedUserData = AES.encrypt(JSON.stringify(userData), secretKey).toString();
            navigate(`/otherusers-profile?userdata=${encodeURIComponent(encryptedUserData)}`);

        }
    }

    // let GoToProfileHandler = ()=>{

    //     dispatch(ModalAction.setPopupModalMessage(false))
    //     dispatch(ModalAction.setPopupModalState(false))
    //     window.scrollTo(0, 0)
    // }
    return (
        <>
            <div className="backdrop" />

            <div className="modal fade bph-modal show" id="generalLoginModal" tabIndex={-1} aria-labelledby="generalLoginModal" aria-hidden="true"
                style={{ display: 'block' }}
            >
                <div className="modal-dialog modal-dialog-centered modalAnimation">
                    <div className="modal-content">
                        <div className="modal-body">
                            <div className="onboarding_modal login_modal">
                                <div className="modal_inner">
                                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={ContinueHandler} />
                                    {/* logo */}
                                    <div className="modal_logo d-none d-md-inline-flex">
                                        <img src="./assets/img/logo/modal-logo.png" alt="img" />
                                    </div>
                                    {/* content */}
                                    <div className="modal_content">
                                        {/* {!photoUploadLimitHeading ?  */}
                                        <div className="modal_title">
                                            <h2 className="title" style={{ fontSize: '38px' }}>{popupHeading}</h2>
                                            <p className="subtitle">{popupMessage}</p>
                                            {popupMessage2 && <p className="subtitle">{popupMessage2}</p>}
                                            <button className="btn" type="submit" onClick={ContinueHandler}>{popupHeading !== "Oops! Upgrade to upload." ? "Continue" : "Learn More"}</button>
                                        </div>
                                        {/* : */}
                                        {/* <div className="modal_title">
                                                <h2 className="title">{photoUploadLimitHeading}</h2>
                                                <p className="subtitle">{ photoUploadLimitMessage1}</p>
                                                <p className="subtitle">{ photoUploadLimitMessage2}</p>
                                               
                                                <Link to={'/profile'}  className="btn"  onClick={GoToProfileHandler}>Go to My profile</Link> */}
                                        {/* </div> */}
                                        {/* } */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div >


        </>
    )
}

export default PopupModal