import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { useDispatch } from "react-redux";
import { ModalAction } from "../../Store/Modal-Slice";
import { useLocation } from "react-router-dom";
import { useEffect } from 'react';


const Header = () => {
  let [MobileMenuState, setMobileMenuState] = useState(false)
  let [OverlayState, setOverlayState] = useState(false)

  let [MenuDropDown, setMenuDropDown] = useState('')

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  // Get individual query parameters
  let LoginModalCheck = encodeURIComponent(searchParams.get('login'));


  useEffect(() => {
    if (LoginModalCheck && LoginModalCheck == "true") {
      dispatch(ModalAction.setSignInModalState(true))

    }
  }, [LoginModalCheck])

  let simpleloginAndsignupHandler = () => {
    dispatch(ModalAction.setPopupModalMessage({
      "joinModalHeading": "",
      "popupHeading": "",
      "popupMessage": "",
      photoID: "",
      userID: "",
    }))
  }


  let SignInModalHandler = () => {
    dispatch(ModalAction.setSignInModalState(true))
    simpleloginAndsignupHandler()
  }
  let JoinNowModalHandler = () => {
    dispatch(ModalAction.setJoinNowModalState(true))
    simpleloginAndsignupHandler()
  }

  let MenuDropDownHandler = (val) => {
    setMenuDropDown(val)
  }


  const scrollToTop = () => {
    setMobileMenuState(false)
    setOverlayState(false)

    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  };

  let dispatch = useDispatch()
  const currentUrl = window.location.pathname;

  let MenuHandler = () => {
    setMobileMenuState(true)
    setOverlayState(true)
  }

  let MenuCloseHandler = () => {
    setMobileMenuState(false)
    setOverlayState(false)
  }
  return (
    <>
      {OverlayState && <div className="overlay " onClick={MenuCloseHandler}></div>}

      <header className={`site-header ${currentUrl == '/' || currentUrl == '/C051324' ? "without_login" : "sticky"} header-absolute`} id="site-header">
        {/* 
css:
without_login
with_login
     */}
        <div className="container">
          <div className="row">
            <div className="col">
              <div className="site_menu d-flex align-items-center flex-wrap">
                <div className="menu_bar">
                  {currentUrl !== '/C051324' &&
                    <>
                      <Link className='forDes'>
                        <img className="white_bar" src="./assets/img/icons/menu-bar-white.svg" alt="Menu Bar" />
                        <img className="black_bar" src="./assets/img/icons/menu-bar.svg" alt="Menu Bar" />
                      </Link>
                      <Link className='forMob' onClick={MenuHandler}>
                        <img className="white_bar" src="./assets/img/icons/menu-bar-white.svg" alt="Menu Bar" />
                        <img className="black_bar" src="./assets/img/icons/menu-bar.svg" alt="Menu Bar" />
                      </Link>
                    </>}
                  {/* Hamburger Menu */}
                  <nav className="side_menu d-none d-md-block">
                    <ul>
                      <li>
                        <Link to={'/about'} onClick={scrollToTop}>About</Link>
                      </li>
                      <li>
                        <Link to={'/how-works'} onClick={scrollToTop}>How It Works</Link>
                      </li>
                      {/* <li>
                        <Link >This Month’s Prize</Link>
                      </li> */}
                      {/* <li className="has-dropdown">
                        <a onClick={monthlyGiveAwayHandler}>Monthly Giveaway 1 </a>
                        <ul className="submenu" style={{ display: MonthlyGiveawayDropDown ? 'block' : 'none' }}>
                          <li>
                            <Link to={"/new-giveaway"} onClick={scrollToTop}>This Month</Link>
                          </li>
                          <li >
                            <Link to={"/previousgiveaways"} onClick={scrollToTop} >Previous Months</Link>
                          </li>
                        </ul>
                      </li> */}
                      <li>
                        <Link to={"/topten"} onClick={scrollToTop}>Weekly Top 10</Link>
                      </li>
                      <li>
                        <Link to={'/feedback'} onClick={scrollToTop}>Good Feedback</Link>
                      </li>
                      {/* <li>
                        <Link to={'/meet-terry'} onClick={scrollToTop}>A Mouse?</Link>
                      </li> */}
                      <li>
                        <Link to={'/contact'} onClick={scrollToTop}>Contact Us</Link>
                      </li>
                      <li>
                        <Link to="https://bigphotohunt.buzzsprout.com" target='_blank'>Podcast</Link>
                      </li>

                      <li>
                        <Link to={'/beta'} onClick={scrollToTop}>Beta</Link>
                      </li>
                    </ul>
                  </nav>
                </div>
                <div className="site_logo" onClick={scrollToTop}>
                  <Link to="/">
                    <img className="white_logo" src="./assets/img/logo/logo-white.png" alt="Site Logo" />
                    <img className="black_logo" src="./assets/img/logo/logo-black.png" alt="Site Logo" />
                  </Link>
                </div>
                <nav className="main_menu d-none d-md-block">
                  {currentUrl !== '/C051324' &&
                    <ul>
                      <li>
                        <Link to={'/how-works'} onClick={scrollToTop}>How It Works</Link>
                      </li>
                      {/* <li>
                      <Link to={'/meet-terry'} onClick={scrollToTop}>A Mouse?</Link>
                    </li> */}
                      <li>
                        <Link to={"/topten"} onClick={scrollToTop}>Weekly Top 10</Link>
                      </li>
                      <li className="has-dropdown" style={{ position: "relative" }}>
                        <a >Giveaway </a>
                        <ul className="submenu" style={{ minWidth: "130px" }}>
                          <li>
                            <Link to={"/new-giveaway"} onClick={scrollToTop}>This Month</Link>
                          </li>
                          <li >
                            <Link to={"/previousgiveaways"} onClick={scrollToTop} >Previous Months</Link>
                          </li>
                        </ul>
                      </li>

                      <li className="has-dropdown" style={{ position: "relative" }}>
                        <a >Photo Reviews</a>
                        <ul className="submenu" style={{ minWidth: "145px" }}>
                          <li>
                            <Link to={"/monthly-townhall"} onClick={scrollToTop}>This Month</Link>
                          </li>
                          <li >
                            <Link to={"/previous-monthly-townhall"} onClick={scrollToTop} >Previous Months</Link>
                          </li>
                          {/* <li >
                            <Link to={"/pierretlambert-course"} onClick={scrollToTop} >Pierre Lambert Course</Link>
                          </li> */}
                        </ul>
                      </li>

                      <li>
                        {/* <Link to={"https://www.bigphotohunt.store/shop/p/steffanchavemasterofhistrade"} target='_blank' onClick={scrollToTop}>Photo of Month</Link> */}
                        <Link to={"/pierretlambert-course"} onClick={scrollToTop} >Pierre Lambert Course</Link>
                      </li>
                    </ul>}
                </nav>
                {/* without login */}
                <div className="menu_right without_login d-none d-md-flex">
                  <div className="button sign_in">
                    <a

                      className="btn btn-white signModal"
                      // data-bs-toggle="modal"
                      // data-bs-target="#signInModal"
                      onClick={SignInModalHandler}
                    >
                      Sign in
                    </a>
                  </div>
                  <div className="button join_now">
                    <a
                      className="btn"

                      onClick={JoinNowModalHandler}
                    >
                      Join now
                    </a>
                  </div>
                </div>
                <div className="menu_right without_login d-md-none">
                  <div className="button sign_in">
                    <a

                      className="btn btn-white signModal"

                      onClick={SignInModalHandler}
                    >
                      Sign in
                    </a>
                  </div>
                  <div className="button join_now">
                    <a
                      className="btn"

                      onClick={JoinNowModalHandler}
                    >
                      Join
                    </a>
                  </div>
                </div>
                {/* !without login */}
                {/* with logged in */}
                {/* <div className="menu_right with_logged_in">
        <div className="button">
          <Link href="#" className="btn d-none d-lg-inline-block" data-bs-toggle="modal" data-bs-target="#photoUploadModal">Hunt My Photo</Link>
          <Link href="#" className="btn plus d-lg-none"><img src="./assets/img/icons/plus.svg" alt="img"></Link>
        </div>
        <div className="notification">
          <Link href="#"><img src="./assets/img/icons/bell.svg" alt="Bell"></Link>
        </div>
        <div className="user">
          <Link href="#"><img src="./assets/img/users/user-img.png" alt="User Image"></Link>
        </div>
      </div> */}
                {/* !logged in */}
              </div>
            </div>
          </div>
        </div>
        {/* Hamburger mobile Menu */}
        <nav className={`side_menu mobile d-md-none ${MobileMenuState ? "open" : ""}`}>
          <div className="side_menu_top">
            <Link href="" className="close" onClick={MenuCloseHandler}>
              <img src="./assets/img/icons/close.svg" alt='img' />
            </Link>
          </div>
          <ul  >

            <li className="has-dropdown">
              <a onClick={() => { MenuDropDownHandler("about") }}>About</a>
              <ul className="submenu" style={{ display: MenuDropDown == "about" ? 'block' : 'none' }}>
                <li >
                  <Link to={'/about'} onClick={scrollToTop}>About Us</Link>
                </li>
                <li >
                  <Link to={'/how-works'} onClick={scrollToTop}>How It Works</Link>
                </li>
                <li>
                  <Link to={'/feedback'} onClick={scrollToTop}>Good Feedback</Link>
                </li>
                <li>
                  <Link to="https://bigphotohunt.buzzsprout.com" target='_blank'>Podcast</Link>
                </li>
                <li>
                  <Link to={'/beta'} onClick={scrollToTop}>Beta</Link>
                </li>
              </ul>
            </li>


            {/* <li>
              <Link  >This Month’s Prize </Link>
            </li> */}


            <li className="has-dropdown">
              <a onClick={() => { MenuDropDownHandler("giveaway") }}>Monthly Giveaway </a>
              <ul className="submenu" style={{ display: MenuDropDown == "giveaway" ? 'block' : 'none' }}>
                <li>
                  <Link to={"/new-giveaway"} onClick={scrollToTop}>This Month</Link>
                </li>
                <li >
                  <Link to={"/previousgiveaways"} onClick={scrollToTop} >Previous Months</Link>
                </li>
              </ul>
            </li>


            <li>
              <Link to={"/topten"} onClick={scrollToTop}>Weekly Top 10</Link>
            </li>

            <li>
              <Link to={'/new-members'} onClick={scrollToTop}>New Members</Link>
            </li>

            {/* <li>
              <Link to={'/meet-terry'} onClick={scrollToTop}>A Mouse?</Link>
            </li> */}
            <li>
              <Link to={'/contact'} onClick={scrollToTop}>Contact Us</Link>
            </li>

            <li className="has-dropdown">
              <a onClick={() => { MenuDropDownHandler("Event") }}>Photo Reviews</a>
              <ul className="submenu" style={{ display: MenuDropDown == "Event" ? 'block' : 'none' }}>
                <li>
                  <Link to={"/monthly-townhall"} onClick={scrollToTop}>This Month</Link>
                </li>
                <li >
                  <Link to={"/previous-monthly-townhall"} onClick={scrollToTop} >Previous Months</Link>
                </li>
                {/* <li >
                  <Link to={"/pierretlambert-course"} onClick={scrollToTop} >Pierre Lambert Course</Link>
                </li> */}
              </ul>
            </li>
            <li>
              {/* <Link to={"https://www.bigphotohunt.store/shop/p/steffanchavemasterofhistrade"} target='_blank' onClick={scrollToTop}>Photo of Month</Link> */}
              <Link to={"/pierretlambert-course"} onClick={scrollToTop} >Pierre Lambert Course</Link>
            </li>
          </ul>
        </nav>
      </header>

    </>
  )
}

export default Header