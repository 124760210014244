import React from 'react'
import { useState } from 'react'
import axios from 'axios'
import { followUser } from '../../utils/API/FollowApi'
import { SocketAction } from '../../Store/SocketSlice';
import { useSelector, useDispatch } from 'react-redux';
import amplitude from 'amplitude-js';


const FollowAndThanksButton = ({ thanksBtnHidden, usertoken, NotificationData, BtnTextFollowBack, ThanksFor, loginUserid, EmailBtn }) => {



    // < --------Socket ------->
    let dispatch = useDispatch()

    let ReloadNotificationFn = useSelector(state => state.Socket.ReloadNotificationSenderFn)

    let SendNotificationFn = () => {
        dispatch(SocketAction.setSocketReceiverUserID(NotificationData.sender_id))
        dispatch(SocketAction.setReloadNotificationSenderFn(!ReloadNotificationFn))
    }
    // <-------------------------------------------->

    let [Isfollow, setIsfollow] = useState(NotificationData && NotificationData.is_follow)
    let [IsThanks, setIsThanks] = useState(NotificationData && NotificationData.is_thanked)


    let FollowHandler = async (event) => {
        event.preventDefault();

        setIsfollow(!Isfollow)
        try {
            await followUser(NotificationData.id, !Isfollow, usertoken, loginUserid, SendNotificationFn, BtnTextFollowBack,);
        } catch (error) {
            console.log(error)
        }

    }

    let ThanksHandler = async (event) => {
        event.preventDefault();
        setIsThanks(!IsThanks);

        try {
            const payload = {
                "user_id": NotificationData && NotificationData.id,
                "photo_id": NotificationData && NotificationData.photo_id,
                "comment_id": NotificationData && NotificationData.comment_id,
                "notification_type": ThanksFor,
                "is_thanked": !IsThanks
            };

            const headers = {
                'Authorization': `Bearer ${usertoken}`,
                'Content-Type': 'application/json'
            };


            await axios.post(`${process.env.REACT_APP_BASE_URL}/notify/sendthanks`, payload, { headers });

            amplitude.getInstance().logEvent("Send Thanks", { UserID: loginUserid });
            SendNotificationFn()

        } catch (error) {

        }
    };
    return (
        <>

            <div className=" d-flex flex-wrap flex-xl-nowrap justify-content-sm-end gap-2 gap-xl-3 btn--group" style={{ margin: "auto 0px" }}>
                {!thanksBtnHidden &&
                    (!IsThanks ? <button className={`btn btn--light btn--116 ${thanksBtnHidden ? "both-btn" : ''}`} data-normal="Send Thanks!" data-active="Sent Thanks" onClick={ThanksHandler}>Send Thanks!</button>
                        :
                        <button className={`btn btn--light activee btn--116 ${thanksBtnHidden ? "both-btn" : ''}`} data-normal="Send Thanks!" data-active="Sent Thanks">Sent Thanks</button>)}

                {!Isfollow ?
                    <button className={`btn btn--base  follow--unfollow ${thanksBtnHidden ? "both-btn" : ''}`} onClick={FollowHandler}>{BtnTextFollowBack ? 'Follow Back' : "Follow"}</button>
                    :
                    <button className={`btn btn--base btn--unfollow follow--unfollow ${thanksBtnHidden ? "both-btn" : ''}`} onClick={FollowHandler}>Following</button>}
                {/* <button className="btn btn--base btn--116" data-normal="Follow" data-active="Following" >{thanksBtnHidden ? 'Follow Back' : "Follow"}</button> */}
            </div>
        </>
    )
}

export default FollowAndThanksButton