import axios from 'axios';

export const performVote = async (photoID, actionType, usertoken) => {
    try {
        const payload = {
            "photoID": photoID,
            "actionType": actionType
        };

        const headers = {
            'Authorization': `Bearer ${usertoken}`,
            'Content-Type': 'application/json'
        };
        const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/vote/perform`, payload, { headers });

        return response
    } catch (error) {

        throw error;
    }
};