import { createSlice } from "@reduxjs/toolkit";

let initialSocialmediaState = { SocialMediaUserInfo: [] };

let SocialMediaSlice = createSlice({
    name: 'SocialMedia',
    initialState:initialSocialmediaState,
    reducers: {
        getSocialMediaUserInfo(state, action) {
            state.SocialMediaUserInfo = action.payload
        }
    }
})

export let  SocialMediaAction = SocialMediaSlice.actions;

export default SocialMediaSlice