import React from 'react'
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'



const FollowerPageUserInfoSekeleton = () => {
    return (
        <>

            <div className="pt-80 pb-40" style={{ marginBottom: "40px" }}
            //  style={!props.MarginTopNone ? { paddingTop: "100px", paddingBottom: "40px" } :
            //     { paddingBottom: "40px" }}
            >
                <div className="container">
                    <div className="profile-wrapper my-profile">
                        <div className="profile-thumb">
                            <Skeleton circle height="100%" width="100%" />

                        </div>
                        <div className="profile-content" >
                            <h4 className="title "><Skeleton width="50%" /></h4>
                            <p className="font--md ">
                                <Skeleton width="20%" />
                                <Skeleton width="10%" />
                            </p>
                        </div>
                    </div>
                </div>
            </div></>
    )
}

export default FollowerPageUserInfoSekeleton