import React from 'react';
import UserData from './UserData';
import { Link } from 'react-router-dom';

const WelcomeBackNotification = ({ NotificationData, is_seen }) => {



    return (
        <>
            <UserData NotificationData={NotificationData} message={"from Big Photo Hunt is checking in to say hi. 👋"} is_seen={is_seen}>
                <div className="d-flex flex-wrap flex-xl-nowrap justify-content-sm-end gap-2 gap-xl-3 btn--group" style={{ margin: "auto 0px" }}>

                    <Link
                        to={`mailto:kend@bigphotohunt.com?subject=${encodeURIComponent("Thanks for checking in, Ken")}&body=${encodeURIComponent("I got your notification and am sending you an email.")}`}
                        className="btn btn--base btn--116"
                        data-normal="Follow"
                        data-active="Following"
                    >
                        Email
                    </Link>
                </div>
            </UserData >
        </>
    );
};

export default WelcomeBackNotification;
