import React from 'react'

const  PaymentBtn = ({ loading, handleButtonClick, productKey, buttonText }) => {
    return (
        <>


            
            {!loading ? (
            <button onClick={() => handleButtonClick(productKey)} className="btn">
                {buttonText}
            </button>
            ) : (
            <button className="btn">
                <span className="loader"></span>
            </button>
            )}
            
        </>
    )
}

export default PaymentBtn
