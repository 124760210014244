import React from 'react'
import { useState } from "react";
import { useEffect, useRef } from "react";
import axios from "axios";
import ProfileInfo from "../Components/Profile/ProfileInfo"
import ProfileNoPhoto from '../Components/Profile/NoPhotoSection'
import { Link } from 'react-router-dom';
import { useSelector } from "react-redux";
import { decryptUserData } from '../utils/encryptedData'
import ReactGA, { set } from "react-ga"
import ImageContainer from '../Components/MainImageComponent/ImageContainer';
import { LightBoxAction } from '../Store/LightboxSlice';
import { useDispatch } from 'react-redux';
import { fetchProfileAndUpvotePhotos } from '../utils/API/PhotoListApi';
import GalleryContainer from '../Components/Gallery/GalleryContainer';
import { GalleryAction } from '../Store/Gallery-Slice';
import { useLocation } from "react-router-dom";
import { useNavigate } from 'react-router-dom';

let increment = 0;

const ProfilePage = () => {
    const [MyPhotoState, setMyPhotoState] = useState(true)
    const [UpvotePhotoState, setUpvotePhotoState] = useState(false)
    const [MyotherWorkState, setMyotherWorkState] = useState(false)
    const [firstloadPhoto, setfirstloadPhoto] = useState([])
    const [loadPhoto, setloadPhoto] = useState([])
    const [spinner, setspinner] = useState(false)
    const [CheckEmptyArry, setCheckEmptyArry] = useState(true)
    const [FilterValue, setFilterValue] = useState('')
    const [checkingPhotoLength, setcheckingPhotoLength] = useState([])
    const [EmptyList, setEmptyList] = useState(false)
    const [IsMobile, setIsMobile] = useState(false)
    // let [isPaid, setisPaid] = useState(false)
    let footerr = useRef();


    let [ReloadGalleryPhotolist, setReloadGalleryPhotolist] = useState(false)

    let AfterUploadPhoto = useSelector(state => state.Modal.StateAfterUploadPhoto)
    let UserPaymentStatus = useSelector(state => state.PayWall.UserPaymentStatus)

    let dispatch = useDispatch()

    let MyPhotoHndler = () => {
        setMyotherWorkState(false);
        setMyPhotoState(true);
        setUpvotePhotoState(false)
        dispatch(GalleryAction.setGalleryUploadComponentState({ open: false, BtnDisable: false }))
        navigate(`/profile`);
    }

    let UpvotePhotoHandler = () => {
        setMyotherWorkState(false);
        setMyPhotoState(false);
        setUpvotePhotoState(true)
        dispatch(GalleryAction.setGalleryUploadComponentState({ open: false, BtnDisable: false }))
        navigate(`/profile?tab=upvotes`);
    }

    let MyOtherWorkHandler = () => {
        setMyotherWorkState(true);
        setMyPhotoState(false);
        setUpvotePhotoState(false)

        dispatch(GalleryAction.setViewGallery({ mainGallery: true, manageGallery: false }))
        dispatch(GalleryAction.setGalleryUploadComponentState({ open: false, BtnDisable: false }))
        setReloadGalleryPhotolist(!ReloadGalleryPhotolist)
        navigate(`/profile?tab=gallery`);
    }
    // <-------------------------------------------->

    let OpenGalleryPhotosUploadComponentFn = (val) => {
        MyOtherWorkHandler()

    }
    // <-------------------------------------------->

    let navigate = useNavigate();

    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    let Tabdata = searchParams.get('tab');

    useEffect(() => {

        if (Tabdata == "gallery") {
            MyOtherWorkHandler()
        } else if (Tabdata == "upvotes") {
            UpvotePhotoHandler()
        }
    }, [Tabdata])
    // <---------------- decrypted data ------------->
    const encryptedUserData = localStorage.getItem('Data');
    const decryptedUserData = decryptUserData(encryptedUserData);
    let usertoken = decryptedUserData.token
    let displayUserSocailPhoto = decryptedUserData.Display_UserSocailPhoto
    let display_name = decryptedUserData.useDisplay_name
    let LoginUserid = decryptedUserData.userid
    let isloggedin = decryptedUserData.isLoggedIn

    // <-------------------------------------------->


    //////////

    function AddLibrary(urlOfTheLibrary) {
        const script = document.createElement("script");
        script.src = urlOfTheLibrary;
        script.async = true;
        document.body.appendChild(script);
    }

    //..............................

    let LeaderboardHandler = () => {
        setFilterValue("leader_board")
        increment = 0
        setCheckEmptyArry(true)
        // setfirstloadPhoto([])
        // setloadPhoto([])
    }
    let MostRecentHandler = () => {
        setFilterValue("by_recent_photos")
        increment = 0
        setCheckEmptyArry(true)
        // setfirstloadPhoto([])
        // setloadPhoto([])
    }
    // ..............................

    useEffect(() => {
        ReactGA.pageview(window.location.pathname + window.location.search);

        if (window.innerWidth <= 500) {
            setIsMobile(true)
        }

    }, [])




    //......................

    let apiCall = (e) => {
        if (e[0].isIntersecting) {
            increment = increment + 10;

            let LoadPhotos = async function () {

                setspinner(true)
                try {

                    const response = await fetchProfileAndUpvotePhotos("by_user", FilterValue, increment, usertoken, UpvotePhotoState);
                    const newPhotos = response.data.data.results;
                    setloadPhoto(prevPhotos => [...prevPhotos, ...newPhotos])

                    if (newPhotos.length == 0 || newPhotos.length < 10) {
                        setCheckEmptyArry(false)
                    }

                    setspinner(false)


                } catch (error) {

                }
            }
            LoadPhotos();
        }


    }

    ///////////

    useEffect(() => {

        const Observer = new IntersectionObserver(apiCall, {
            root: null,
            threshold: 0

        })

        if (footerr.current) Observer.observe(footerr.current)
        return () => {
            if (footerr.current) Observer.unobserve(footerr.current);
        };
    }, [FilterValue, firstloadPhoto])
    ////////////


    // .................. checking user upload or upvote photo or not.................



    useEffect(() => {
        setfirstloadPhoto([])
        setloadPhoto([])
        setEmptyList(false)
        let LoadPhotos = async function () {
            setCheckEmptyArry(true)
            setspinner(true)
            try {

                const response = await fetchProfileAndUpvotePhotos("by_user", FilterValue, 0, usertoken, UpvotePhotoState);

                // setisPaid(response.data.data?.isPaid)
                setfirstloadPhoto(response.data.data.results)
                if (response.data.data.results.length < 10) {
                    setCheckEmptyArry(false)
                } else {
                    setCheckEmptyArry(true)
                }
                if (response.data.data.results.length == 0) {
                    setEmptyList(true)
                }
                setspinner(false)

                increment = 0

            } catch (error) {

            }
            // .............

        }
        LoadPhotos();
    }, [FilterValue, AfterUploadPhoto, UpvotePhotoState, MyPhotoState])

    useEffect(() => {


        let LoadPhotoCount = async function () {


            let config = {
                method: 'post',

                url: `${process.env.REACT_APP_BASE_URL}/photo/get_photos_count`,
                headers: {
                    'Authorization': `Bearer ${usertoken}`
                }
            };

            axios.request(config)
                .then((response) => {
                    const data = response.data.data;

                    setcheckingPhotoLength(data[0])
                })
                .catch((error) => {

                });


        }
        LoadPhotoCount();
    }, [AfterUploadPhoto])






    let AllloadPhoto;
    if (increment === 0) {
        AllloadPhoto = [...firstloadPhoto]
    } else {

        AllloadPhoto = [...firstloadPhoto, ...loadPhoto]

    }

    useEffect(() => {

        if (AllloadPhoto && (MyPhotoState || UpvotePhotoState)) {

            dispatch(LightBoxAction.setLightBoxState({ PhotoArr: AllloadPhoto, LoginUserPhotos: MyPhotoState ? true : false }))
        }
    }, [AllloadPhoto])




    return (
        <>
            <ProfileInfo onMyOtherWork={OpenGalleryPhotosUploadComponentFn} UserPaymentStatus={UserPaymentStatus} />

            <section className="photo-feed-section my-profile" id="MygalleryComponent">
                <div className="container" >
                    <div className="row">
                        <div className={`${MyotherWorkState ? "col-12" : "col-md-9 col-lg-8"}`}>

                            {/* {(checkingPhotoLength.photo_count > 0) || (checkingPhotoLength.upvote_count > 0) ? */}
                            <div className="feed_content">
                                <ul className="heading_tabs" role="tablist">
                                    {<li className={`${MyPhotoState ? "active" : ""}`} role="presentation" data-bs-toggle="tab" data-bs-target="#profile" aria-selected="true" onClick={MyPhotoHndler}
                                    // onClick={MyPhotoHndler}
                                    >
                                        My Hunted Photos
                                    </li>}
                                    {<li className={`${UpvotePhotoState ? "active" : ""}`} role="presentation" data-bs-toggle="tab" data-bs-target="#upvotes" aria-selected="false" tabIndex={-1} onClick={UpvotePhotoHandler}
                                    // onClick={UpvotesHndler}
                                    >
                                        My Upvotes
                                    </li>}

                                    {<li className={`${MyotherWorkState ? "active" : ""}`} role="presentation" data-bs-toggle="tab" data-bs-target="#otherWork"
                                        onClick={MyOtherWorkHandler}>
                                        My Personal Gallery
                                    </li>}

                                </ul>
                                <div className="tab-content" id="profileTabContent">

                                    {(MyPhotoState) &&
                                        <div className="" id="profile" role="tabpanel">
                                            {checkingPhotoLength.photo_count > 0 && <div className="feed_content_heading d-flex flex-column flex-lg-row align-items-center justify-content-center justify-content-lg-between flex-wrap">
                                                <h3 className="title">My Hunted Photos</h3>
                                                <ul className="feedFilter dropdown">
                                                    <li className="activated">Most Recent </li>
                                                    <img src="./assets/img/icons/arrow-down.svg" alt="img" />
                                                    <ul className="filter_lists">
                                                        <li className={`${FilterValue !== "leader_board" ? "active" : ""}`}>
                                                            <Link onClick={MostRecentHandler}>Most Recent </Link>
                                                        </li>

                                                        <li className={`${FilterValue == "leader_board" ? "active" : ""}`}>
                                                            <Link onClick={LeaderboardHandler}>By Upvotes</Link>
                                                        </li>
                                                        {/* <li><Link  >New Members</Link></li>
                                                            <li>
                                                                <Link  >Last Month’s Winners</Link>
                                                            </li> */}
                                                    </ul>
                                                </ul>
                                            </div>}
                                            {/* {checkingPhotoLength.photo_count > 0 ?  */}
                                            <div className="feed_items text-center text-md-start">

                                                {AllloadPhoto.map((data) => (<ImageContainer

                                                    img={!IsMobile ? data.photo_url : `https://res.cloudinary.com/duk09hrua/image/upload/c_fit,w_700,f_auto,q_auto/Resized_Photos/${data.compressedImageId}`}
                                                    postId={data.id}
                                                    key={data.id}
                                                    TotalVotes={data.total_votes}
                                                    voted={data.is_vote}
                                                    Name={data.name}
                                                    postedDate={data.photo_posted_date}
                                                    Userid={data.user_id}
                                                    TotalComments={data.total_comments}
                                                    First5CommentsPhotoList={data.commenters_info}
                                                    Userlocation={data.state}
                                                    UserPhoto={data.user_photo_url}
                                                    First1Comments={data.comments}
                                                    usertoken={usertoken}
                                                    description={data.description}
                                                    feedback={data.feedback_type}
                                                    metadata={data.metadata}
                                                    loginUserid={LoginUserid}
                                                    isLoggedIn={isloggedin}
                                                    show={true}
                                                    sharebtn={true}
                                                    displaySocailUserPhoto={displayUserSocailPhoto}
                                                    display_name={display_name}
                                                    UserPaymentStatus={true}

                                                />))
                                                }

                                            </div>

                                            {EmptyList && <ProfileNoPhoto showMenu={false} myphoto={true} Upvotephoto={false} />}
                                            {/* : <ProfileNoPhoto showMenu={false} myphoto={true} Upvotephoto={false} />} */}
                                        </div>}
                                    {/* .................... */}

                                    {UpvotePhotoState && <div className="" id="upvotes" role="tabpanel">
                                        {checkingPhotoLength.upvote_count > 0 && <div className="feed_content_heading d-flex flex-column flex-lg-row align-items-center justify-content-center justify-content-lg-between flex-wrap">
                                            <h3 className="title">My Upvotes</h3>
                                            <ul className="feedFilter dropdown">
                                                <li className="activated">Most Recent </li>
                                                <img src="./assets/img/icons/arrow-down.svg" alt="img" />
                                                <ul className="filter_lists">
                                                    <li className="active">
                                                        <Link onClick={MostRecentHandler}>Most Recent </Link>
                                                    </li>
                                                    <li><Link onClick={LeaderboardHandler}>By Upvotes</Link></li>
                                                    {/* <li><a  >New Members</a></li>
                                                    <li>
                                                        <a  >Last Month’s Winners</a>
                                                    </li> */}
                                                </ul>
                                            </ul>
                                        </div>}
                                        {/* {checkingPhotoLength.upvote_count > 0 ?  */}
                                        <div className="feed_items text-center text-md-start">

                                            {AllloadPhoto?.map((data) => (<ImageContainer

                                                img={!IsMobile ? data.photo_url : `https://res.cloudinary.com/duk09hrua/image/upload/c_fit,w_700,f_auto,q_auto/Resized_Photos/${data.compressedImageId}`}
                                                postId={data.photo_id}
                                                key={data.id}
                                                TotalVotes={data.total_votes}
                                                voted={data.is_vote}
                                                Name={data.name}
                                                postedDate={data.photo_posted_date}
                                                TotalComments={data.total_comments}
                                                Userlocation={data.state}
                                                UserPhoto={data.user_photo_url}
                                                Userid={data.user_id}
                                                usertoken={usertoken}
                                                First1Comments={data.comments}
                                                description={data.description}
                                                feedback={data.feedback_type}
                                                metadata={data.metadata}
                                                is_follow={data.is_follow}
                                                loginUserid={LoginUserid}
                                                isLoggedIn={isloggedin}
                                                displaySocailUserPhoto={displayUserSocailPhoto}
                                                display_name={display_name}
                                                UserPaymentStatus={UserPaymentStatus}
                                            />))
                                            }

                                        </div>
                                        {EmptyList && <ProfileNoPhoto showMenu={false} myphoto={false} Upvotephoto={true} />}
                                        {/* : <ProfileNoPhoto showMenu={false} myphoto={false} Upvotephoto={true} />} */}
                                    </div>}


                                    {/* <----------------------------------------------------------> */}

                                    {MyotherWorkState &&
                                        <GalleryContainer usertoken={usertoken} ReloadGalleryPhotolist={ReloadGalleryPhotolist} UserPaymentStatus={UserPaymentStatus} />
                                    }

                                    {/* <-----------------------------------------------------------> */}

                                    {CheckEmptyArry && spinner && (MyPhotoState || UpvotePhotoState) && <div style={{ display: "flex", justifyContent: "center" }}>
                                        <div className="spinner-border text-secondary" role="status">
                                            <span className="sr-only"></span>
                                        </div>
                                    </div>}

                                </div>
                            </div>
                            {/* 
                                : <ProfileNoPhoto showMenu={true} myphoto={true} />
                            } */}


                        </div>


                    </div>
                </div>
            </section >






            {CheckEmptyArry && AllloadPhoto.length > 0 && (MyPhotoState || UpvotePhotoState) && <div ref={footerr}></div>}


            {AddLibrary("./assets/js/main.js")}
        </>
    )
}

export default ProfilePage