import { createSlice } from "@reduxjs/toolkit";

let initialGenericState = { LogoClickState: false, };


let GenericSlice = createSlice({
    name: 'Generic',
    initialState: initialGenericState,
    reducers: {
        setLogoClickState(state, action) {
            state.LogoClickState = action.payload
        },

    }
})

export let GenericAction = GenericSlice.actions;

export default GenericSlice