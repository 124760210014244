import axios from 'axios';
import amplitude from 'amplitude-js';

export const followUser = async (followerID, isFollow, usertoken, loginUserID, socketFn, BtnTextFollowBack) => {
    try {

        if (isFollow) {
            amplitude.getInstance().logEvent(`${BtnTextFollowBack ? "Follow Back" : "Follow"}`, { UserID: loginUserID });
        }
        const payload = {
            followerID,
            is_follow: isFollow,
        };
        const headers = {
            'Authorization': `Bearer ${usertoken}`,
            'Content-Type': 'application/json'
        };
        const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/follow/user`, payload, { headers });

        if (response.data.data.first_time_follow === false) {
            socketFn()
        }
        // socketFn()

        return response;
    } catch (error) {

        throw error;
    }
};