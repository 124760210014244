import React from "react";
import { calculateTimeDifference } from "../../utils/CalculateCommentTime";
import ReplyCommentList from "./ReplyCommentList";
import ReplyInput from "./ReplyInput";
import { useState } from "react";
import axios from "axios";
import { formatLikes } from "../../utils/FomattedLike";
import { useNavigate } from "react-router-dom";
import { AES } from "crypto-js";
import { useSelector } from "react-redux";
import { followUser } from "../../utils/API/FollowApi";
import { SocketAction } from "../../Store/SocketSlice";
import { useDispatch } from "react-redux";
import amplitude from "amplitude-js";

const Commentslist = (props) => {
  let [ReplyinputState, setReplyinputState] = useState(false);
  let [placeholderValue, setplaceholderValue] = useState("");
  let [replyArr, setreplyArr] = useState([]);
  let [ReplyCommentShow, setReplyCommentShow] = useState(false);
  let [TotalVote, setTotalVote] = useState(props.totalVote);
  let [upDownvote, setupDownvote] = useState(props.isVote || false);
  let [votebtnactive, setvotebtnactive] = useState(props.isVote || false);
  let [checkChangingInArr, setcheckChangingInArr] = useState(false);
  let [spinner, setspinner] = useState(false);
  let [follow, setfollow] = useState(props.is_follow);

  const timeDifference = calculateTimeDifference(props.CeatedAt);

  let getReplyCommentsInfo = function (val) {
    setReplyinputState(!ReplyinputState);
    setplaceholderValue(val);
  };

  /// Like
  const formattedLikes = formatLikes(TotalVote ? TotalVote : 0);

  // < --------Socket ------->
  let dispatch = useDispatch();
  let ReloadNotificationFn = useSelector(
    (state) => state.Socket.ReloadNotificationSenderFn
  );

  let SendNotificationFn = () => {
    dispatch(SocketAction.setSocketReceiverUserID(props.userid));
    dispatch(SocketAction.setReloadNotificationSenderFn(!ReloadNotificationFn));
  };
  // <-------------------------------------------->

  let navigate = useNavigate();

  let OtheruserProfileNavigateHandler = (event) => {
    event.preventDefault();

    if (props.loginUserid === props.userid) {
      navigate("/profile");
    } else {
      let userData = {
        PhotoID: "",
        UserID: props.userid,
      };
      const secretKey = process.env.REACT_APP_SECRET_KEY;
      const encryptedUserData = AES.encrypt(
        JSON.stringify(userData),
        secretKey
      ).toString();
      navigate(
        `/otherusers-profile?userdata=${encodeURIComponent(encryptedUserData)}`
      );
    }

    window.scrollTo(0, 0);
  };

  let ReplyCommentHandler = () => {
    setReplyinputState(!ReplyinputState);
    setplaceholderValue({
      username: props.username,
      photoID: props.photoID,
      parentCommentID: props.id,
    });
  };

  let voteHandler = async (event) => {
    event.preventDefault();
    setvotebtnactive(!votebtnactive);
    setupDownvote(!upDownvote);

    let up_or_down_vote;
    if (upDownvote) {
      up_or_down_vote = "remove";
    } else {
      up_or_down_vote = "add";
      SendNotificationFn();
      amplitude
        .getInstance()
        .logEvent("Like A Comment", { UserID: props.loginUserid });
    }
    // ......................
    try {
      const payload = {
        commentID: props.id,
        actionType: up_or_down_vote,
      };
      let headers = {
        Authorization: `Bearer ${props.usertoken}`,
        "Content-Type": "application/json",
      };
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/vote/comment`,
        payload,
        { headers }
      );

      setTotalVote(response.data.data.voteCount);
    } catch (error) {}
  };

  let LoadReplycommentsList = async function () {
    setReplyCommentShow(true);
    setspinner(true);
    try {
      const payload = {
        photoID: props.photoID,
        topcommentID: props.id,
      };
      let headers = {
        Authorization: `Bearer ${props.usertoken}`,
        "Content-Type": "application/json",
      };
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/comment/list_replies`,
        payload,
        { headers }
      );

      setreplyArr(response.data.data);

      setspinner(false);
    } catch (error) {
      setspinner(false);
    }
  };

  let FollowHandler = async (event) => {
    event.preventDefault();
    setfollow(!follow);

    if (!follow) {
      SendNotificationFn();
    }
    try {
      await followUser(
        props.userid,
        !follow,
        props.usertoken,
        props.loginUserid,
        SendNotificationFn
      );
    } catch (error) {}
  };

  let PushValueintoReplayArrayFn = function (val) {
    setcheckChangingInArr(true);
    setreplyArr([...replyArr, val]);
  };

  return (
    <>
      <li className="comment">
        <div
          className="comment_img"
          onClick={OtheruserProfileNavigateHandler}
          style={{
            backgroundImage: `url(${props.userPhoto || ""})`,
            backgroundPosition: "center",
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
            borderRadius: "50%",
            cursor: "pointer",
          }}
        >
          {!props.userPhoto && (
            <img
              src={`https://ui-avatars.com/api/?name=${props.username}&size=40&rounded=true&background=01655a&color=fff`}
              alt="img"
            />
          )}
        </div>
        <div className="comment_text">
          <div style={{ display: "flex", gap: "8px" }}>
            <h6
              className="name"
              style={{ cursor: "pointer" }}
              onClick={OtheruserProfileNavigateHandler}
            >
              {props.username}
            </h6>
            {props.loginUserid !== props.userid && (
              <span
                className="PointerClass"
                onClick={FollowHandler}
                style={{
                  display: "flex",
                  alignItems: "center",
                  fontSize: "13px",
                }}
              >
                {!follow ? "Follow" : "Following"}
              </span>
            )}
          </div>

          <p className="text">{props.commentText}</p>
          <div className="comment_meta">
            <span
              className={`likes ${votebtnactive ? "active" : ""}`}
              onClick={voteHandler}
            >
              {formattedLikes} Like
              <img
                className="stock"
                src="./assets/img/icons/thumb.svg"
                alt="img"
              />
              <img
                className="fill"
                src="./assets/img/icons/thumb-fill.svg"
                alt="img"
              />
            </span>
            <button
              onClick={ReplyCommentHandler}
              className="reply"
              type="button"
            >
              Reply <img src="./assets/img/icons/reply.svg" alt="img" />
            </button>
            <span className="date">{timeDifference}</span>
          </div>
          {props.totalReplies > 0 && !ReplyCommentShow && (
            <div
              style={{ display: "flex", gap: "10px", color: "black" }}
              onClick={LoadReplycommentsList}
            >
              <i
                style={{ fontSize: "1rem" }}
                className="bi bi-arrow-return-right"
              />
              <span className="PointerClass">{props.totalReplies} Replies</span>
            </div>
          )}
        </div>

        <ul
          className={`children ${
            ReplyCommentShow || checkChangingInArr ? "children-expanded" : ""
          }`}
        >
          {replyArr &&
            replyArr.map((data) => (
              <ReplyCommentList
                ReplyCommentsInfoFun={getReplyCommentsInfo}
                commentText={data.comment_text}
                username={data.username}
                key={data.id}
                id={data.id}
                topcommentID={props.id}
                userPhoto={data.user_profile_pic}
                replyToName={data.replyToName}
                replyToId={data.replyToId}
                usertoken={props.usertoken}
                CeatedAt={data.createdAt}
                isVote={data.is_vote}
                totalVote={data.total_likes}
                photoID={props.photoID}
                inputInshalstate={ReplyinputState}
                userid={data.user_id}
                loginUserid={props.loginUserid}
              />
            ))}
          {spinner && (
            <div style={{ display: "flex", width: "23px", height: "23px" }}>
              <div
                className="spinner-border text-warning"
                role="status"
                style={{ width: "100%", height: "100%" }}
              >
                <span className="visually-hidden" />
              </div>
            </div>
          )}
        </ul>
        {ReplyinputState && (
          <ReplyInput
            photoid={props.photoId}
            userCommentinfo={placeholderValue}
            setvalueFn={PushValueintoReplayArrayFn}
            userid={placeholderValue.userid || props.userid}
          />
        )}
      </li>
    </>
  );
};

export default Commentslist;
