import React from 'react'
import { useEffect } from "react";
import { useState, useRef } from "react";
import { Link } from 'react-router-dom'
import { useSelector } from 'react-redux';
import { decryptUserData } from '../../utils/encryptedData'
import { useDispatch } from 'react-redux';
import { ModalAction } from '../../Store/Modal-Slice';
import ImageContainer from '../MainImageComponent/ImageContainer';
import { mainPhotosFeedpagelist } from '../../utils/API/PhotoListApi';
import { LightBoxAction } from '../../Store/LightboxSlice';
import amplitude from 'amplitude-js'

let increment = 0;

const FavouritePhotos = () => {


    let footer = useRef();
    let [firstloadPhoto, setfirstloadPhoto] = useState([])
    let [loadPhoto, setloadPhoto] = useState([])
    let [spinner, setspinner] = useState(false)
    let [CheckEmptyArry, setCheckEmptyArry] = useState(true)
    let [FilterValue, setFilterValue] = useState('')
    let [RecordNotFound, setRecordNotFound] = useState(false)
    let [IsMobile, setIsMobile] = useState(false)
    // let [isPaid, setisPaid] = useState(false)


    let isLogginState = useSelector(state => state.Login.isLoggedIn)
    let UserPaymentStatus = useSelector(state => state.PayWall.UserPaymentStatus)

    let dispatch = useDispatch()

    // <---------------- decrypted data ------------->
    const encryptedUserData = localStorage.getItem('Data');
    const decryptedUserData = decryptUserData(encryptedUserData);
    let usertoken = decryptedUserData.token
    let isloggedin = decryptedUserData.isLoggedIn
    let LoginUserid = decryptedUserData.userid
    let display_name = decryptedUserData.useDisplay_name
    let displaySocailUserPhoto = decryptedUserData.Display_UserSocailPhoto

    // <-------------------------------------------->




    function AddLibrary(urlOfTheLibrary) {
        const script = document.createElement("script");
        script.src = urlOfTheLibrary;
        script.async = true;
        document.body.appendChild(script);
    }

    let filterValueHandler = (val) => {
        setFilterValue(val)
        increment = 0
        setCheckEmptyArry(true)
    }




    // ..............................
    let apiCall = (e) => {
        if (e[0].isIntersecting) {
            increment = increment + 10;


            LoadPhotos(increment);
        }


    }

    let LoadMorePhotos = useSelector((state) => state.LightBox.LoadMorePhotos);



    useEffect(() => {
        if (LoadMorePhotos.fetchMorePhotos) {

            increment = increment + 10;
            LoadPhotos(increment);
            dispatch(LightBoxAction.setLoadMorePhotos({ fetchMorePhotos: false, fetchAction: false }))
        }
    }, [LoadMorePhotos.fetchAction])

    let LoadPhotos = async function (incrementValue) {
        setspinner(true)
        try {
            const response = await mainPhotosFeedpagelist("all", FilterValue || (isloggedin ? "by_recent_photos" : "last_week"), incrementValue, usertoken, isloggedin);

            const newPhotos = response.data.data.results;
            setloadPhoto(prevPhotos => [...prevPhotos, ...newPhotos])
            amplitude.getInstance().logEvent('View (Scroll) Photo Feed', { UserID: LoginUserid });

            if (newPhotos.length === 0 || newPhotos.length < 10) {
                setCheckEmptyArry(false)
            }

            setspinner(false)

        } catch (error) {

        }
    }


    ///////////

    useEffect(() => {

        const Observer = new IntersectionObserver(apiCall, {
            root: null,
            threshold: 0

        })
        const footerRef = footer.current;
        if (footerRef) {

            Observer.observe(footerRef)
        }
        return () => {
            if (footerRef) Observer.unobserve(footerRef);
        };
    }, [FilterValue, firstloadPhoto, isLogginState])
    ////////////


    useEffect(() => {
        setfirstloadPhoto([])
        setloadPhoto([])
        setRecordNotFound(false)
        if (window.innerWidth <= 500) {
            setIsMobile(true)
        }

        let LoadPhotos = async function () {
            setspinner(true)
            try {
                const response = await mainPhotosFeedpagelist("all", FilterValue || (isloggedin ? "by_recent_photos" : "last_week"), 0, usertoken, isloggedin);



                // setisPaid(response.data.data.isPaid)
                setfirstloadPhoto(response.data.data.results)
                setspinner(false)
                setCheckEmptyArry(true)
                increment = 0
                if (response.data.data.results.length == 0) {
                    setRecordNotFound(true)
                }
            } catch (error) {


            }

        }
        LoadPhotos();

    }, [isLogginState, FilterValue])

    let AllloadPhoto;

    if (increment === 0) {
        AllloadPhoto = [...firstloadPhoto]
    } else {

        AllloadPhoto = [...firstloadPhoto, ...loadPhoto]

    }

    useEffect(() => {
        if (!isloggedin && AllloadPhoto.length > 10) {
            dispatch(ModalAction.setJoinNowModalState(true))
        }

        if (AllloadPhoto) {
            dispatch(LightBoxAction.setLightBoxState({ PhotoArr: AllloadPhoto, LoginUserPhotos: false }))
        }
    }, [AllloadPhoto])




    return (
        <>

            <div className="feed_content">
                <div className="feed_content_heading d-flex gap-lg-3 flex-column flex-md-row align-items-center justify-content-center justify-content-md-between flex-wrap">

                    {/* //// */}
                    <h3 className="title">Upvote Your Favorite Photos</h3>

                    <ul className="feedFilter dropdown">
                        <li className="activated"></li>
                        <img src="./assets/img/icons/arrow-down.svg" alt='img' />

                        {isloggedin ? <ul className="filter_lists">

                            <li className="active">
                                <Link onClick={() => { filterValueHandler("by_recent_photos") }}>Most Recent </Link>
                            </li>
                            <li >
                                <Link onClick={() => { filterValueHandler("following") }}>I’m Following</Link>
                            </li>
                            <li>
                                <Link onClick={() => { filterValueHandler("this_week") }}>This Week</Link>
                            </li>
                            <li>
                                <Link onClick={() => { filterValueHandler("last_week") }}>Last Week</Link>
                            </li>
                            <li >
                                <Link onClick={() => { filterValueHandler("participating_members") }}>Participating</Link>
                            </li>
                            <li >
                                <Link onClick={() => { filterValueHandler("leader_board") }}>By Upvotes</Link>
                            </li>

                            <li >
                                <Link onClick={() => { filterValueHandler("new_to_you") }}>New To You</Link>
                            </li>
                        </ul> :
                            <ul className="filter_lists">

                                <li className="active">
                                    <Link onClick={() => { filterValueHandler("last_week") }}>Last Week</Link>
                                </li>
                                <li>
                                    <Link onClick={() => { filterValueHandler("this_week") }}>This Week</Link>
                                </li>

                                <li >
                                    <Link onClick={() => { filterValueHandler("by_recent_photos") }}>Most Recent </Link>
                                </li>
                            </ul>}

                    </ul>

                    {/* /// */}
                </div>

                <div className="feed_items text-center text-md-start">

                    {AllloadPhoto?.map((data) => (<ImageContainer

                        img={!IsMobile ? data.photo_url : `https://res.cloudinary.com/duk09hrua/image/upload/c_fit,w_700,f_auto,q_auto/Resized_Photos/${data.compressedImageId}`}
                        postId={data.id}
                        key={data.id}
                        TotalVotes={data.total_votes}
                        voted={data.is_vote}
                        Name={data.name}
                        postedDate={data.photo_posted_date}
                        Userlocation={data.state}
                        UserPhoto={data.user_photo_url}
                        Userid={data.user_id}
                        usertoken={usertoken}
                        isLoggedIn={isloggedin}
                        loginUserid={LoginUserid}
                        display_name={display_name}
                        displaySocailUserPhoto={displaySocailUserPhoto}
                        First1Comments={data.comments}
                        TotalComments={data.total_comments}
                        description={data.description}
                        feedback={data.feedback_type}
                        metadata={data.metadata}
                        is_follow={data.is_follow}
                        UserPaymentStatus={UserPaymentStatus}

                    />))}
                    {RecordNotFound && <h4>You’ve seen all the recent hunted photos.</h4>}



                    {CheckEmptyArry && spinner && <div style={{ display: "flex", justifyContent: "center" }}>
                        <div className="spinner-border text-secondary" role="status" >
                            <span className="sr-only"></span>
                        </div>
                    </div>}



                    {CheckEmptyArry && firstloadPhoto.length > 0 && <div ref={footer}></div>}


                </div>
            </div >

            {AddLibrary("./assets/js/magnific-popup.min.js")}
            {AddLibrary("./assets/js/owl.carousel.min.js")}
            {AddLibrary("./assets/js/main.js")}


        </>
    )
}

export default FavouritePhotos