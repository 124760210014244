import React from 'react'
import { formatLikes } from "../../utils/FomattedLike"
import { useState } from 'react'
import axios from 'axios'
import { calculateTimeDifference } from '../../utils/CalculateCommentTime';

import { useNavigate } from 'react-router-dom';
import { AES } from 'crypto-js';
import { SocketAction } from '../../Store/SocketSlice';
import { useSelector, useDispatch } from 'react-redux';

const ReplyToYOURComment = ({ NotificationData, usertoken, CeatedAt, is_seen }) => {

    let [TotalVote, setTotalVote] = useState(NotificationData.upvoted_count)
    let [upDownvote, setupDownvote] = useState(NotificationData.is_vote)
    let [votebtnactive, setvotebtnactive] = useState(NotificationData.is_vote)
    let [commentValue, setcommentValue] = useState("")
    let [Loader, setLoader] = useState(false)
    let [PlaceholderValueChange, setPlaceholderValueChange] = useState(false)

    const timeDifference = calculateTimeDifference(CeatedAt);

    // //// Like////

    const formattedLikes = formatLikes(TotalVote);

    // < --------Socket ------->
    let dispatch = useDispatch()

    let ReloadNotificationFn = useSelector(state => state.Socket.ReloadNotificationSenderFn)

    let SendNotificationFn = () => {
        dispatch(SocketAction.setSocketReceiverUserID(NotificationData.sender_id))
        dispatch(SocketAction.setReloadNotificationSenderFn(!ReloadNotificationFn))
    }
    // <-------------------------------------------->

    let OtheruserProfileNavigateHandler = (event) => {
        event.preventDefault();

        if (NotificationData.receiver_id === NotificationData.photo_owner_id) {
            navigate("/profile");
        } else {
            let userData = {
                PhotoID: NotificationData.photo_id,
                UserID: NotificationData.photo_owner_id,
            }
            const secretKey = process.env.REACT_APP_SECRET_KEY;
            const encryptedUserData = AES.encrypt(JSON.stringify(userData), secretKey).toString();
            navigate(`/otherusers-profile?userdata=${encodeURIComponent(encryptedUserData)}`);
        }

        window.scrollTo(0, 0)
    }

    let navigate = useNavigate()

    let NavigatorHandler = () => {
        let userData = {
            PhotoID: "",
            UserID: NotificationData.sender_id,
        }
        const secretKey = process.env.REACT_APP_SECRET_KEY;
        const encryptedUserData = AES.encrypt(JSON.stringify(userData), secretKey).toString();
        navigate(`/otherusers-profile?userdata=${encodeURIComponent(encryptedUserData)}`);
        window.scrollTo(0, 0)
    }

    let voteHandler = async (event) => {

        event.preventDefault();
        setvotebtnactive(!votebtnactive)
        setupDownvote(!upDownvote)



        let up_or_down_vote;
        if (upDownvote) {
            up_or_down_vote = "remove"
        } else {
            up_or_down_vote = "add"
            SendNotificationFn()
        }
        // ......................
        try {
            const payload = {
                "commentID": NotificationData.comment_id,
                "actionType": up_or_down_vote
            };
            let headers = {
                'Authorization': `Bearer ${usertoken}`,
                'Content-Type': 'application/json'
            }

            const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/vote/comment`, payload, { headers });

            setTotalVote(response.data.data.voteCount)





        } catch (error) {

        }

    }

    let commentInputHandler = (e) => {
        setcommentValue(e.target.value)
    }

    let SubmitHandler = async (e) => {
        e.preventDefault()

        setLoader(true)
        try {
            let payload = {
                "photoID": NotificationData.photo_id,
                "commentText": commentValue,
                "topcommentID": NotificationData.top_comment_id,
                "subcommentID": NotificationData.sub_comment_id,
            };


            let headers = {
                'Authorization': `Bearer ${usertoken}`,
                'Content-Type': 'application/json'
            }


            await axios.post(`${process.env.REACT_APP_BASE_URL}/comment/add`, payload, { headers });



            setcommentValue('')
            setLoader(false)
            setPlaceholderValueChange(true)
            SendNotificationFn()
        } catch (error) {

            setLoader(false)
        }
    }


    return (
        <>
            <div className={`profile-card ${!is_seen ? "new-card" : ''} style--two`}>
                <div className="profile-card-inner">
                    <div className="profile-card-top-content mb-0">
                        <div className="profile-card-thumb">
                            <span className='PointerClass' onClick={NavigatorHandler}>
                                <img src={NotificationData.profile_pic ? NotificationData.profile_pic : `https://ui-avatars.com/api/?name=${NotificationData.name}&size=40&rounded=true&background=01655a&color=fff`} alt="profile" />
                            </span>
                        </div>
                        <div className="profile-card-content d-flex flex-wrap flex-xl-nowrap justify-content-between flex-grow-1 gap-2">
                            <ul className="profile-info-list">
                                <li className="name_info"><span className="title">
                                    <span onClick={NavigatorHandler} className='PointerClass text-title'>{NotificationData.name}</span>
                                </span>
                                    <span className="value font--sm text-title" style={{ marginLeft: "5px" }}>replied to your comment</span>
                                </li>
                                <li className="d-none d-sm-block"><span className="text--black80 mb-1">{`“${NotificationData.comment_text}”`}</span></li>
                                <li className="d-none d-sm-block">
                                    <ul className="d-flex flex-wrap flex-xl-nowrap align-items-center font--sm" style={{ gap: 4 }}>
                                        <li className="text--black80">{formattedLikes} Like</li>
                                        <li className="me-2">
                                            <button onClick={voteHandler} className="outline-none border-0 shadow-none bg-transparent like--btn" >
                                                {votebtnactive ?
                                                    <img src="./assets/img/icons/thumb-fill.svg" alt="img" className="fill" />
                                                    :
                                                    <img src="./assets/img/icons/thumb.svg" alt="img" className="outline" />
                                                }
                                            </button>
                                        </li>
                                        <li className="text--black40">{timeDifference}</li>
                                    </ul>
                                </li>
                            </ul>
                            <span className='PointerClass' onClick={OtheruserProfileNavigateHandler}><img src={`https://res.cloudinary.com/duk09hrua/image/upload/c_fit,w_250,f_auto,q_auto/Resized_Photos/${NotificationData.compressedImageId}`} alt="thumb" className="comment-photo d-none d-sm-block" /></span>
                        </div>
                    </div>
                    <ul className="profile-info-list d-sm-none mt-3">
                        <li className="d-flex gap-3 mb-3">
                            <span className="text--black80 mb-1">{`“${NotificationData.comment_text}”`}</span>
                            <a onClick={OtheruserProfileNavigateHandler}><img src={`https://res.cloudinary.com/duk09hrua/image/upload/c_fit,w_250,f_auto,q_auto/Resized_Photos/${NotificationData.compressedImageId}`} alt="thumb" className="comment-photo" style={{ width: 44, height: 44, objectFit: 'cover' }} /></a>
                        </li>
                        <li>
                            <ul className="d-flex flex-wrap flex-xl-nowrap align-items-center font--sm" style={{ gap: 4 }}>
                                <li className="text--black80">{formattedLikes} Like</li>
                                <li className="me-2">
                                    <button onClick={voteHandler} className="outline-none border-0 shadow-none bg-transparent like--btn" >
                                        {votebtnactive ?
                                            <img src="./assets/img/icons/thumb-fill.svg" alt="img" className="fill" />
                                            :
                                            <img src="./assets/img/icons/thumb.svg" alt="img" className="outline" />
                                        }
                                    </button>
                                </li>
                                <li className="text--black40">{timeDifference}</li>
                            </ul>
                        </li>
                    </ul>
                    <div className="write-comment mt-2 mt-sm-4">
                        <img src={NotificationData.login_photo_url ? NotificationData.login_photo_url : `https://ui-avatars.com/api/?name=${NotificationData.login_username}&size=40&rounded=true&background=01655a&color=fff`} alt="user" className="comment-user" />


                        <form onSubmit={SubmitHandler} className="comment-form">
                            <input type="text" className={`form-control ${commentValue ? "typed" : ''}`} value={commentValue} onChange={commentInputHandler} placeholder={!PlaceholderValueChange ? `Reply to @${NotificationData.name}` : 'Thank you! Your replay has been successfully submitted.'} />
                            {!Loader ? <button type="submit" className="outline-none border-0 shadow-none bg-transparent"><img src="./assets/img/icons/subtract.svg" alt="submit" /></button> :
                                <span style={{ fontSize: "4px", marginBottom: "14px" }} className={`${commentValue ? "Commentloader" : ''}`}></span>}

                        </form>
                    </div>
                </div>
            </div >

        </>
    )
}

export default ReplyToYOURComment