import React from 'react'
import UserData from './UserData'
import FollowAndThanksButton from './FollowAndThanksButton'
const ReceviceCongratulationNotification = ({ NotificationData, usertoken, loginUserid, is_seen }) => {
    return (
        <>

            <UserData NotificationData={NotificationData} message={"congratulated you for making the Weekly Top Ten!"} is_seen={is_seen}>
                <FollowAndThanksButton
                    ThanksFor={'thanks_on_weeklytopten_congratulation'}
                    usertoken={usertoken}
                    NotificationData={NotificationData}
                    loginUserid={loginUserid}
                />
            </UserData>


        </>
    )
}

export default ReceviceCongratulationNotification