export const smoothScrollToElement = (e,elementId) => {
    e.preventDefault();
    const element = document.querySelector(elementId);

   
    if (element) {
        element.scrollIntoView({ behavior: 'smooth' });

       
    }
};