import React from 'react'
import { useDispatch } from 'react-redux'
import { SocialMediaAction } from '../../Store/SocialMedia-Slice'
import { Link } from 'react-router-dom'
import { LoginSocialFacebook, LoginSocialGoogle } from 'reactjs-social-login'
import { useEffect } from 'react'
import { ModalAction } from '../../Store/Modal-Slice'
import { AES } from 'crypto-js'
import { useNavigate } from 'react-router-dom'
import axios from 'axios'
import { useSelector } from 'react-redux'
import { LoginAction } from '../../Store/Login-Slice'
import { useState } from 'react'
import { isUserExistApi } from '../../utils/API/AuthApi'



const SocialMediaButtons = ({ ReferralCode }) => {
  let [spinner, setspinner] = useState(false)
  let [googelspinner, setgoogelspinner] = useState(false)
  let [Fbspinner, setFbspinner] = useState(false)
  let [error, seterror] = useState(false)


  let dispatch = useDispatch()
  let navigate = useNavigate();
  let popupData = useSelector(state => state.Modal.PopupModalMessage)
  let UserSocialInfo = useSelector(state => state.SocialMedia.SocialMediaUserInfo)
  let SignInModalState = useSelector(state => state.Modal.SignInModalState)


  function ModifyFacebookPictureUrl(url) {

    const regex = /asid=(\d+)/;
    const match = url.match(regex);
    if (!match || match.length < 2) {
      console.error('Invalid URL provided');
      return null;
    }
    const asid = match[1];
    const graphApiUrl = `https://graph.facebook.com/${asid}/picture?type=large`;
    return graphApiUrl;
  }


  useEffect(() => {
    if (!UserSocialInfo[1] && !UserSocialInfo[4]) {
      return
    }

    if (UserSocialInfo[0] == "google") {
      setgoogelspinner(true)

    } else {
      setFbspinner(true)

    }


    let me = async function () {



      try {
        // const payload = {
        //   "email": UserSocialInfo[1] || UserSocialInfo[4],
        // };

        // const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/auth/Me`, payload);
        let userType = 'socialMedia';
        const response = await isUserExistApi(UserSocialInfo[1] || UserSocialInfo[4], userType, ReferralCode);

        let user_type = response.data.data[0].user_type
        let token = response.data.data[1].token

        // && user_type == UserSocialInfo[0]

        if (token) {
          const userData = {
            userid: response.data.data[0].id,
            useDisplay_name: response.data.data[0].name,
            user_email: response.data.data[0].email,
            user_type: response.data.data[0].user_type,
            token: token,
            Display_UserSocailPhoto: response.data.data[0].photo_url,
            CreatedAt: response.data.data[0].createdAt,
            isLoggedIn: true,
            state: response.data.data[0].state
          };
          const secretKey = process.env.REACT_APP_SECRET_KEY;
          const encryptedUserData = AES.encrypt(JSON.stringify(userData), secretKey).toString();
          localStorage.setItem('Data', encryptedUserData);


          dispatch(LoginAction.setIsLoggedIn(true))
          if (!localStorage.getItem("source") && !popupData.photoID) {
            navigate('/', { replace: true })

          } else if (userData.userid !== popupData.userID && !localStorage.getItem("source")) {
            let userData = {
              PhotoID: popupData.photoID,
              UserID: popupData.userID
            }
            const secretKey = process.env.REACT_APP_SECRET_KEY;
            const encryptedUserData = AES.encrypt(JSON.stringify(userData), secretKey).toString();
            navigate(`/otherusers-profile?userdata=${encodeURIComponent(encryptedUserData)}`);


          } else if (userData.userid == popupData.userID && !localStorage.getItem("source")) {
            navigate('/profile', { replace: true })
            dispatch(ModalAction.setPopupModalMessage({
              "joinModalHeading": "",
            }))
          }
          setspinner(false)
          setgoogelspinner(false)
          window.scrollTo(0, 0);
          dispatch(ModalAction.setSignInModalState(false))
          dispatch(ModalAction.setJoinNowModalState(false))
          dispatch(SocialMediaAction.getSocialMediaUserInfo([]))


        } else {
          // This email address already has an account. Contact us if we can help.
          seterror(`Oops !Something went wrong. Please try again `)
          setFbspinner(false)
          setgoogelspinner(false)
        }

      } catch (error) {


        setFbspinner(false)
        setgoogelspinner(false)
        seterror(error.response.data.message)
        if (error.response.data.message == "We don't recognize that username or password. Email us at") {

          if (UserSocialInfo[4] && !UserSocialInfo[1]) {

            dispatch(ModalAction.setFacebookEmailState(true))
          } else {

            dispatch(ModalAction.setJoinModalBackbtn(true))

          }
          dispatch(ModalAction.setSignInModalState(false))
          dispatch(ModalAction.setJoinNowModalState(true))
          setFbspinner(false)
          setgoogelspinner(false)

        }

      }
    }
    me();
  }, [UserSocialInfo])

  return (
    <>
      <div className="log_reg_btn">
        {error && <p style={{ textAlign: 'center', color: 'red' }}>{error}</p>}
        <LoginSocialFacebook className="faceboook"
          appId={process.env.REACT_APP_FACEBOOK_KEY}

          scope="public_profile,email"
          onResolve={(res) => {
            seterror(false)
            let loginWith = res.provider
            let UserEmail = res.data.email
            let facebookUserName = res.data.name
            let googleUserName = res.data.given_name
            let userPicture = res.data.picture.data.url
            let facebookuserID = res.data.id

            const facebookProfilepic = ModifyFacebookPictureUrl(userPicture)

            let UserSocialInfo = [loginWith, UserEmail, googleUserName || facebookUserName, facebookProfilepic, facebookuserID]
            dispatch(SocialMediaAction.getSocialMediaUserInfo(UserSocialInfo))
          }}
          onReject={(err) => {
            console.log(err)
          }}
        >
          {!spinner && !Fbspinner ? <a className="button facebook" style={{ marginBottom: "10px" }}>
            <img src="./assets/img/icons/fb.svg" alt="img" />
            {SignInModalState ? "Sign In with Facebook" : "Sign up with Facebook"}
          </a>
            :
            <a className="button facebook" style={{ marginBottom: "10px" }}>
              <span className="loader" style={{ borderTop: '3px solid white' }}></span>
            </a>
          }
          {/* <FacebookLoginButton /> */}
        </LoginSocialFacebook>


        <LoginSocialGoogle
          className="google"
          client_id={process.env.REACT_APP_GOOOGLE_KEY}
          scope="profile email"
          onResolve={(res) => {
            seterror(false)
            let loginWith = res.provider
            let UserEmail = res.data.email
            let UserName = res.data.name
            let userPicture = res.data.picture
            userPicture = userPicture.replace('=s96', '=s200');
            let UserSocialInfo = [loginWith, UserEmail, UserName, userPicture]

            dispatch(SocialMediaAction.getSocialMediaUserInfo(UserSocialInfo))

          }}
          onReject={(err) => {
            console.log(err)
          }}
        >
          {!spinner && !googelspinner ? <a className="button google" style={{ marginBottom: "10px" }}>
            <img src="./assets/img/icons/google.svg" alt="img" />
            {SignInModalState ? "Sign In with Google" : "Sign up with Google"}
          </a>
            :
            <a className="button google" style={{ marginBottom: "10px" }}>
              <span className="loader" style={{ borderTop: '3px solid black' }}></span>
            </a>
          }
          {/* <GoogleLoginButton /> */}
        </LoginSocialGoogle>

      </div>

    </>
  )
}

export default SocialMediaButtons