// apiUtils.js
import amplitude from 'amplitude-js';

import axios from 'axios';
const sendCongratulations = async (usertoken, UserID, PhotoID, IsCongratulate, loginUserID) => {

    try {

        const payload = {
            "user_id": UserID,
            "photo_id": PhotoID,
            "notification_type": "congratulate_weekly_toptenuser",
            "is_thanked": !IsCongratulate
        };
        const headers = {
            'Authorization': `Bearer ${usertoken}`,
            'Content-Type': 'application/json'
        };
        const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/notify/sendthanks`, payload, { headers });
        amplitude.getInstance().logEvent('Congratulations', { UserID: loginUserID });
        return response

    } catch (error) {
        throw error;
    }

};



export default sendCongratulations;