import React, { useState } from 'react'
import { formatDate } from '../../utils/Formatteddate';
import { useDispatch } from 'react-redux';
import { LightBoxAction } from '../../Store/LightboxSlice';
import amplitude from 'amplitude-js';
import { ModalAction } from '../../Store/Modal-Slice';
import axios from 'axios';


const MainGalleryList = ({ id, photoUrl, CompressedImageId, IsWeeklyTopTen, IsHunted, CreatedAt, TotalUpvoteCount, usertoken, isHearted }) => {
    let [IsUpvote, setIsUpvote] = useState(isHearted)
    let [TotalVote, setTotalVote] = useState(Number(TotalUpvoteCount))

    let dispatch = useDispatch()


    // <-------------------------------------------->


    let LightboxHandler = (photoId) => {

        dispatch(LightBoxAction.setLightBoxActiveImgId(photoId))
        amplitude.getInstance().logEvent('Expand Photo')
        dispatch(ModalAction.setisLightboxModalOpen(true))
    }
    // <-------------------------------------------->


    let UpVoteHandler = async () => {
        setIsUpvote(!IsUpvote)
        setTotalVote(IsUpvote ? TotalVote - 1 : TotalVote + 1)
        if (!IsUpvote) {
            amplitude.getInstance().logEvent('Gallery Photo Heart')
        }
        try {
            let payload = {
                "isHearted": !IsUpvote,
                "galleryPhotoId": id

            }


            let headers = {
                'Authorization': `Bearer ${usertoken}`,
                'Content-Type': 'application/json'
            }

            let response = await axios.post(`${process.env.REACT_APP_BASE_URL}/photo/heartedGalleryPhoto`, payload, { headers });



        } catch (error) {
            console.log(error, "error")

        }
    }
    return (
        <>

            <div className="grid-image-item" onClick={() => { LightboxHandler(id) }} style={{ cursor: 'pointer' }}>
                <button className="gallery_love" onClick={(event) => {
                    event.stopPropagation(); // Prevents the event from reaching the parent
                    UpVoteHandler();
                }}>
                    <img src={`./assets/img/icons/${IsUpvote ? "heart-fill.svg" : "heart.svg"}`} alt="img" />
                    {TotalVote > 0 && TotalVote}
                </button>

                <img className="gallery_img " src={photoUrl ? photoUrl : `https://res.cloudinary.com/duk09hrua/image/upload/c_fit,w_500,f_auto,q_auto/Resized_Photos/${CompressedImageId}`} alt='img' />
                <div className="content">
                    <h6 className="title">{!IsWeeklyTopTen && !IsHunted ? "" : (IsWeeklyTopTen ? "Weekly Top Ten" : "Hunted Photo")}</h6>
                    <span className="date">{formatDate(CreatedAt)}</span>
                </div>
            </div>

        </>
    )
}

export default MainGalleryList
