import axios from 'axios';

export const addNewsletterRecipient = async (email) => {
    try {
        const payload = { email };
        const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/user/add_newsletter_recipient`, payload);
        return response
       
    } catch (error) {
        throw error
       
    }
};