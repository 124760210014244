export function ShortAddress(address, maxLength) {
    const CompleteAddress = address.split(' ');

    if (CompleteAddress.length <= 2) {
        // If there are 1 or 2 words, return the complete address
        return address;
    }

    let firstWord = CompleteAddress[0];
    let secondWord = CompleteAddress[1];
    const lastWord = CompleteAddress[CompleteAddress.length - 1];

    // Remove comma if it appears with the first word
    if (firstWord.endsWith(',')) {
        firstWord = firstWord.slice(0, -1);
    }

    // Check if the first word ends with a comma
    const commaInFirstWord = CompleteAddress[0].endsWith(',');

    // Remove comma if it appears with the second word
    if (secondWord.endsWith(',')) {
        secondWord = secondWord.slice(0, -1);
    }

    const displayAddress = commaInFirstWord
        ? `${firstWord} ${secondWord}, ${lastWord}`
        : `${firstWord} ${secondWord}, ${lastWord}`;

    if (displayAddress.length <= maxLength) {
        return displayAddress;
    }

    return displayAddress.slice(0, maxLength) + '..';
}
