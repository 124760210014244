// utils/api.js
import axios from 'axios';

export const updateLastVisitTime = async (usertoken) => {
  try {
    const payload = {
      lastVisitTime: new Date(),
    };
    const headers = {
      Authorization: `Bearer ${usertoken}`,
      'Content-Type': 'application/json',
    };

    const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/user/updateLastVisitTime`, payload, { headers });
    return response.data;
  } catch (error) {
    console.error("Error updating last visit time:", error);
    throw error;
  }
};