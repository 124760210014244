import { createSlice } from "@reduxjs/toolkit";

let initialSocketReceiverUserID = {
    SocketReceiverUserID: null,
    ReloadNotificationSenderFn: false,
    ReloadGetNorificationApi: false
};

let SocketSlice = createSlice({
    name: 'Socket',
    initialState: initialSocketReceiverUserID,
    reducers: {
        setSocketReceiverUserID(state, action) {
            state.SocketReceiverUserID = action.payload
        },
        setReloadNotificationSenderFn(state, action) {
            state.ReloadNotificationSenderFn = action.payload
        },
        setReloadGetNorificationApi(state, action) {
            state.ReloadGetNorificationApi = action.payload
        }
    }
})

export let SocketAction = SocketSlice.actions;

export default SocketSlice