import React from 'react'
import { useState, useEffect } from 'react'
import PhotoFeed from '../Feed/PhotoFeed'
import ActivityContainer from '../Activity/ActivityContainer'
import FollowerAndFollowingContainer from '../FollowersAndFollowing/FollowerAndFollowingContainer'
import { useSelector } from 'react-redux';
import { GenericAction } from '../../Store/GenericSlice'
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Link } from 'react-router-dom'


const SubNavbar = (props) => {

    let [PhotosTabState, setPhotosTabState] = useState(false)
    let [ActivitTabState, setActivitTabState] = useState(false)
    let [PeopleTabState, setPeopleTabState] = useState(false)
    let is_logoClickState = useSelector(state => state.Generic.LogoClickState)

    let dispatch = useDispatch()


    useEffect(() => {

        if (is_logoClickState) {
            document.querySelector("#photoss").click()
            setPhotosTabState(true)
            setActivitTabState(false)
            setPeopleTabState(false)
            dispatch(GenericAction.setLogoClickState(false))
        }
    }, [is_logoClickState])

    useEffect(() => {
        if (props.photoTabstate) {
            setPhotosTabState(true)
            setActivitTabState(false)
            setPeopleTabState(false)
        } else if (props.activityTabState) {
            setActivitTabState(true)
            setPhotosTabState(false)
            setPeopleTabState(false)
        } else if (props.peopleTabState) {
            setPeopleTabState(true)
            setPhotosTabState(false)
            setActivitTabState(false)
        }
    }, [])

    let navigate = useNavigate();
    let urlHandler = () => {
        const currentUrl = window.location.pathname;
        navigate(`${currentUrl}`)
    }
    return (
        <>
            <main className="site-content" id="main" style={props.activityTabState || props.peopleTabState ? { paddingTop: '130px' } : { paddingTop: '40px' }}>
                <div className="pt-40 pb--80 pb-lg-0">
                    <div className="container container-two">
                        {/* <ul className="nav-tabs nav tab-style-one" role="tablist">
                            <li>
                                <Link to={'/'}
                                // id='photoss' 
                                // onClick={() => {
                                //     setPhotosTabState(true)
                                //     setPeopleTabState(false)
                                // }} href="#photos" data-bs-toggle="tab" aria-selected="false" role="tab" 
                                className={`${PhotosTabState ? "active" : ""}`}>Photos</Link>
                            </li>
                            <li>
                                <Link  to={'/activity'}
                                // onClick={() => {
                                //     setActivitTabState(true)
                                //     setPeopleTabState(false)
                                // }} href="#activity" data-bs-toggle="tab" aria-selected="true" role="tab"
                                 className={`${ActivitTabState ? "active" : ""}`}>Activity</Link >
                            </li>
                            <li onClick={urlHandler}>
                                <Link  to={'/people'}
                                // onClick={() => { setPeopleTabState(true) }} 
                                // href="#people" data-bs-toggle="tab" aria-selected="false" role="tab" 
                                className={`${PeopleTabState ? "active" : ""}`}>People</Link>
                            </li>
                        </ul> */}
                        <div className="pt-40" style={{ paddingTop: "40px" }}>
                            <div className="tab-content">

                                <div className={`tab-pane fade${PhotosTabState ? "show active" : ""}`} id="photos" >
                                    {PhotosTabState && <PhotoFeed MarginTopNone={true} />}
                                </div>
                                <div className={`tab-pane fade${ActivitTabState ? "show active" : ""}`} id="activity">
                                    <div className="activity-wrapper">
                                        {ActivitTabState && <ActivityContainer />}
                                    </div>
                                </div>

                                <div className={`tab-pane fade${PeopleTabState ? "show active" : ""}`} id="people" >
                                    {PeopleTabState && <FollowerAndFollowingContainer MarginTopNone={true} />}
                                </div>
                            </div>
                        </div>



                    </div>
                </div>
            </main>


        </>
    )
}

export default SubNavbar