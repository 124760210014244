import axios from 'axios';


const axiosInstance = axios.create({
    baseURL: process.env.REACT_APP_BASE_URL,
});

axiosInstance.interceptors.response.use(
    response => response,
    error => {
        if (error.response.data.message === "jwt expired") {

            alert("Your session has expired. Please log in again.");
            localStorage.removeItem('Data');
            window.location.replace('/');
            window.scrollTo(0, 0);
        } else if (error.response.data.message === "Your Account is Blocked") {

            alert("Your Account is Blocked");
            localStorage.removeItem('Data');
            window.location.replace('/');
            window.scrollTo(0, 0);
        }else if (error.response.data.message === "User Profile is Blocked") {

            alert("User Profile is Blocked");
            window.location.replace('/');
            window.scrollTo(0, 0);
        }
        return Promise.reject(error);
    }
);

export default axiosInstance;
