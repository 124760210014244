import React from 'react'
import Cropper from 'react-easy-crop';
import getCroppedImg from '../../utils/CropImage';
import { Slider } from '@mui/material';
import axios from 'axios';
import { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ModalAction } from '../../Store/Modal-Slice';
import { decryptUserData } from '../../utils/encryptedData'
import { AES } from 'crypto-js';
import Swal from 'sweetalert2';
import amplitude from 'amplitude-js'

const ProfileImgUploadModal = () => {
  let [image, setimage] = useState('')
  let [imagePath, setimagePath] = useState('')
  let [CroppimagePath, setCroppimagePath] = useState('')
  let [ERROR, setERROR] = useState('')
  let [spinner, setspinner] = useState(false)
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [rotation, setRotation] = useState(0);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
  const [uploaded, setUploaded] = useState('');
  const [CropperContainerHeight, setCropperContainerHeight] = useState("310px");


  let dispatch = useDispatch()
  const currentUrl = window.location.pathname;
  // <---------------- decrypted data ------------->
  const encryptedUserData = localStorage.getItem('Data');
  const decryptedUserData = decryptUserData(encryptedUserData);
  let usertoken = decryptedUserData.token
  let loginUserid = decryptedUserData.userid
  let uploadinputClickhandler = () => {
    document.querySelector('.profile-upload-input').click()

  }

  // <-------------------------------------------->
  let MeApiState = useSelector(state => state.Modal.MeApiState)
  // <-------------------------------------------------------->

  const cropComplete = (croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  };
  const cropImage = async () => {
    // setLoading(true);
    document.querySelector('.profile-img-modal-close_btn').click()
    try {
      const { file, url } = await getCroppedImg(
        imagePath,
        croppedAreaPixels,
        // rotation
      );
      setCroppimagePath(url)
      setimage(file);
      dispatch(ModalAction.setCropImg({
        cropImgPath: url,
        cropImg: file
      }))
      setimagePath('')
    } catch (error) {


    }
  };


  let cropandUploadHandler = async () => {
    setspinner(true)
    try {
      const timestamp = new Date().getTime();

      const { file, url } = await getCroppedImg(
        imagePath,
        croppedAreaPixels,
        // rotation
      );
      setCroppimagePath(url)
      setimage(file);
      let data = new FormData();
      data.append('page', "upload_photo");
      data.append('photoUrl', file, `profile_image_${timestamp}.jpeg`);
      let headers = {
        'Authorization': `Bearer ${usertoken}`
      };
      const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/user/edit_profile`, data, {
        headers,
        onUploadProgress: (data) => {
          setUploaded(Math.round((data.loaded / data.total) * 100));
        }
      });

      dispatch(ModalAction.setMeApiState(!MeApiState))
      const secretKey = process.env.REACT_APP_SECRET_KEY;
      decryptedUserData.Display_UserSocailPhoto = response.data.data.photo_url

      let updatedEncryptedUserData = AES.encrypt(JSON.stringify(decryptedUserData), secretKey).toString();
      localStorage.setItem('Data', updatedEncryptedUserData);
      Swal.fire(
        '',
        'Upload successfully!',
        'success'
      )
      amplitude.getInstance().logEvent('Add Avatar Photo', { UserID: loginUserid });
      setimagePath('')
      setspinner(false)
      document.querySelector('.profile-img-modal-close_btn').click()
    } catch (error) {


      setspinner(false)
    }
  };

  // <-------------------------------------------------------->



  const MAX_IMAGE_EDGE = 5000;
  const MAX_FILE_SIZE = 50 * 1024 * 1024; // 50 MB
  const ALLOWED_FORMATS = ['image/jpeg', 'image/png'];



  let imgHandler = (e) => {
    setERROR("")
    let selectedImage = e.target.files[0]

    if (!ALLOWED_FORMATS.includes(selectedImage.type)) {
      setERROR('Please upload only JPG or PNG files.');
      return;
    }


    const img = new Image();
    img.src = URL.createObjectURL(selectedImage);
    img.onload = async () => {
      await img.decode();

      const { width, height } = img;


      if (width > MAX_IMAGE_EDGE || height > MAX_IMAGE_EDGE) {
        setERROR('Please ensure the edges of your photo are less than 5000 pixels.');
        return;
      } else if (selectedImage.size > MAX_FILE_SIZE) {
        setERROR('Please ensure that your file is less than 50MB.');
        return;
      } else {

        // setimage(selectedImage)

        const reader = new FileReader();
        reader.onload = () => {
          setimagePath(reader.result);
        };
        reader.readAsDataURL(selectedImage);
      }
    }


  }
  // <-------------------------------------------->
  return (
    <>
      <div className="backdrop" />
      {/* Upload Profile */}
      <div className="modal fade bph-modal show" id="profileUploadModal" tabIndex={-1} aria-labelledby="profileUploadModal" aria-hidden="true"
        style={{ display: 'block' }}>
        <div className="modal-dialog modal-dialog-centered modalAnimation">
          <div className="modal-content">
            <div className="modal-body">
              <div className="onboarding_modal photoUpload_modal profileUpload">
                <div className="modal_inner">
                  <button type="button" className="btn-close profile-img-modal-close_btn" data-bs-dismiss="modal" aria-label="Close"
                    onClick={() => { dispatch(ModalAction.setProfileUploadModalState(false)) }} />
                  {/* logo */}
                  <div className="modal_logo d-none d-md-inline-flex">
                    <img src="./assets/img/logo/modal-logo.png" alt="img" />
                  </div>
                  {/* content */}
                  <div className="modal_content">
                    <div className="modal_title">
                      <h2 className="title"><img src="./assets/img/icons/upload.svg" alt="img" />Upload a photo of you</h2>
                    </div>
                    {/* upload photo */}
                    <div className="file-upload">
                      {!imagePath && <p className="subtitle profile-upload-before-title">Add a photo to your profile so we can show the other
                        Big Photo
                        Hunters your
                        beautiful face! </p>}
                      {imagePath && <p className="subtitle profile-upload-after-title" style={{ display: 'block' }}>Position yourself in the circle</p>}
                      {/* <form action method="POST" encType="multipart/form-data"> */}

                      {ERROR && <p className="eerror" style={{ textAlign: 'center' }}>{ERROR} </p>}

                      {!imagePath && <div className="profile-image-upload-wrap">
                        <input className="profile-upload-input" type="file" onChange={imgHandler} />
                        <div className="drag-text">
                          <h4 className="title">Drag and drop your photo here</h4>
                          <span className="or">or</span>
                          <button className="file-upload-btn btn" type="button"
                            onClick={uploadinputClickhandler}
                          >Upload from device</button>
                          <span className="support_note">Max. 5000 x 5000 px., 50 MB,
                            JPG or PNG format only.</span>
                        </div>
                      </div>}
                      {imagePath &&
                        <div>

                          <Cropper
                            image={imagePath}
                            crop={crop}
                            zoom={zoom}
                            rotation={rotation}
                            aspect={1}
                            cropShape="round"
                            onZoomChange={setZoom}
                            onRotationChange={setRotation}
                            onCropChange={setCrop}
                            onCropComplete={cropComplete}
                            // style={{  // Apply inline styling for a circular appearance
                            //   borderRadius: "50%",
                            //   overflow: "hidden",
                            //   width: "300px",  // Adjust the width and height to your preference
                            //   height: "300px",
                            // }}

                            style={{
                              containerStyle: {
                                height: `${CropperContainerHeight}`,
                                width: "100%",
                                margin: "auto",
                                position: "relative",
                              }
                            }}

                            classes={{ reactEasyCrop_Container: "" }}
                          >
                          </Cropper>
                          <div className='slider_container'>
                            {!spinner && <Slider
                              // valueLabelDisplay="auto"
                              // valueLabelFormat={zoomPercent}
                              min={1}
                              max={3}
                              step={0.1}
                              value={zoom}
                              onChange={(e, zoom) => setZoom(zoom)}
                            />}
                          </div>
                          <div className="profile-image-title-wrap" style={{ display: 'flex', flexDirection: 'column', minWidth: "200px", margin: 'auto',alignItems:"center" }}>

                            {currentUrl === '/profile' ? (
                              !spinner ? (
                                <button style={{minWidth:"200px"}} type="button" className="btn" onClick={cropandUploadHandler}>
                                  Upload
                                </button>
                              ) : (
                                <div className="progress" style={{width:"80%",marginTop:"10px"}}>
                                  <div className="progress-bar bg-warning" role="progressbar" style={{ width: `${uploaded}%`, color: 'black' }} aria-valuenow={uploaded} aria-valuemin={0} aria-valuemax={100}>{`${uploaded}%`}</div>
                                </div>
                              )
                            ) : (
                              <button type="button" className="btn" onClick={cropImage}>
                                SAVE
                              </button>
                            )}
                            {!spinner && <label className="remove-profile-image" style={{ marginTop: '10px' }} onClick={() => { setimagePath("") }}>Select a different photo</label>}

                            {/* <input className="profile-upload-input" id="profile-upload" type='file' onchange=""
                          accept="image/*" /> */}
                          </div>
                          {/* <a
  
                            >Done</a> */}

                        </div>
                        //       <div className="profile-upload-content">
                        //         <div className="drag_image">
                        //           <div className="drag_image_inner">
                        //             <img className="profile-upload-image" id="upload-img" src={imagePath} alt="your image" />
                        //           </div>
                        //         </div>
                        //         <div className="profile-image-title-wrap">
                        //           <button type="button" className="btn">Post Photo</button>
                        //           <label className="remove-profile-image" onClick={()=>{setimagePath("")}}>Select a different photo</label>
                        //           {/* <input className="profile-upload-input" id="profile-upload" type='file' onchange=""
                        // accept="image/*" /> */}
                        //         </div>
                        //       </div>
                      }
                      {/* </form> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* ! Upload Profile */}

    </>
  )
}

export default ProfileImgUploadModal

// const zoomPercent = (value) => {
//   return `${Math.round(value * 100)}%`;
// };