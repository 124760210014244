
import React, { useEffect } from 'react';
import { useStripe } from '@stripe/react-stripe-js';

const CheckoutForm = ({ sessionId }) => {
  const stripe = useStripe();

  useEffect(() => {
    const redirectToCheckout = async () => {
      if (stripe && sessionId) {
        const { error } = await stripe.redirectToCheckout({ sessionId });

        if (error) {
          console.error('Stripe error:', error);
        }
      }
    };

    redirectToCheckout();
  }, [stripe, sessionId]);

  return (
    <div>
      <p>Redirecting to checkout...</p>
    </div>
  );
};

export default CheckoutForm;
