import React from 'react'
import FavouritePhotos from './FavouritePhotos'
import Members from './Members'

const PhotoFeed = ({ MarginTopNone }) => {
  return (
    <>
    
      <section className={`photo-feed-section logout-home`}
      style={{paddingTop:"0px"}}
        // style={!MarginTopNone ? { marginTop: "40px" } : { marginTop: "0px", paddingTop: "0px" }}
        >
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-12 col-lg-8">
              <FavouritePhotos />
            </div>
            {/* <div className="col-lg-1 d-none d-lg-block">
              <div className="line"></div>
            </div> */}
            <div className="col-lg-4 d-none d-xl-block">
              <Members />
            </div>
          </div>
        </div>
      </section >

    </>
  )
}

export default React.memo(PhotoFeed)