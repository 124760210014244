import React from 'react'
import { formatDate } from '../../utils/Formatteddate';
import { Link } from 'react-router-dom';
import { ShortAddress } from '../../utils/Address';
import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { AES } from 'crypto-js';
import { FollowAction } from '../../Store/FollowSlice';
import { followUser } from '../../utils/API/FollowApi';
import { SocketAction } from '../../Store/SocketSlice';
import { useSelector, useDispatch } from 'react-redux';

const FollowersAndFollowingCard = (props) => {
    let [Isfollow, setIsfollow] = useState(props.isfollow)
    // formatDate function 
    const formattedDate = formatDate(props.createdAt);

    ///address
    let address
    if (props.FollowerLocation) {
        address = ShortAddress(props.FollowerLocation, 16);
    }

    let ClickedFollowCardInfo = useSelector(state => state.Follow.ClickedFollowCardInfo)


    useEffect(() => {

        if (ClickedFollowCardInfo.id == props.FollowerId) {
            setIsfollow(ClickedFollowCardInfo.is_follow)
            dispatch(FollowAction.setClickedFollowCardInfo({}))
        }
    }, [ClickedFollowCardInfo])




    // < --------Socket ------->
    let dispatch = useDispatch()

    let ReloadNotificationFn = useSelector(state => state.Socket.ReloadNotificationSenderFn)


    let SendNotificationFn = () => {
        dispatch(SocketAction.setSocketReceiverUserID(props.FollowerId))
        dispatch(SocketAction.setReloadNotificationSenderFn(!ReloadNotificationFn))
    }
    // <-------------------------------------------->

    const genresString = props.genresList ? Object.values(props.genresList).slice(0, 3).join(", ") : "";


    let FollowerImgModalOpenHandler = (photo_id) => {

        dispatch(FollowAction.setFollowerImgmodalUserInfo({
            User_ID: props.FollowerId,
            usertoken: props.usertoken,
            photo_id,
            LoginUserId: props.LoginUserId
        }))

        dispatch(FollowAction.setFollowerimgModalOpen(true))
    }



    useEffect(() => {
        setIsfollow(props.isfollow);
    }, [props.isfollow]);


    let navigate = useNavigate();

    let OtheruserProfileNavigateHandler = (event) => {
        event.preventDefault();



        if (props.LoginUserId == props.FollowerId) {
            navigate("/profile");
        } else {
            let userData = {
                PhotoID: "",
                UserID: props.FollowerId,
            }
            const secretKey = process.env.REACT_APP_SECRET_KEY;
            const encryptedUserData = AES.encrypt(JSON.stringify(userData), secretKey).toString();
            navigate(`/otherusers-profile?userdata=${encodeURIComponent(encryptedUserData)}`);

        }

        window.scrollTo(0, 0)
    }

    let FollowHandler = async (event) => {

        setIsfollow(!Isfollow)

        let followCardinfo = {
            camera_brand: props.cameraBrand,
            createdAt: props.createdAt,
            genres_list: props.genresList,
            id: props.FollowerId,
            is_follow: !Isfollow,
            name: props.FollowerName,
            photo_url: props.FollowerPhoto,
            photos: props.photosArr,
            state: props.FollowerLocation,
            usertoken: props.usertoken,
            LoginUserId: props.LoginUserId,
        }
        dispatch(FollowAction.setClickedFollowCardInfo(followCardinfo))
        if (Isfollow) {
            props.GetFollowCardInfoFn(followCardinfo, "remove", props.FollowingCard)

        } else {
            props.GetFollowCardInfoFn(followCardinfo, "add", props.FollowingCard)

        }


        try {

            const response = await followUser(props.FollowerId, !Isfollow, props.usertoken, props.LoginUserId, SendNotificationFn, props.isFollowBack ? "Follow Back" : "Follow");

        } catch (error) {

        }

    }


    return (


        <>

            {/* {<div className="col-lg-4 col-md-6 fadeInDown wow">
                <div className="profile-card ">
                    <div className="profile-card-inner">
                        <a >
                            <div className="profile-card-top-content">
                                <div className="profile-card-thumb">
                                    <Link
                                        onClick={OtheruserProfileNavigateHandler}
                                        className='memberUser_img'
                                        style={{
                                            backgroundImage: `url(${props.FollowerPhoto || ""})`,
                                            backgroundPosition: "center",
                                            backgroundSize: "cover",
                                            backgroundRepeat: "no-repeat",
                                            borderRadius: "50%",
                                            cursor: "pointer",
                                            display: "block",
                                            height: "60px",
                                            width: "60px",
                                        }}
                                    >
                                        {!props.FollowerPhoto && <img src={`https://ui-avatars.com/api/?name=${props.FollowerName}&size=60&rounded=true&background=01655a&color=fff`} alt='img' />}
                                    </Link>
                                </div>
                                <div className="profile-card-content">
                                    <ul>
                                        {props.cameraBrand && props.cameraBrand !== "null" && <li><span className="title">Camera: </span> <span className="value">{props.cameraBrand}</span></li>}
                                        {genresString &&
                                            <li style={{ marginTop: "4px" }}>
                                                <span className="title">Genres: </span>
                                                <span className="value" style={{ textAlign: "left" }}>{genresString}
                                                </span>
                                            </li>}
                                    </ul>
                                </div>
                            </div>
                        </a>
                        <a className="d-flex">
                            <div className="profile-card-bottom-content w-100">
                                <div className="left">
                                    <h6 className="name" style={{ cursor: "pointer" }} onClick={OtheruserProfileNavigateHandler}>{props.FollowerName}</h6>
                                    <p className="font--sm mb-0">{address}</p>
                                </div>
                                {props.LoginUserId !== props.FollowerId && <div className="right">
                                    {!Isfollow ? <button className="btn btn--base  follow-card-follow-btn" onClick={FollowHandler}>{props.isFollowBack ? "Follow Back" : "Follow"}</button> :
                                        <button className="btn btn--base btn--unfollow follow-card-following-btn" onClick={FollowHandler}>Following</button>}
                                </div>}
                            </div>
                        </a>
                        {props.photosArr && props.photosArr.length > 0 ?
                            <div className="bottom-images">

                                {props.photosArr?.map((data, index) => (
                                    <div key={index}>
                                        <a onClick={() => { FollowerImgModalOpenHandler(data.id) }}
                                        // data-bs-toggle="modal" data-bs-target="#exampleModalNew1"
                                        >
                                            <img src={`https://res.cloudinary.com/duk09hrua/image/upload/c_fit,w_220,f_auto,q_auto/Resized_Photos/${props.photosArr[index].compressedImageId}`} alt='img' className="img-fluid" />
                                        </a>
                                    </div>
                                ))}

                            </div> :
                            <div className="profile_bottom">
                                <span className="no_feature_images">This Member hasn’t posted a photo yet.</span>
                            </div>

                        }
                    </div>
                </div>
            </div>} */}

            <div className="user_profile fadeInDown wow">
                <div className="profile_top">
                    <div className="profile_img">
                        <Link
                            onClick={OtheruserProfileNavigateHandler}
                            className='memberUser_img'
                            style={{
                                backgroundImage: `url(${props.FollowerPhoto || ""})`,
                                backgroundPosition: "center",
                                backgroundSize: "cover",
                                backgroundRepeat: "no-repeat",
                                borderRadius: "50%",
                                cursor: "pointer",
                                display: "block",
                                height: "60px",
                                width: "60px",
                            }}
                        >
                            {!props.FollowerPhoto && <img src={`https://ui-avatars.com/api/?name=${props.FollowerName}&size=60&rounded=true&background=01655a&color=fff`} alt='img' />}
                        </Link>
                    </div>
                    <ul className="camera_infos">
                        {props.cameraBrand && props.cameraBrand !== "null" && <li><span>Camera: </span>{props.cameraBrand}</li>}
                        {genresString && <li><span>Genres:</span> {genresString}</li>}
                    </ul>
                </div>
                <div className="profile_middle">
                    <ul className="user_info">
                        <li className="name" style={{ cursor: "pointer" }} onClick={OtheruserProfileNavigateHandler}>{props.FollowerName}</li>
                        <li className="tagline">{address}</li>
                    </ul>

                    {props.LoginUserId !== props.FollowerId && <div >
                        {!Isfollow ? <button className="btn btn--base  follow-card-follow-btn" onClick={FollowHandler}>{props.isFollowBack ? "Follow Back" : "Follow"}</button> :
                            <button className="btn btn--base btn--unfollow follow-card-following-btn" onClick={FollowHandler}>Following</button>}
                    </div>}

                </div>
                {props.photosArr && props.photosArr.length > 0 ? <div className="profile_bottom">
                    <ul className="feature_images">
                        {props.photosArr?.map((data, index) => (
                            <div key={index}>
                                <li onClick={() => { FollowerImgModalOpenHandler(data.id) }}
                                >
                                    <img src={`https://res.cloudinary.com/duk09hrua/image/upload/c_fit,w_220,f_auto,q_auto/Resized_Photos/${props.photosArr[index].compressedImageId}`} alt='img' className="img-fluid" />
                                </li>
                            </div>
                        ))}

                    </ul>
                </div>
                    :
                    <div className="profile_bottom">
                        <span className="no_feature_images">This Member hasn’t posted a photo yet.</span>
                    </div>
                }
            </div>


        </>




    )
}

export default FollowersAndFollowingCard