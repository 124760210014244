import React from 'react'
import ActivityPageNotificationList from './ActivityPageNotificationList'
import { useState, useEffect, useRef } from 'react'
import { decryptUserData } from '../../utils/encryptedData'
import { useSelector } from 'react-redux'
import { fetchNotifications } from '../../utils/API/NotificationApi'
import amplitude from 'amplitude-js';
import { Link } from 'react-router-dom'



let increment = 0;
const ActivityContainer = () => {

    let [spinner, setspinner] = useState(false)
    let [CheckEmptyArry, setCheckEmptyArry] = useState(true)
    let [firstNotificationList, setfirstNotificationList] = useState([])
    let [LoadMoreNotification, setLoadMoreNotification] = useState([])
    let [RecordNotFound, setRecordNotFound] = useState(false)

    let footerr = useRef();
    // <---------------- decrypted data ------------->
    const encryptedUserData = localStorage.getItem('Data');
    const decryptedUserData = decryptUserData(encryptedUserData);
    let usertoken = decryptedUserData.token
    let CreatedAt = decryptedUserData.CreatedAt
    let loginUserid = decryptedUserData.userid

    let ReloadNotif = useSelector(state => state.Socket.ReloadGetNorificationApi)


    const formatDate = (dateString) => {
        const options = { year: 'numeric', month: 'long', day: 'numeric' };
        const formattedDate = new Date(dateString).toLocaleDateString('en-US', options);
        return formattedDate;
    };

    const formattedDate = formatDate(CreatedAt);

    let apiCall = (e) => {
        if (e[0].isIntersecting) {
            increment = increment + 10;

            let LoadMoreNotification = async function () {

                setspinner(true)
                try {
                    const response = await fetchNotifications(increment, usertoken);

                    const newNotification = response.data.data.notifications;
                    setLoadMoreNotification(prevNotification => [...prevNotification, ...newNotification])

                    if (response.data.data.notifications.length == 0 || response.data.data.notifications.length < 10) {
                        setCheckEmptyArry(false)
                    }
                    setspinner(false)

                } catch (error) {
                    setspinner(false)
                }
            }
            LoadMoreNotification();
        }


    }


    ///////////

    useEffect(() => {

        const Observer = new IntersectionObserver(apiCall, {
            root: null,
            threshold: 0
        })
        if (footerr.current) Observer.observe(footerr.current)
        return () => {
            if (footerr.current) Observer.unobserve(footerr.current);
        };
    }, [firstNotificationList])
    ////////////


    useEffect(() => {

        setfirstNotificationList([])
        setLoadMoreNotification([])
        setspinner(true)
        setRecordNotFound(false)
        increment = 0
        setCheckEmptyArry(true)
        let FirstListOfNotification = async function () {

            try {

                const response = await fetchNotifications(0, usertoken);


                setfirstNotificationList(response.data.data.notifications)

                if (response.data.data.notifications.length == 0) {
                    setRecordNotFound(true)
                    setCheckEmptyArry(false)
                }

                if (response.data.data.notifications.length < 10) {
                    setCheckEmptyArry(false)
                }

                if (response.data.data.total_notifications > 0) {
                    let count = response.data.data.total_notifications
                    for (let i = 0; i < count; i++) {
                        amplitude.getInstance().logEvent("Activity Page Notification", { UserID: loginUserid });
                    }
                }


                setspinner(false)
            } catch (error) {
                setspinner(false)
            }
            // .............

        }
        FirstListOfNotification();
    }, [ReloadNotif])


    let AllNotificationList;
    if (increment === 0) {
        AllNotificationList = [...firstNotificationList]
    } else {

        AllNotificationList = [...firstNotificationList, ...LoadMoreNotification]

    }


    let LinksArray = [
        {
            title: "Invite A Friend",
            link: "/invite-friend"
        },
        // {
        //     title: "Be In Contests & Events",
        //     link: "/new-giveaway"
        // },
        // {
        //     title: "Share Up To 50 Photos On Profile",
        //     link: "/profile"
        // },
        // {
        //     title: "Feature Your Profile",
        //     link: "/club-page"

        // },
        // {
        //     title: "Boost Photo To Top",
        //     link: "#"
        // }

    ]
    return (
        <>
            <div className="left-content pb-120" style={{ paddingBottom: '120px', width: '100%' }}>
                <h4 className="mb-4 text-center text-lg-start mb-lg-2">Activity</h4>
                <div className="d-flex flex-column gap-3 gap-lg-4">
                    {AllNotificationList?.map((data) => (
                        <ActivityPageNotificationList
                            key={data.id}
                            // NotificationType={data.notification_type}
                            NotificationsData={data}
                            usertoken={usertoken}
                            loginUserid={loginUserid}
                        />
                    ))}
                    {RecordNotFound && <h4>Record Not Found</h4>}
                </div>
                {CheckEmptyArry && spinner && <div style={{ display: "flex", justifyContent: "center", marginTop: "20px" }}>
                    <div className="spinner-border text-secondary" role="status">
                        <span className="sr-only"></span>
                    </div>
                </div>}

                {CheckEmptyArry && AllNotificationList.length > 0 && <div ref={footerr}></div>}
            </div>
            <hr style={{ width: 1 }} />



            <div className="right-content">
                <span className="btn member-since-btn" style={{ backgroundColor: "transparent" }}>Member since {formattedDate}</span>
                {/* call to action */}
                <div className="activity-call_to_action">
                    <div className="icon">
                        <div style={{ backgroundColor: "white", borderRadius: "50%", height: "48px", width: "48px", display: "flex", justifyItems: "center", padding: "10px" }}>
                            <img src="./assets/img/hunt-page/star.svg" alt />
                        </div>

                    </div>
                    <div className="action_title">Bring a friend: 2 free months for both!</div>
                    <ul className="action_links">
                        {LinksArray.map((data) =>
                            <li key={data.link}>
                                <Link to={`${data.link}`}>
                                    {data.title}
                                    <img src="./assets/img/icons/action-link.svg" alt />
                                </Link>
                            </li>)}

                    </ul>
                </div>
            </div>





        </>
    )
}

export default ActivityContainer